import React from 'react';
import ProjectTable from './ProjectTable';

const ExperimentTracking = () => {
    return (
        <div>
            <ProjectTable />
        </div>
    )
}

export default ExperimentTracking;