import React, { useEffect, useRef, useState } from "react";
import { Toast } from "primereact/toast";
import axios from "axios";
import Loading from "../../Components/Loading";
import { FormControl, InputLabel, MenuItem, Select } from "@mui/material";
import { useNavigate, useParams } from "react-router";
import { Link } from "react-router-dom";
import profilePic from "../../logo/profile-pic.png";
import informationIcon from "../../logo/informationIcon.png";
import mllogo from "../../logo/mlangles360logo.svg";
import mlimage from "../../logo/mlangles360logo.svg";
import iIcon from "../../logo/i-Icon.png";
import Mlsidebar from "../../Components/Mlsidebar";
import Superuser from "../../Components/Superuser";
import { backend_url } from "../../Config";
import Logout from "../Logout";
import { Dialog } from "primereact/dialog";
import InputCheckbox from "./InputCheckbox";
import "./new.css";
import LayersInput from "../../Components/ImageLayers";
import TabularLayers from "../../Components/TabularLayers";
import ImageLayers from "../../Components/ImageLayers";
import { imageData, tabularData } from "./layers/layersData";

const CreateRun = () => {
  let { path, project_name, project_id } = useParams();

  const [inputValue, setInputValue] = useState({});
  const [selected, setSelected] = useState([]);
  const [runName, setRunName] = useState("");
  const [fulldata, setFulldata] = useState({});
  const [open, setOpen] = useState(false);
  const navigate = useNavigate();
  const toast = useRef(null);

  const [problemtype, setProblemtype] = useState("");
  const [targetfeature, setTargetfeature] = useState("");
  const [dataversion, setDataversion] = useState("");
  const [showalogo, setShowalgo] = useState(false);
  const [problem, setProblem] = useState([]);
  const [target, setTarget] = useState([]);
  const [version, setVersion] = useState([]);
  const [runs, setRuns] = useState([]);
  const [clusterImg, setClusterImg] = useState([]);

  const [currentTab, setCurrentTab] = useState("experimentTracking");
  const [userDetails, setUserDetails] = useState({});
  const [logout, setLogout] = useState(false);

  const [fieldErr, setFieldErr] = useState({
    runName: "",
    model: "",
    problemType: "",
    dataVersion: "",
    targetVariable: "",
    instance: "",
  });
  const [ins, setIns] = useState([
    "c6a.8xlarge",
    "g5.8xlarge",
    "g3.8xlarge",
    "g5.12xlarge",
    "p4d.24xlarge",
  ]);
  const [insValue, setInsValue] = useState("");
  const [modelType, setModelType] = useState([
    "Supervised",
    "Unsupervised",
    "Deep Learning",
  ]);
  const [modelValue, setModelValue] = useState("");
  const [domainLabel, setDomainLabel] = useState([]);

  const [fileData, setFileData] = useState({});
  const [optimise, setOptimise] = useState("");
  const [optData, setOptData] = useState([
    "Optuna",
    "Hyperopt",
    "Random search",
    "None",
  ]);
  const [noTrails, setNoTrails] = useState("");

  const [deepData, setDeepData] = useState({});
  const [learningModel, setLearningModel] = useState([]);
  const [optimiser, setOptimiser] = useState([]);
  const [lossData, setLossData] = useState([]);
  const [finalDeep, setFinalDeep] = useState({});
  const [inpDeep, setInpDeep] = useState({});
  const [timeseriesImg, setTimeseriesImg] = useState([]);

  // const [imageLayers, setImageLayers] = useState(imageData);
  // const [tabularLayers, setTabularLayers] = useState(tabularData);

  const [imageLayers, setImageLayers] = useState(null);
  const [tabularLayers, setTabularLayers] = useState(null);

  const [finalImageData, setFinalImageData] = useState([]);
  const [finalTabularData, setFinalTabularData] = useState({});

  const [addImageLayers, setAddImageLayers] = useState([1]);
  const [addTabularLayers, setAddTabularLayers] = useState([1]);

  let token = JSON.parse(localStorage.getItem("mlanglesToken"));
  let Header = { headers: { Authorization: `Bearer ${token}` } };

  let mapping = {
    object_detection: "Object Detection",
    timeseries: "Time Series",
  };

  useEffect(() => {
    let token = JSON.parse(localStorage.getItem("mlanglesToken"));
    let userdetails = JSON.parse(localStorage.getItem("userDetails"));
    setUserDetails(userdetails);

    if (userdetails) {
      if (!token || userDetails?.permissions?.experiments === 0) {
        navigate("/");
      }
      // fileDatafun();
      fetchData();
    }
  }, []);

  async function fileDatafun() {
    try {
      console.log("fileDatafun");
      setOpen(true);
      const fileD1 = await axios.get(
        `${backend_url}/tracking/default_trining_values`,
        Header
      );
      console.log("runfetch----", fileD1);
      setOpen(false);
      if (fileD1.data.success) {
        setFileData(fileD1.data.data);
      } else {
        funError("Error occured while getting tracking data");
      }
    } catch (e) {
      setOpen(false);
      funError("Error occured while getting tracking data");
    }
  }

  const funError = (data) => {
    toast.current.show({
      severity: "error",
      summary: "Error",
      detail: data,
      life: 2000,
    });
  };

  async function fetchData() {
    try {
      // console.log("fetchData");
      setOpen(true);
      const runfetch = await axios.get(
        `${backend_url}/tracking/tracking/${project_id}`,
        Header
      );
      //console.log("runfetch----", runfetch)
      setOpen(false);
      if (runfetch.data.success) {
        setTarget(runfetch.data.target_feature);
        // setVersion(runfetch.data.data_version);
        setVersion(runfetch.data.urls);
        setRuns(runfetch.data.run_names);
        // setUrls(runfetch.data.urls[0]);
        fileDatafun();
      } else {
        funError("Error occured while getting tracking data");
      }
    } catch (e) {
      setOpen(false);
      funError("Error occured while getting tracking data");
    }
  }

  function errorHide() {
    setTimeout(() => {
      setFieldErr({
        runName: "",
        model: "",
        problemType: "",
        dataVersion: "",
        targetVariable: "",
        instance: "",
        learningModel: "",
        domain_type: "",
        pretrained_model: "",
      });
    }, 5000);
  }

  async function createRunbtn(e) {
    try {
      e.preventDefault();
      console.log("hello");
      let n1 = {};
      let n2 = {};
      console.log("fulldata---", fulldata);

      for (let i in fulldata) {
        if (fulldata[i].isTrue) {
          let inside1 = {};
          let k1 = Object.keys(fulldata[i]);
          for (let x of k1) {
            if (fulldata[i][x].value !== "null" && x !== "isTrue") {
              let v1 = fulldata[i][x].value;
              console.log("v1------", v1, typeof v1);
              inside1[x] = v1;
              if (Number(v1)) {
                inside1[x] = Number(v1);
              } else if (v1 === "true") {
                inside1[x] = true;
              } else if (v1 === "false") {
                inside1[x] = false;
              } else {
                inside1[x] = v1;
              }
            }
          }
          if (modelValue === "Unsupervised") {
            // n1[i] = inside1.replaceAll(/\s/g, '').toLowerCase()
            console.log("inside1---", inside1);
            if (i === "ECLAT") {
              n1[i] = inside1;
            } else {
              n1[i.replaceAll(/\s/g, "").toLowerCase()] = inside1;
            }
          } else if (problemtype === "timeseries") {
            n1[i] = inside1;
          } else {
            n1[i + "()"] = inside1;
          }
        }
      }

      for (let i in fulldata) {
        if (fulldata[i].isTrue) {
          let inside2 = {};
          let k1 = Object.keys(fulldata[i]);
          for (let x of k1) {
            console.log("fulldata--", fulldata);
            if (
              fulldata[i][x].optiArray?.length > 0 &&
              fulldata[i][x].optiArray[0] !== "" &&
              x !== "isTrue"
            ) {
              // inside2[x] = fulldata[i][x].optiArray;

              if (fulldata[i][x]?.options?.length === 0) {
                if (fulldata[i][x].optiArray[1] !== "") {
                  let newAr = fulldata[i][x].optiArray;
                  let afterArrange = [];
                  for (let ab = 0; ab < newAr.length; ab++) {
                    if (Number(newAr[ab])) {
                      afterArrange.push(Number(newAr[ab]));
                    } else if (newAr[ab] === "true") {
                      afterArrange.push(true);
                    } else if (newAr[ab] === "false") {
                      afterArrange.push(false);
                    } else {
                      afterArrange.push(newAr[ab]);
                    }
                  }
                  inside2[x] = afterArrange;
                }
              } else {
                let newAr = fulldata[i][x].optiArray;
                let afterArrange = [];
                for (let ab = 0; ab < newAr.length; ab++) {
                  if (Number(newAr[ab])) {
                    afterArrange.push(Number(newAr[ab]));
                  } else if (newAr[ab] === "true") {
                    afterArrange.push(true);
                  } else if (newAr[ab] === "false") {
                    afterArrange.push(false);
                  } else {
                    afterArrange.push(newAr[ab]);
                  }
                }
                inside2[x] = afterArrange;
              }
            }
          }
          n2[i] = inside2;
        }
      }

      console.log("n1-------", n1);
      console.log("n22-------", n2);

      let initalErr = {
        runName: "",
        model: "",
        problemType: "",
        dataVersion: "",
        targetVariable: "",
        instance: "",
        learningModel: "",
        domain_type: "",
        pretrained_model: "",
      };
      let flag = 0;
      if (!runName) {
        initalErr = { ...initalErr, runName: "Enter Run Name" };
        errorHide();
        flag = 1;
      }
      if (!problemtype) {
        initalErr = { ...initalErr, problemType: "Select Problem Type" };
        errorHide();
        flag = 1;
      }
      if (!dataversion) {
        initalErr = { ...initalErr, dataVersion: "Select Data Version" };
        errorHide();
        flag = 1;
      }
      if (modelValue !== "Unsupervised") {
        if (!targetfeature) {
          initalErr = {
            ...initalErr,
            targetVariable: "Select Target Variable",
          };
          errorHide();
          flag = 1;
        }
      }
      if (!insValue) {
        initalErr = { ...initalErr, instance: "Select Instance Type" };
        errorHide();
        flag = 1;
      }

      setFieldErr(initalErr);

      let runFlag = 0;

      for (let i of runs) {
        if (i === runName) {
          runFlag = 1;
          funError("Run Name need to be unique for a project");
          break;
        }
      }

      if (flag || runFlag) {
        window.scrollTo(0, 0);
      }

      if (!flag && !runFlag) {
        let finalDataPost = {};
        finalDataPost["run_name"] = runName;
        finalDataPost["problem_type"] = problemtype;
        finalDataPost["url"] = dataversion;
        finalDataPost["target_variable"] = targetfeature;
        finalDataPost["project_id"] = project_id;
        finalDataPost["instance"] = insValue;
        finalDataPost["learning_method"] = modelValue.toLowerCase();

        if (optimise && optimise !== "None") {
          finalDataPost["optimization"] = optimise.toLowerCase();
          finalDataPost["triles"] = noTrails ? Number(noTrails) : 1;
          finalDataPost["hyper_data"] = n2;
        } else {
          finalDataPost["triles"] = noTrails ? Number(noTrails) : 1;
          finalDataPost["optimization"] = optimise.toLowerCase();
          finalDataPost["hyper_data"] = n1;
        }

        console.log("finalDataPost-----", finalDataPost);

        setOpen(true);
        const get_UpdateProblemType = await axios.get(
          `${backend_url}/mlflow/update_problemType_targetFeature/${project_id}`,
          Header
        );
        let putData = get_UpdateProblemType.data.project;
        putData["problem_type"] = problemtype;
        putData["target_feature"] = targetfeature;
        console.log("problemTyoe----->>>>>", problemtype);

        const put_UpdateProblemType = await axios.put(
          `${backend_url}/mlflow/update_problemType_targetFeature/${project_id}`,
          putData,
          Header
        );
        setOpen(false);

        console.log("finalDataPost-----", finalDataPost);

        setTimeout(() => {
          navigate(
            `/experimentTracking/Projects/${project_name}/${project_id}`
          );
        }, 10000);

        setOpen(true);
        const res = await axios.post(
          `${backend_url}/mlflow/mlflow_tracking/${project_id}`,
          finalDataPost,
          Header
        );
        setOpen(false);
        if (res.data.success) {
          // navigate(`/experimentTracking/Projects/${project_name}/${project_id}`);
        } else {
          funError(res.data.message);
        }
      }
    } catch (e) {
      console.log("There is an Error--", e);
      setOpen(false);
      funError("Error occurred while creating a run with sever side");
    }
  }

  async function problemsselect(e) {
    try {
      let v1 = e.target.value;
      setProblemtype(v1);
      let selectedCheck = [];
      let d1 = document.getElementsByClassName("checkbox");
      for (let i = 0; i < d1?.length; i++) {
        if (d1[i].checked) {
          selectedCheck.push(d1[i].value);
          d1[i].click();
        }
      }
      setSelected([]);

      if (modelValue === "Deep Learning") {
        // setLearningModel(deepData.problem_type[v1]);
        setLearningModel(
          deepData.domain[finalDeep?.domain_type].problem_type[v1]
        );
        setShowalgo(false);
        setSelected([]);
      } else {
        setOpen(true);
        const typeSelect = await axios.post(
          `${backend_url}/tracking/hyper_perameter_tuning_inputs_and_labels`,
          {
            type: v1,
          },
          Header
        );
        setOpen(false);
        if (typeSelect.data.success) {
          setShowalgo(true);
          setFulldata(typeSelect.data.data);
          // console.log("timeseries && dataversion---------------", v1, dataversion);
          updateData(typeSelect.data.data, e.target.value);
          if (v1 === "clustering" && dataversion) {
            clusImgFn(v1, dataversion);
          }
          if (v1 === "timeseries" && dataversion) {
            // console.log("hiiiiiiiiii34567890")
            timeseriesImgFun(v1, targetfeature, dataversion);
          }
        } else {
          funError(
            "Error occured while taking hyper_perameter_tuning_inputs_and_labels"
          );
        }
      }
    } catch (e) {
      console.log("There is an Error--", e);
      setOpen(false);
      funError(
        "Error occured while hyper_perameter_tuning_inputs_and_labels with sever side"
      );
    }
  }

  function updateData(fully, value) {
    try {
      console.log("fileData---", fileData);

      if (value in fileData) {
        console.log("---", fileData[value]);
        let s1 = [];
        let k1 = Object.keys(fileData[value]);
        for (let i of k1) {
          for (let x in fully) {
            if (i === x) {
              fully[i] = fileData[value][x];
              s1.push(i);
            }
          }
        }
        console.log("fully----", fully);
        setFulldata(fully);
        setSelected(s1);
      }
    } catch (e) {
      console.log("updateData e--", e);
    }
  }

  function inputAllGetting(e, main, sub, optimise) {
    let valueData = e.target.value;

    let d1 = { ...fulldata };

    if (optimise && optimise !== "None") {
      let f1 = optimise.split("-");
      if (f1[1]) {
        let second = Number(f1[1]);

        setInputValue({
          ...inputValue,
          [main]: { ...inputValue[main], [sub]: true },
        });

        if (second === 1) {
          d1[main][sub]["optiArray"][0] = valueData;
          setFulldata(d1);
        } else {
          d1[main][sub]["optiArray"][1] = valueData;
          setFulldata(d1);
        }
      } else {
        d1[main][sub]["optiArray"] = valueData;
        setFulldata(d1);
      }
    } else {
      d1[main][sub]["value"] = valueData;
      setFulldata(d1);
    }
  }

  function changingCheckbox(eachAlgo) {
    // console.log("changingCheckbox");
    const copyOfFullData = { ...fulldata };
    for (let i in copyOfFullData) {
      if (i === eachAlgo) {
        if (copyOfFullData[i].isTrue) {
          copyOfFullData[i].isTrue = false;
          // console.log("copyOfFullData---", copyOfFullData[i]);
          let arrCopyOfFullData = Object.keys(copyOfFullData[i]);
          for (let x of arrCopyOfFullData) {
            if (copyOfFullData[i][x].value) {
              copyOfFullData[i][x].value = "null";
              console.log("copyOfFullData[i][x]---", copyOfFullData[i][x]);
              if (copyOfFullData[i][x].options.length > 0) {
                copyOfFullData[i][x].optiArray = [];
              } else {
                copyOfFullData[i][x].optiArray = ["", ""];
              }
            }
          }
        } else {
          copyOfFullData[i].isTrue = true;
        }
      }
    }
    setFulldata(copyOfFullData);
    let selectedCheck = [];
    for (let i in copyOfFullData) {
      if (copyOfFullData[i].isTrue) {
        selectedCheck.push(i);
      }
    }
    // console.log("selectedCheck-------", selectedCheck);
    setSelected(selectedCheck);
  }

  async function settingModel(e) {
    let v1 = e.target.value;
    setModelValue(v1);
    if (v1 === "Supervised") {
      setProblem(["classification", "regression", "timeseries"]);
    } else if (v1 === "Unsupervised") {
      setProblem([
        "clustering",
        "association rules",
        // "dimensionality reduction",
      ]);
    } else {
      try {
        setOpen(true);
        const getDeepLearning = await axios.get(
          `${backend_url}/tracking/deep_learning`,
          Header
        );
        setOpen(false);
        console.log("getDeepLearning--", getDeepLearning);
        if (getDeepLearning.data.success) {
          let p1 = Object.keys(getDeepLearning?.data?.data?.domain);
          setDomainLabel(p1);
          console.log("p1---------->", p1);
          setDeepData(getDeepLearning?.data?.data);
          setOptimiser(Object.keys(getDeepLearning?.data?.data?.optimizer));
          setLossData(Object.keys(getDeepLearning?.data?.data?.loss_functions));
          setSelected([]);
          setShowalgo(false);

          console.log(
            "getDeepLearning.data.layers------------------->>>>>>>>",
            getDeepLearning.data.data.layers
          );
          let d1234 = getDeepLearning.data.data.layers;

          setImageLayers(d1234);
          setTabularLayers({
            input: d1234,
            "fully connected": d1234,
            output: d1234,
          });
        } else {
          funError(getDeepLearning.message);
        }
      } catch (e) {
        console.log("error--", e);
        setOpen(false);
      }
    }
  }

  async function timeseriesImgFun(probType, tarFeature, dataVersion) {
    try {
      if (probType === "timeseries" && tarFeature && dataVersion) {
        setOpen(true);
        let d1 = {
          project_id: project_id,
          problem_type: probType,
          target_variable: tarFeature,
          url: dataVersion,
        };

        const res = await axios.post(
          `${backend_url}/tracking/timeseries_graphs`,
          d1,
          Header
        );
        setOpen(false);
        if (res.data.success) {
          console.log("res---", res);
          setTimeseriesImg(res.data.url);
        }
      }
    } catch (e) {
      console.log("error---", e);
      setOpen(false);
    }
  }

  async function clusImgFn(problemtype1, dataver1) {
    try {
      setOpen(true);

      let d1 = {
        project_id: project_id,
        problem_type: problemtype1,
        run_name: runName,
        url: dataver1,
      };

      const res = await axios.post(
        `${backend_url}/tracking/clustering`,
        d1,
        Header
      );
      setOpen(false);
      if (res.data.success) {
        console.log("res---", res);
        setClusterImg(res.data.urls);
      }
    } catch (e) {
      console.log(e);
      setOpen(false);
    }
  }

  function dataVerFn(e) {
    console.log("dataVerFn---dataVerFn-----------", problemtype);
    let v1 = e.target.value;
    setDataversion(v1);
    if (problemtype === "clustering") {
      clusImgFn(problemtype, v1);
    }
    if (problemtype === "timeseries") {
      timeseriesImgFun(problemtype, targetfeature, v1);
    }
  }

  function deepLearningChanging(key, k1, k2, value, format) {
    let copyData = JSON.parse(JSON.stringify(deepData));
    if (["number", "select"].includes(format)) {
      copyData[key][k1][k2]["value"] = value;
    } else {
      let r1 = format.split("-");
      if (r1[1] === "0") {
        copyData[key][k1][k2]["optiArray"][0] = value;
      } else {
        copyData[key][k1][k2]["optiArray"][1] = value;
      }
    }
    setDeepData(copyData);
  }

  async function DeepLearningSubmit(e) {
    e.preventDefault();

    // console.log("deepData---------", deepData, "--------", finalDeep);
    let copyFinal = JSON.parse(JSON.stringify(finalDeep));
    let copydeepDtata = JSON.parse(JSON.stringify(deepData));

    copyFinal.project_id = Number(project_id);
    copyFinal.data_version_link = dataversion;

    let initalErr = {
      runName: "",
      model: "",
      problemType: "",
      dataVersion: "",
      targetVariable: "",
      instance: "",
      learningModel: "",
      domain_type: "",
      pretrained_model: "",
    };
    let flag = 0;
    if (!runName) {
      initalErr = { ...initalErr, runName: "Enter Run Name" };
      errorHide();
      flag = 1;
    }
    if (!copyFinal?.domain_type) {
      initalErr = { ...initalErr, domain_type: "Enter Domain Type" };
      errorHide();
      flag = 1;
    }
    if (!copyFinal?.problem_type) {
      initalErr = { ...initalErr, problemType: "Enter Problem Type" };
      errorHide();
      flag = 1;
    }
    if (!copyFinal?.data_version_link) {
      initalErr = { ...initalErr, dataVersion: "Enter Data Version" };
      errorHide();
      flag = 1;
    }
    // if (!copyFinal?.pretrained_model) {
    //   initalErr = {
    //     ...initalErr,
    //     pretrained_model: "Enter Transfer Learning Model",
    //   };
    //   errorHide();
    //   flag = 1;
    // }

    setFieldErr(initalErr);

    if (flag) {
      window.scrollTo(0, 0);
    }

    let optimi = {
      name: inpDeep?.optimizer,
      params: {},
    };

    if (inpDeep?.optimizer) {
      let optData = copydeepDtata["optimizer"][inpDeep?.optimizer];
      for (let i of Object.keys(optData)) {
        if (optData[i].input > 1) {
          let temp = ["", ""];
          if (optData[i]?.optiArray[0]) {
            temp[0] = Number(optData[i]?.optiArray[0]);
            temp[1] = Number(optData[i]?.optiArray[1]);
            optimi["params"][i] = [temp[0], temp[1]];
          }
        } else {
          let temp;
          if (optData[i]?.value !== "null") {
            if (Number(optData[i]?.value) || optData[i]?.value === "0") {
              temp = Number(optData[i]?.value);
            } else if (optData[i]?.value === "true") {
              temp = true;
            } else if (optData[i]?.value === "false") {
              temp = false;
            } else {
              temp = optData[i]?.value;
            }
            optimi["params"][i] = temp;
          }
        }
      }
    }

    let criterion = {
      name: inpDeep?.lossfun,
      params: {},
    };
    if (inpDeep?.lossfun) {
      let lossf = copydeepDtata["loss_functions"][inpDeep?.lossfun];
      for (let i of Object.keys(lossf)) {
        if (lossf[i].input > 1) {
          let temp = ["", ""];
          if (lossf[i]?.optiArray[0]) {
            temp[0] = Number(lossf[i]?.optiArray[0]);
            temp[1] = Number(lossf[i]?.optiArray[1]);
            criterion["params"][i] = [temp[0], temp[1]];
          }
        } else {
          let temp;
          if (lossf[i]?.value !== "null") {
            if (Number(lossf[i]?.value) || lossf[i]?.value === "0") {
              temp = Number(lossf[i]?.value);
            } else if (lossf[i]?.value === "true") {
              temp = true;
            } else if (lossf[i]?.value === "false") {
              temp = false;
            } else {
              temp = lossf[i]?.value;
            }
            criterion["params"][i] = temp;
          }
        }
      }
    }
    if (inpDeep?.optimizer) {
      copyFinal.optimizer = optimi;
    }
    if (inpDeep?.lossfun) {
      copyFinal.criterion = criterion;
    }
    if (modelValue === "Deep Learning") {
      copyFinal.learning_method = "deeplearning";
    }
    copyFinal.run_name = runName;

    console.log("copyFinal------", copyFinal);

    if (!flag) {
      try {
        setOpen(true);
        setTimeout(() => {
          navigate(
            `/experimentTracking/Projects/${project_name}/${project_id}`
          );
        }, 10000);

        if (finalDeep?.domain_type === "tabular") {
          copyFinal.layers = removeEmptyDataOfTabularData();
          const res = await axios.post(
            `${backend_url}/tracking/tabular`,
            copyFinal,
            Header
          );
        } else {
          copyFinal.layer_list = finalImageData;
          const res = await axios.post(
            `${backend_url}/tracking/deep_learning`,
            copyFinal,
            Header
          );
        }
        setOpen(false);
        // console.log("res----", res);
      } catch (e) {
        console.log("error--", e);
      }
    }
  }

  function changeDeepLearning(e, domain) {
    let { name, value } = e.target;
    let temp;
    console.log("v1----", value);
    if (Number(value)) {
      console.log("number");
      temp = Number(value);
    } else {
      console.log("string");
      temp = value;
    }
    console.log("temp----", temp);
    setFinalDeep({ ...finalDeep, [name]: temp });

    if (domain) {
      console.log("cfghj");
      console.log(
        "deepData.domain[domainLabel].problem_type----------",
        deepData.domain[value].problem_type,
        domainLabel
      );
      setProblem(Object.keys(deepData.domain[value].problem_type));
    }
  }

  function imagelayerChanging(data) {
    const { index, layerType } = data;
    const imageCopyData = JSON.parse(JSON.stringify(finalImageData));
    if (imageCopyData[index]) {
      imageCopyData[index] = { name: layerType, params: {} };
    } else {
      imageCopyData.push({ name: layerType, params: {} });
    }
    setFinalImageData(imageCopyData);
  }

  function imagelayerValueChanging(data) {
    console.log("imagelayerValueChanging---", data);
    let { layerType, key, value, index } = data;
    const imageCopyData = JSON.parse(JSON.stringify(finalImageData));

    let temp = "";
    if (Number(value) || value === "0") {
      temp = Number(value);
    } else if (value === "true") {
      temp = true;
    } else if (value === "false") {
      temp = false;
    } else {
      temp = value;
    }
    // optimi["params"][i] = temp;

    imageCopyData[index].params[key] = temp;

    setFinalImageData(imageCopyData);
  }

  function tabularType(data) {
    console.log("finalTabularData----", finalTabularData);
    let { index, value } = data;
    const copyfinalTabularData = JSON.parse(JSON.stringify(finalTabularData));

    if (!copyfinalTabularData[value]) {
      copyfinalTabularData[value] = {
        name: [],
        params: [],
      };
    }
    setFinalTabularData(copyfinalTabularData);
  }
  console.log("finalImageData-----", finalImageData);

  console.log("finalTabularData----", finalTabularData);

  function tabularlayerChanging(data) {
    let { index, layerType, layer } = data;
    console.log("index, layerType, layer-------", index, layerType, layer);

    const copyfinalTabularData = JSON.parse(JSON.stringify(finalTabularData));
    copyfinalTabularData[layerType]["name"][index] = layer;
    copyfinalTabularData[layerType]["params"][index] = {};
    setFinalTabularData(copyfinalTabularData);
  }

  function tabularlayerValueChanging(data) {
    let { index, layerType, layer, key, value } = data;
    console.log(
      "index,layerType,layer,key,value-----",
      index,
      layerType,
      layer,
      key,
      value
    );

    const copyfinalTabularData = JSON.parse(JSON.stringify(finalTabularData));

    let temp = "";
    if (Number(value) || value === "0") {
      temp = Number(value);
    } else if (value === "true") {
      temp = true;
    } else if (value === "false") {
      temp = false;
    } else {
      temp = value;
    }

    copyfinalTabularData[layerType]["params"][index][key] = temp;

    setFinalTabularData(copyfinalTabularData);
  }

  function changingInputLayersValueForTABULAR(data) {
    let { layerType, layer, key, value } = data;
    let copylayers = JSON.parse(JSON.stringify(tabularLayers));
    copylayers[layerType][layer][key].value = value;
    setTabularLayers(copylayers);
  }

  function addingLayers() {
    if (finalDeep?.domain_type === "image") {
      let lostOne;
      let copyArr = JSON.parse(JSON.stringify(addImageLayers));
      if (copyArr.length > 0) {
        let maxOne1 = Math.max(...copyArr);
        // lostOne = copyArr.pop() + 1;
        lostOne = maxOne1 + 1;
      } else {
        lostOne = 0;
      }

      let newArr1 = JSON.parse(JSON.stringify(addImageLayers));
      newArr1.push(lostOne);
      setAddImageLayers(newArr1);
      // setAddImageLayers([...addImageLayers, 1]);
    } else if (finalDeep?.domain_type === "tabular") {
      let lostTwo;
      let copyArr2 = JSON.parse(JSON.stringify(addTabularLayers));
      if (copyArr2.length > 0) {
        lostTwo = copyArr2.pop() + 1;
      } else {
        lostTwo = 0;
      }

      let newArr2 = JSON.parse(JSON.stringify(addTabularLayers));
      newArr2.push(lostTwo);
      setAddTabularLayers(newArr2);
      // setAddTabularLayers([...addTabularLayers, 1]);
    }
  }

  function removeEmptyDataOfTabularData() {
    const newData = JSON.parse(JSON.stringify(finalTabularData));
    let arrayData = Object.keys(newData);

    for (let i of arrayData) {
      let noNullName = newData[i].name.filter((ele) => ele);
      let noNullparams = newData[i].params.filter((ele) => ele);
      newData[i].name = noNullName;
      newData[i].params = noNullparams;
    }

    console.log("newData-------", newData);
    return newData;
  }

  console.log("imagelayers---------", imageLayers);
  console.log("finalDeep?.domain_type-----------", finalDeep?.domain_type);

  return (
    <div>
      {/* <button style={{ marginTop: "600px", backgroundColor: "red", color: "black" }} onClick={removeEmptyDataOfTabularData}> jhg HIIIIIIIIIIIII  </button> */}
      <header>
        <div className="d-flex align-items-center justify-content-between">
          <div className="d-flex align-items-center">
            <div className="logohover">
              <div className="newlogo">
                <img src={mlimage} className="newlogos" alt="" />
              </div>
            </div>
            <Link to="/Home">
              <h4 className="capitailze">
                {userDetails?.user_full_name} workspace /
              </h4>
            </Link>
            <Link to="/Projects">
              <span> Projects/</span>
            </Link>
            <Link
              to={`/projectpipeline/Projects/${project_name}/${project_id}`}
            >
              <span className="capitailze">{project_name}/</span>
            </Link>
            <span className="capitailze">Experiment Tracking</span>
          </div>
          <Superuser
            logoutClicked={() => {
              setLogout(true);
            }}
          />
        </div>
      </header>

      <Dialog
        className="logout-dialogbox"
        visible={logout}
        style={{ width: "40vw" }}
        onHide={() => setLogout(false)}
      >
        <Logout no={() => setLogout(false)} />
      </Dialog>

      <Loading loading={open} />
      <Toast ref={toast} />

      <Mlsidebar data={path} />

      <div className="middlepart">
        <div className="each-pipehead d-flex">
          <h4
            onClick={(e) => {
              setCurrentTab("pipeline");
              navigate(
                `/projectpipeline/Projects/${project_name}/${project_id}`
              );
            }}
          >
            PIPELINES
          </h4>
          <h4
            onClick={(e) => setCurrentTab("experimentTracking")}
            className="green"
          >
            EXPERIMENT TRACKING
          </h4>
        </div>
        <form onSubmit={createRunbtn}>
          <div className="run padTop80px">
            <div>
              <div className="run-checkbox">
                <div className="each-check">
                  <label htmlFor="">Run Name</label>
                  <input
                    className="brightColor"
                    autoComplete="off"
                    type="text"
                    placeholder="Enter Run Name"
                    value={runName}
                    onChange={(e) => setRunName(e.target.value)}
                  />
                  <span className="fieldError">{fieldErr.runName}</span>
                </div>
                <div className="each-check">
                  <label htmlFor="">Learning Method</label>
                  <FormControl fullWidth>
                    <InputLabel id="demo-simple-select-label">
                      Select Learning Method
                    </InputLabel>
                    <Select
                      labelId="demo-simple-select-label"
                      id="demo-simple-select"
                      className="demo-simple-select cpaitalise"
                      value={modelValue}
                      label="Problem Type"
                      onChange={(e) => {
                        settingModel(e);
                      }}
                    >
                      {modelType?.map((data, idx) => {
                        return (
                          <MenuItem key={idx} value={data}>
                            {data}
                          </MenuItem>
                        );
                      })}
                    </Select>
                    <span className="fieldError">
                      {fieldErr?.learningModel}
                    </span>
                  </FormControl>
                </div>
                {modelValue === "Deep Learning" && (
                  <div className="each-check">
                    <label htmlFor="">Domain Type</label>
                    <FormControl fullWidth>
                      <InputLabel id="demo-simple-select-label">
                        Domain Problem Type
                      </InputLabel>
                      <Select
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        className="demo-simple-select"
                        value={finalDeep?.domain_type}
                        label="domain_type"
                        name="domain_type"
                        onChange={(e) => changeDeepLearning(e, "domain")}
                      >
                        {domainLabel?.map((data, idx) => {
                          let dat1 =
                            data.charAt(0).toUpperCase() + data.slice(1);
                          return (
                            <MenuItem key={idx} value={data}>
                              {dat1}
                            </MenuItem>
                          );
                        })}
                      </Select>
                      <span className="fieldError">
                        {fieldErr?.domain_type}
                      </span>
                    </FormControl>
                  </div>
                )}

                <div className="each-check">
                  <label htmlFor="">Problem Type</label>
                  <FormControl fullWidth>
                    <InputLabel id="demo-simple-select-label">
                      Select Problem Type
                    </InputLabel>
                    <Select
                      labelId="demo-simple-select-label"
                      id="demo-simple-select"
                      className="demo-simple-select capitailze"
                      value={problemtype}
                      name="problem_type"
                      label="Problem Type"
                      onChange={(e) => {
                        problemsselect(e);
                        changeDeepLearning(e);
                      }}
                    >
                      {problem?.map((data, idx) => {
                        return (
                          <MenuItem
                            className="capitailze"
                            key={idx}
                            value={data}
                          >
                            {mapping[data] ? mapping[data] : data}
                          </MenuItem>
                        );
                      })}
                    </Select>
                    <span className="fieldError">{fieldErr?.problemType}</span>
                  </FormControl>
                </div>
                {modelValue === "Deep Learning" &&
                  finalDeep?.domain_type === "image" && (
                    <div className="each-check">
                      <label htmlFor=""> Transfer Learning Model </label>
                      <FormControl fullWidth>
                        <InputLabel id="demo-simple-select-label">
                          Select Transfer Learning Model
                        </InputLabel>
                        <Select
                          labelId="demo-simple-select-label"
                          id="demo-simple-select"
                          className="demo-simple-select"
                          value={finalDeep?.pretrained_model}
                          label="pretrained_model"
                          name="pretrained_model"
                          onChange={(e) => changeDeepLearning(e)}
                        >
                          {learningModel?.map((data, idx) => {
                            return (
                              <MenuItem key={idx} value={data}>
                                {data}
                              </MenuItem>
                            );
                          })}
                        </Select>
                        <span className="fieldError">
                          {fieldErr?.pretrained_model}
                        </span>
                      </FormControl>
                    </div>
                  )}
                <div className="each-check">
                  <label htmlFor="">Instance Type</label>
                  <FormControl fullWidth>
                    <InputLabel id="demo-simple-select-label">
                      Select Instance Type
                    </InputLabel>
                    <Select
                      labelId="demo-simple-select-label"
                      id="demo-simple-select"
                      className="demo-simple-select"
                      value={insValue}
                      label="Problem Type"
                      onChange={(e) => {
                        setInsValue(e.target.value);
                      }}
                    >
                      {ins?.map((data, idx) => {
                        return (
                          <MenuItem key={idx} value={data}>
                            {data}
                          </MenuItem>
                        );
                      })}
                    </Select>
                    <span className="fieldError">{fieldErr.instance}</span>
                  </FormControl>
                </div>
                <div className="each-check">
                  <label htmlFor="">Data Version</label>
                  <FormControl fullWidth>
                    <InputLabel id="demo-simple-select-label">
                      Select Data Version
                    </InputLabel>
                    <Select
                      labelId="demo-simple-select-label"
                      id="demo-simple-select"
                      className="demo-simple-select"
                      value={dataversion}
                      label="Data Version"
                      onChange={(e) => dataVerFn(e)}
                    >
                      {version?.map((data, idx) => {
                        let newData = data?.split("/");
                        let no = newData[5];
                        let last = newData[newData.length - 1]?.split(".");
                        let values = last[0] + " " + "V" + no;
                        // console.log("data-----", data);
                        return (
                          <MenuItem key={idx} value={data}>
                            {values}
                          </MenuItem>
                        );
                      })}
                    </Select>
                    <span className="fieldError">{fieldErr?.dataVersion}</span>
                  </FormControl>
                </div>
                {(modelValue === "Supervised" ||
                  finalDeep?.domain_type?.toLowerCase() === "tabular") && (
                  <div className="each-check">
                    <label htmlFor="">Target Variable</label>
                    <FormControl fullWidth>
                      <InputLabel id="demo-simple-select-label">
                        Select Target Variable
                      </InputLabel>
                      <Select
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        className="demo-simple-select"
                        value={targetfeature}
                        label="Target Feature"
                        onChange={(e) => {
                          setTargetfeature(e.target.value);
                          timeseriesImgFun(
                            problemtype,
                            e.target.value,
                            dataversion
                          );
                        }}
                      >
                        {target?.map((data, idx) => {
                          return (
                            <MenuItem key={idx} value={data}>
                              {data}
                            </MenuItem>
                          );
                        })}
                      </Select>
                      <span className="fieldError">
                        {fieldErr?.targetVariable}
                      </span>
                    </FormControl>
                  </div>
                )}
              </div>
            </div>

            {problemtype === "clustering" && clusterImg.length > 0 && (
              <div className="marTop48">
                <h4 className="greenTitle">
                  Visualizations for Cluster Selection
                </h4>
                <div className="fleximgcreate">
                  {clusterImg?.map((item, idx) => {
                    return <img src={item} alt="idx" key={idx} />;
                  })}
                </div>
              </div>
            )}

            {problemtype === "timeseries" && timeseriesImg.length > 0 && (
              <div className="marTop48">
                <h4 className="greenTitle">
                  Visualizations for Time Series Selection
                </h4>
                <div className="fleximgcreate">
                  {timeseriesImg?.map((item, idx) => {
                    return <img src={item} alt="idx" key={idx} />;
                  })}
                </div>
              </div>
            )}

            {showalogo && (
              <div className="algo">
                <h5 className="green">Select the Algorithm</h5>
                <div className="algo-input">
                  {Object.keys(fulldata)?.map((ele, idx) => {
                    return (
                      <React.Fragment key={idx}>
                        <div>
                          <InputCheckbox
                            ele={ele}
                            idx={idx}
                            fulldata={fulldata}
                            changingCheckbox={changingCheckbox}
                          />
                        </div>
                      </React.Fragment>
                    );
                  })}
                </div>
              </div>
            )}

            {selected.length > 0 && (
              <>
                {problemtype !== "timeseries" &&
                  modelValue !== "Unsupervised" && (
                    <div>
                      <h5 className="green">Hyperparameter Optimization </h5>
                      <div className="optimise">
                        <div className="each-check">
                          <label>Optimization Techniques</label>
                          <FormControl fullWidth>
                            <InputLabel id="demo-simple-select-label">
                              None
                            </InputLabel>
                            <Select
                              labelId="demo-simple-select-label"
                              id="demo-simple-select"
                              className="demo-simple-select"
                              value={optimise}
                              label="Problem Type"
                              onChange={(e) => {
                                // if (e.target.value === "None") {
                                //   setOptimise("");
                                // } else {
                                setOptimise(e.target.value);
                                // }
                              }}
                            >
                              {optData?.map((data, idx) => {
                                return (
                                  <MenuItem key={idx} value={data}>
                                    {data}
                                  </MenuItem>
                                );
                              })}
                            </Select>
                          </FormControl>
                        </div>
                        {optimise && optimise !== "None" ? (
                          <div className="each-check">
                            <label htmlFor="">Number of Trails</label>
                            <input
                              className="brightColor trailsInput"
                              autoComplete="off"
                              required
                              type="number"
                              placeholder="Enter Number of Trails"
                              value={noTrails}
                              min="0"
                              // onChange={(e) => setNoTrails(e.target.value)}
                              onChange={(e) => {
                                const inputValue = e.target.value;
                                const isPositiveNumber = /^[+]?\d*$/.test(
                                  inputValue
                                );
                                if (isPositiveNumber || inputValue === "") {
                                  setNoTrails(inputValue);
                                }
                              }}
                            />
                          </div>
                        ) : (
                          <></>
                        )}
                      </div>
                    </div>
                  )}

                <div className="inpHyper">
                  <h5 className="green">Input Hyperparameters</h5>
                  <div className="hyper-table mar0">
                    <div className="d-flex justify-content-between table-heading-hyper">
                      <div className="d-flex width20 justify-content-between">
                        <div className="sno width20">
                          <h2 className="hyper-th">#</h2>
                        </div>
                        <div className="head-algo width80">
                          <h2 className="hyper-th">Algorithm </h2>
                        </div>
                      </div>
                      <div className="head-hyper justifyCenter width80">
                        <h2 className="hyper-th">Hyperparameter </h2>
                      </div>
                    </div>

                    <div className="d-flex justify-content-between align-items-center column-dir">
                      {Object.keys(fulldata)?.map((ele, ind) => {
                        return (
                          <React.Fragment key={ind}>
                            {selected?.map((cur, idx) => {
                              if (cur === ele) {
                                return (
                                  <div
                                    key={idx}
                                    className="d-flex w100 mar12 align-items-center"
                                  >
                                    <div className="d-flex width20 justify-content-between">
                                      <div className="sno width20">
                                        <h2 className="hyper-td">{idx + 1}</h2>
                                      </div>
                                      <div className="head-algo width80">
                                        <h2 className="hyper-td">{ele}</h2>
                                      </div>
                                    </div>
                                    <div className="head-hyper width80">
                                      {Object.keys(fulldata[ele])?.map(
                                        (elements, index) => {
                                          let d1 = fulldata[ele][elements];
                                          let type1 = "";

                                          if (d1?.options?.length === 0) {
                                            if (d1?.limits[1] === "inf") {
                                              type1 = "Infinity";
                                            } else if (d1?.limits[1]) {
                                              type1 = d1?.limits[1];
                                            }

                                            return (
                                              <div
                                                className="equalwidth"
                                                key={index}
                                              >
                                                <div className="hoverInfo">
                                                  <label
                                                    htmlFor="elements"
                                                    className="infoLabel"
                                                  >
                                                    {elements}
                                                    <span className="infoIcon">
                                                      i
                                                    </span>
                                                  </label>
                                                  <span className="showInfo">
                                                    Enter {d1?.type} value
                                                    between {d1?.limits[0]} to{" "}
                                                    {type1}
                                                  </span>
                                                </div>
                                                {optimise &&
                                                optimise !== "None" ? (
                                                  <>
                                                    <div className="optimiFlex">
                                                      <div>
                                                        <h2>From</h2>
                                                        <input
                                                          autoComplete="off"
                                                          type={
                                                            [
                                                              "int",
                                                              "float",
                                                            ].includes(d1?.type)
                                                              ? "number"
                                                              : "text"
                                                          }
                                                          value={
                                                            d1?.optiArray[0] ===
                                                            ""
                                                              ? ""
                                                              : d1?.optiArray[0]
                                                          }
                                                          onChange={(e) =>
                                                            inputAllGetting(
                                                              e,
                                                              ele,
                                                              elements,
                                                              "optimise-1"
                                                            )
                                                          }
                                                          min={
                                                            d1?.limits[0]
                                                              ? d1?.limits[0]
                                                              : 0
                                                          }
                                                          max={
                                                            d1?.limits[1] ===
                                                            "inf"
                                                              ? "1.797693134862315E+308"
                                                              : d1?.limits[1]
                                                          }
                                                          step={
                                                            [
                                                              "int",
                                                              "float",
                                                            ].includes(d1?.type)
                                                              ? d1?.type ===
                                                                "int"
                                                                ? "1"
                                                                : "any"
                                                              : ""
                                                          }
                                                          // required={
                                                          //   JSON.stringify(
                                                          //     d1?.optiArray[1]
                                                          //   )
                                                          //     ? true
                                                          //     : false
                                                          // }
                                                        />
                                                      </div>
                                                      <div>
                                                        <h2>To</h2>
                                                        <input
                                                          autoComplete="off"
                                                          type={
                                                            [
                                                              "int",
                                                              "float",
                                                            ].includes(d1?.type)
                                                              ? "number"
                                                              : "text"
                                                          }
                                                          value={
                                                            d1?.optiArray[1] ===
                                                            "null"
                                                              ? ""
                                                              : d1?.optiArray[1]
                                                          }
                                                          onChange={(e) =>
                                                            inputAllGetting(
                                                              e,
                                                              ele,
                                                              elements,
                                                              "optimise-2"
                                                            )
                                                          }
                                                          min={
                                                            d1?.optiArray[0]
                                                              ? d1?.optiArray[0]
                                                              : 0
                                                          }
                                                          max={
                                                            d1?.limits[1] ===
                                                            "inf"
                                                              ? "1.797693134862315E+308"
                                                              : d1?.limits[1]
                                                          }
                                                          step={
                                                            [
                                                              "int",
                                                              "float",
                                                            ].includes(d1?.type)
                                                              ? d1?.type ===
                                                                "int"
                                                                ? "1"
                                                                : "any"
                                                              : ""
                                                          }
                                                          // required={
                                                          //   JSON.stringify(
                                                          //     d1?.optiArray[0]
                                                          //   )
                                                          //     ? true
                                                          //     : false
                                                          // }
                                                        />
                                                      </div>
                                                    </div>
                                                  </>
                                                ) : (
                                                  <input
                                                    autoComplete="off"
                                                    type={
                                                      ["int", "float"].includes(
                                                        d1?.type
                                                      )
                                                        ? "number"
                                                        : "text"
                                                    }
                                                    value={
                                                      d1?.value === "null"
                                                        ? ""
                                                        : d1?.value
                                                    }
                                                    onChange={(e) =>
                                                      inputAllGetting(
                                                        e,
                                                        ele,
                                                        elements
                                                      )
                                                    }
                                                    min={
                                                      d1?.limits[0]
                                                        ? d1?.limits[0]
                                                        : 0
                                                    }
                                                    max={
                                                      d1?.limits[1] === "inf"
                                                        ? "1.797693134862315E+308"
                                                        : d1?.limits[1]
                                                    }
                                                    step={
                                                      ["int", "float"].includes(
                                                        d1?.type
                                                      )
                                                        ? d1?.type === "int"
                                                          ? "1"
                                                          : "any"
                                                        : ""
                                                    }
                                                  />
                                                )}
                                              </div>
                                            );
                                          } else {
                                            if (elements === "isTrue") {
                                            } else {
                                              // console.log("d1---", d1);
                                              return (
                                                <div
                                                  className="equalwidth d-flex"
                                                  key={index}
                                                >
                                                  <label htmlFor="elements">
                                                    {elements}
                                                  </label>
                                                  {optimise &&
                                                  optimise !== "None" ? (
                                                    <Select
                                                      labelId="demo-simple-select-label"
                                                      id="demo-simple-select"
                                                      className="demo-simple-select"
                                                      value={d1?.optiArray}
                                                      multiple
                                                      label="select value"
                                                      onChange={(e) =>
                                                        inputAllGetting(
                                                          e,
                                                          ele,
                                                          elements,
                                                          "optimise"
                                                        )
                                                      }
                                                    >
                                                      {d1?.options?.map(
                                                        (data, idx) => {
                                                          return (
                                                            <MenuItem
                                                              key={idx}
                                                              value={data}
                                                            >
                                                              {data}
                                                            </MenuItem>
                                                          );
                                                        }
                                                      )}
                                                    </Select>
                                                  ) : (
                                                    <Select
                                                      labelId="demo-simple-select-label"
                                                      id="demo-simple-select"
                                                      className="demo-simple-select"
                                                      value={d1?.value}
                                                      label="select value"
                                                      onChange={(e) =>
                                                        inputAllGetting(
                                                          e,
                                                          ele,
                                                          elements
                                                        )
                                                      }
                                                    >
                                                      {d1?.options?.map(
                                                        (data, idx) => {
                                                          return (
                                                            <MenuItem
                                                              key={idx}
                                                              value={data}
                                                            >
                                                              {data}
                                                            </MenuItem>
                                                          );
                                                        }
                                                      )}
                                                    </Select>
                                                  )}
                                                </div>
                                              );
                                            }
                                          }
                                        }
                                      )}
                                    </div>
                                  </div>
                                );
                              }
                            })}
                          </React.Fragment>
                        );
                      })}
                    </div>
                  </div>
                </div>

                <div className="saving">
                  <button className="commonbtn" type="submit">
                    Create Run
                  </button>
                </div>
              </>
            )}
          </div>
        </form>

        {modelValue === "Deep Learning" &&
          ["image", "tabular"].includes(finalDeep?.domain_type) && (
            <div className="run">
              <div className="end">
                <button className="addLayer" onClick={() => addingLayers()}>
                  Add Layer
                </button>
              </div>

              {finalDeep?.domain_type === "image" ||
              (finalDeep?.domain_type === "tabular" &&
                problemtype === "time series") ? (
                <div className="layersWrap">
                  {addImageLayers?.map((item, idx) => {
                    return (
                      <>
                        {imageLayers && (
                          <ImageLayers
                            key={item}
                            index={idx}
                            imageLayers={imageLayers}
                            imagelayerChanging={imagelayerChanging}
                            imagelayerValueChanging={imagelayerValueChanging}
                          />
                        )}
                      </>
                    );
                  })}
                </div>
              ) : (
                <div className="layersWrap">
                  {}
                  {addTabularLayers?.map((item, idx) => {
                    return (
                      <TabularLayers
                        key={item}
                        tabularLayers={tabularLayers}
                        changingInputLayersValueForTABULAR={
                          changingInputLayersValueForTABULAR
                        }
                        index={idx}
                        tabularType={tabularType}
                        tabularlayerChanging={tabularlayerChanging}
                        tabularlayerValueChanging={tabularlayerValueChanging}
                      />
                    );
                  })}
                </div>
              )}

              <div>
                <form onSubmit={DeepLearningSubmit}>
                  <h5 className="green">Hyperparameter Optimization </h5>
                  <div className=" run-checkbox">
                    <div className="each-check">
                      <label htmlFor="">Epochs</label>
                      <input
                        className="brightColor"
                        autoComplete="off"
                        required
                        type="number"
                        name="epochs"
                        min="0"
                        value={finalDeep?.epochs}
                        onChange={changeDeepLearning}
                      />
                    </div>
                    <div className="each-check">
                      <label htmlFor="">Batch Size</label>
                      <input
                        className="brightColor"
                        autoComplete="off"
                        required
                        type="number"
                        name="batchsize"
                        min="0"
                        value={finalDeep?.batchsize}
                        onChange={changeDeepLearning}
                      />
                    </div>
                  </div>

                  <div className=" run-checkbox">
                    <div className="each-check">
                      <label htmlFor="">Optimizer</label>
                      <FormControl fullWidth>
                        <InputLabel id="demo-simple-select-label">
                          Select Optimiser
                        </InputLabel>
                        <Select
                          labelId="demo-simple-select-label"
                          id="demo-simple-select"
                          className="demo-simple-select"
                          value={inpDeep?.optimizer}
                          name="optimizer"
                          label="Data Version"
                          onChange={(e) =>
                            setInpDeep({
                              ...inpDeep,
                              ["optimizer"]: e.target.value,
                            })
                          }
                        >
                          {optimiser?.map((data, idx) => {
                            return (
                              <MenuItem key={idx} value={data}>
                                {data}
                              </MenuItem>
                            );
                          })}
                        </Select>
                      </FormControl>
                    </div>

                    {inpDeep?.optimizer && (
                      <>
                        {Object.keys(
                          deepData["optimizer"][inpDeep?.optimizer]
                        )?.map((v1, k1) => {
                          let d1 =
                            deepData["optimizer"][inpDeep?.optimizer][v1];
                          if (d1.input > 1) {
                            return (
                              <>
                                <div className="optimiFlex1">
                                  <div className="hoverInfo">
                                    <label
                                      htmlFor="elements"
                                      className="infoLabel info1"
                                    >
                                      {v1}
                                      <span className="infoIcon">i</span>
                                    </label>
                                    <span className="showInfo">
                                      Enter {d1?.type} value between{" "}
                                      {d1?.limits[0]} to {d1?.limits[1]}
                                    </span>
                                  </div>
                                  <div className="fle">
                                    <div className="w50">
                                      <h2>From</h2>
                                      <input
                                        className="w100"
                                        autoComplete="off"
                                        type={
                                          ["int", "float"].includes(d1?.type)
                                            ? "number"
                                            : "text"
                                        }
                                        value={
                                          d1?.optiArray[0] === ""
                                            ? ""
                                            : d1?.optiArray[0]
                                        }
                                        onChange={(e) =>
                                          deepLearningChanging(
                                            "optimizer",
                                            inpDeep?.optimizer,
                                            v1,
                                            e.target.value,
                                            "range-0"
                                          )
                                        }
                                        min={d1?.limits[0] ? d1?.limits[0] : 0}
                                        max={
                                          d1?.limits[1] === "inf"
                                            ? "1.797693134862315E+308"
                                            : d1?.limits[1]
                                        }
                                        step={
                                          ["int", "float"].includes(d1?.type)
                                            ? d1?.type === "int"
                                              ? "1"
                                              : "any"
                                            : ""
                                        }
                                        // required={
                                        //   JSON.stringify(d1?.optiArray[1])
                                        //     ? true
                                        //     : false
                                        // }
                                      />
                                    </div>
                                    <div className="w50">
                                      <h2>To</h2>
                                      <input
                                        className="w100"
                                        autoComplete="off"
                                        type={
                                          ["int", "float"].includes(d1?.type)
                                            ? "number"
                                            : "text"
                                        }
                                        value={
                                          d1?.optiArray[1] === "null"
                                            ? ""
                                            : d1?.optiArray[1]
                                        }
                                        onChange={(e) =>
                                          deepLearningChanging(
                                            "optimizer",
                                            inpDeep?.optimizer,
                                            v1,
                                            e.target.value,
                                            "range-1"
                                          )
                                        }
                                        min={
                                          d1?.optiArray[0]
                                            ? d1?.optiArray[0]
                                            : 0
                                        }
                                        max={
                                          d1?.limits[1] === "inf"
                                            ? "1.797693134862315E+308"
                                            : d1?.limits[1]
                                        }
                                        step={
                                          ["int", "float"].includes(d1?.type)
                                            ? d1?.type === "int"
                                              ? "1"
                                              : "any"
                                            : ""
                                        }
                                        // required={
                                        //   JSON.stringify(d1?.optiArray[0])
                                        //     ? true
                                        //     : false
                                        // }
                                      />
                                    </div>
                                  </div>
                                </div>
                              </>
                            );
                          } else {
                            if (["int", "float"].includes(d1?.type)) {
                              return (
                                <div className="each-check">
                                  <div className="hoverInfo">
                                    <label
                                      htmlFor="elements"
                                      className="infoLabel info1"
                                    >
                                      {v1}
                                      <span className="infoIcon">i</span>
                                    </label>
                                    <span className="showInfo">
                                      Enter {d1?.type} value between{" "}
                                      {d1?.limits[0]} to {d1?.limits[1]}
                                    </span>
                                  </div>
                                  <input
                                    autoComplete="off"
                                    type={
                                      ["int", "float"].includes(d1?.type)
                                        ? "number"
                                        : "text"
                                    }
                                    value={
                                      d1?.value === "null" ? "" : d1?.value
                                    }
                                    onChange={(e) =>
                                      deepLearningChanging(
                                        "optimizer",
                                        inpDeep?.optimizer,
                                        v1,
                                        e.target.value,
                                        "number"
                                      )
                                    }
                                    min={d1?.limits[0] ? d1?.limits[0] : 0}
                                    max={
                                      d1?.limits[1] === "inf"
                                        ? "1.797693134862315E+308"
                                        : d1?.limits[1]
                                    }
                                    step={
                                      ["int", "float"].includes(d1?.type)
                                        ? d1?.type === "int"
                                          ? "1"
                                          : "any"
                                        : ""
                                    }
                                  />
                                </div>
                              );
                            } else {
                              return (
                                <div className="each-check">
                                  <label htmlFor="">{v1}</label>
                                  <FormControl fullWidth>
                                    {/* <InputLabel id="demo-simple-select-label">{v1}</InputLabel> */}
                                    <Select
                                      labelId="demo-simple-select-label"
                                      id="demo-simple-select"
                                      className="demo-simple-select"
                                      value={d1?.value}
                                      label="select value"
                                      onChange={(e) =>
                                        deepLearningChanging(
                                          "optimizer",
                                          inpDeep?.optimizer,
                                          v1,
                                          e.target.value,
                                          "select"
                                        )
                                      }
                                    >
                                      {d1?.options?.map((data, idx) => {
                                        return (
                                          <MenuItem key={idx} value={data}>
                                            {data}
                                          </MenuItem>
                                        );
                                      })}
                                    </Select>
                                  </FormControl>
                                </div>
                              );
                            }
                          }
                        })}
                      </>
                    )}
                  </div>

                  <div className=" run-checkbox">
                    <div className="each-check">
                      <label htmlFor="">Loss Function</label>
                      <FormControl fullWidth>
                        <InputLabel id="demo-simple-select-label">
                          Select Loss Function
                        </InputLabel>
                        <Select
                          labelId="demo-simple-select-label"
                          id="demo-simple-select"
                          className="demo-simple-select"
                          value={inpDeep?.lossfun}
                          label="Data Version"
                          name="lossfun"
                          onChange={(e) =>
                            setInpDeep({
                              ...inpDeep,
                              ["lossfun"]: e.target.value,
                            })
                          }
                        >
                          {lossData?.map((data, idx) => {
                            return (
                              <MenuItem key={idx} value={data}>
                                {data}
                              </MenuItem>
                            );
                          })}
                        </Select>
                      </FormControl>
                    </div>

                    {inpDeep?.lossfun && (
                      <>
                        {Object.keys(
                          deepData["loss_functions"][inpDeep?.lossfun]
                        )?.map((v1, k1) => {
                          let d1 =
                            deepData["loss_functions"][inpDeep?.lossfun][v1];
                          if (d1.input > 1) {
                            return (
                              <>
                                <div className="optimiFlex1">
                                  <div className="hoverInfo">
                                    <label
                                      htmlFor="elements"
                                      className="infoLabel info1"
                                    >
                                      {v1}
                                      <span className="infoIcon">i</span>
                                    </label>
                                    <span className="showInfo">
                                      Enter {d1?.type} value between{" "}
                                      {d1?.limits[0]} to {d1?.limits[1]}
                                    </span>
                                  </div>
                                  <div className="fle">
                                    <div className="w50">
                                      <h2>From</h2>
                                      <input
                                        className="w100"
                                        autoComplete="off"
                                        type={
                                          ["int", "float"].includes(d1?.type)
                                            ? "number"
                                            : "text"
                                        }
                                        value={
                                          d1?.optiArray[0] === ""
                                            ? ""
                                            : d1?.optiArray[0]
                                        }
                                        onChange={(e) =>
                                          deepLearningChanging(
                                            "loss_functions",
                                            inpDeep?.lossfun,
                                            v1,
                                            e.target.value,
                                            "range-0"
                                          )
                                        }
                                        min={d1?.limits[0] ? d1?.limits[0] : 0}
                                        max={
                                          d1?.limits[1] === "inf"
                                            ? "1.797693134862315E+308"
                                            : d1?.limits[1]
                                        }
                                        step={
                                          ["int", "float"].includes(d1?.type)
                                            ? d1?.type === "int"
                                              ? "1"
                                              : "any"
                                            : ""
                                        }
                                        // required={
                                        //   JSON.stringify(d1?.optiArray[1])
                                        //     ? true
                                        //     : false
                                        // }
                                      />
                                    </div>
                                    <div className="w50">
                                      <h2>To</h2>
                                      <input
                                        className="w100"
                                        autoComplete="off"
                                        type={
                                          ["int", "float"].includes(d1?.type)
                                            ? "number"
                                            : "text"
                                        }
                                        value={
                                          d1?.optiArray[1] === "null"
                                            ? ""
                                            : d1?.optiArray[1]
                                        }
                                        onChange={(e) =>
                                          deepLearningChanging(
                                            "loss_functions",
                                            inpDeep?.lossfun,
                                            v1,
                                            e.target.value,
                                            "range-1"
                                          )
                                        }
                                        min={
                                          d1?.optiArray[0]
                                            ? d1?.optiArray[0]
                                            : 0
                                        }
                                        max={
                                          d1?.limits[1] === "inf"
                                            ? "1.797693134862315E+308"
                                            : d1?.limits[1]
                                        }
                                        step={
                                          ["int", "float"].includes(d1?.type)
                                            ? d1?.type === "int"
                                              ? "1"
                                              : "any"
                                            : ""
                                        }
                                        // required={
                                        //   JSON.stringify(d1?.optiArray[0])
                                        //     ? true
                                        //     : false
                                        // }
                                      />
                                    </div>
                                  </div>
                                </div>
                              </>
                            );
                          } else {
                            if (["int", "float"].includes(d1?.type)) {
                              return (
                                <div className="each-check">
                                  <div className="hoverInfo">
                                    <label
                                      htmlFor="elements"
                                      className="infoLabel info1"
                                    >
                                      {v1}
                                      <span className="infoIcon">i</span>
                                    </label>
                                    <span className="showInfo">
                                      Enter {d1?.type} value between{" "}
                                      {d1?.limits[0]} to {d1?.limits[1]}
                                    </span>
                                  </div>
                                  <input
                                    autoComplete="off"
                                    type={
                                      ["int", "float"].includes(d1?.type)
                                        ? "number"
                                        : "text"
                                    }
                                    value={
                                      d1?.value === "null" ? "" : d1?.value
                                    }
                                    onChange={(e) =>
                                      deepLearningChanging(
                                        "loss_functions",
                                        inpDeep?.lossfun,
                                        v1,
                                        e.target.value,
                                        "number"
                                      )
                                    }
                                    min={d1?.limits[0] ? d1?.limits[0] : 0}
                                    max={
                                      d1?.limits[1] === "inf"
                                        ? "1.797693134862315E+308"
                                        : d1?.limits[1]
                                    }
                                    step={
                                      ["int", "float"].includes(d1?.type)
                                        ? d1?.type === "int"
                                          ? "1"
                                          : "any"
                                        : ""
                                    }
                                  />
                                </div>
                              );
                            } else {
                              return (
                                <div className="each-check">
                                  <label htmlFor="">{v1}</label>
                                  <FormControl fullWidth>
                                    {/* <InputLabel id="demo-simple-select-label">{v1}</InputLabel> */}
                                    <Select
                                      labelId="demo-simple-select-label"
                                      id="demo-simple-select"
                                      className="demo-simple-select"
                                      value={d1?.value}
                                      label="select value"
                                      onChange={(e) =>
                                        deepLearningChanging(
                                          "loss_functions",
                                          inpDeep?.lossfun,
                                          v1,
                                          e.target.value,
                                          "select"
                                        )
                                      }
                                    >
                                      {d1?.options?.map((data, idx) => {
                                        return (
                                          <MenuItem key={idx} value={data}>
                                            {data}
                                          </MenuItem>
                                        );
                                      })}
                                    </Select>
                                  </FormControl>
                                </div>
                              );
                            }
                          }
                        })}
                      </>
                    )}
                  </div>
                  <button className="commonbtn">Submit </button>
                </form>
              </div>
            </div>
          )}
      </div>
    </div>
  );
};

export default CreateRun;
