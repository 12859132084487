import { useEffect, useRef, useState } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import { Toast } from "primereact/toast";
import Mlsidebar from "../Components/Mlsidebar";
import mlimage from "../logo/mlangles360logo.svg";
import Superuser from "../Components/Superuser";
import Logout from "./Logout";
import { Dialog } from "primereact/dialog";
import axios from "axios";
import Loading from "../Components/Loading";
import styles from "./industryDistribution.module.css";
import industryFolder from "../logo/mlFolder.svg";
import { fabClasses } from "@mui/material";
import { backend_url } from "../Config";
// import { backend_url } from "../Config";

const Home = () => {
  const toast = useRef(null);
  const navigate = useNavigate();
  const [open, setOpen] = useState(true);
  const [userDetails, setUserDetails] = useState({});
  const [logout, setLogout] = useState(false);
  const [projects, setProjects] = useState([]);
  const [industries, setIndustries] = useState([]);
  const [projectCount, setProjectCount] = useState({});
  const [selectedIndustry, setSelectedIndustry] = useState();
  const [selectedIndustryProjects, setSelectedIndustryProjects] = useState();

  let token = JSON.parse(localStorage.getItem("mlanglesToken"));
  let Header = { headers: { Authorization: `Bearer ${token}` } };
  let path = window?.location?.href?.split("/")[3];

  useEffect(() => {
    let token = JSON.parse(localStorage.getItem("mlanglesToken"));
    let userdetails = JSON.parse(localStorage.getItem("userDetails"));
    setUserDetails(userdetails);

    if (userdetails) {
      if (!token || userDetails?.permissions?.projects_admin === 0) {
        navigate("/");
      }
      fetchData();
    }
  }, []);

  async function fetchData() {
    try {
      setOpen(true);
      const getprojects = await axios.get(
        `${backend_url}/mlflow/industry_projects`,
        Header
      );

      if (getprojects.data.success) {
        const industriesData = getprojects.data.data;
        setIndustries(Object.keys(industriesData));
        const projectCountData = {};
        Object.keys(industriesData).forEach((industry) => {
          projectCountData[industry] = industriesData[industry].length;
        });
        setProjectCount(projectCountData);
        console.log("getProjects----->", getprojects.data.data);
        setProjects(getprojects.data.data || []);
      }
      setOpen(false);
    } catch (e) {
      console.log("error --", e);
      setOpen(false);
      funError(e.message);
    }
  }

  const dataArray = Object.entries(projects).map(([category, arraydata]) => ({
    [category]: arraydata,
  }));

  const handleIndustryClick = (industry) => {
    setSelectedIndustry(industry);
    navigate(`/Home/${industry}`, {
      state: { data: projects[industry] },
    });
  };
  useEffect(() => {
    console.log("Selected Industry:", selectedIndustry);
    console.log("Projects:", projects);

    if (selectedIndustry && projects[selectedIndustry]) {
      const filteredProjects = projects[selectedIndustry];
      console.log("filteredProjects:", filteredProjects);
      setSelectedIndustryProjects(filteredProjects);
    } else {
      console.error("Selected industry not found in projects.");
    }
  }, [selectedIndustry, projects]);
  console.log("selectedIndustryProjects----->", selectedIndustryProjects);

  const funError = (data) => {
    toast.current.show({
      severity: "error",
      summary: "Error",
      detail: data,
      life: 2000,
    });
  };

  return (
    <div>
      <Loading loading={open} />
      <Toast ref={toast} />
      <header>
        <div className="d-flex align-items-center justify-content-between">
          <div className="d-flex align-items-center">
            <div className="logohover">
              <div className="newlogo">
                <img src={mlimage} className="newlogos" alt="" />
              </div>
            </div>
            <Link to="/Industry">
              <h4 className="capitailze">
                {userDetails?.user_full_name} workspace /
              </h4>
            </Link>
            <span> Industry</span>
          </div>
          <Superuser
            logoutClicked={() => {
              setLogout(true);
            }}
          />
        </div>
      </header>

      <Dialog
        className="logout-dialogbox"
        visible={logout}
        style={{ width: "40vw" }}
        onHide={() => setLogout(false)}
      >
        <Logout no={() => setLogout(false)} />
      </Dialog>

      <Mlsidebar data={path} />
      <div className={styles.main}>
        <div className={styles.title}>USECASES BY INDUSTRY</div>
        <div className={styles.bucketallign}>
          {industries.map((industry, idx) => (
            <div
              className={styles.industry}
              key={industry}
              onClick={() => handleIndustryClick(industry)}
            >
              <div className={styles.top}>
                <div>
                  <img src={industryFolder} alt="" />
                </div>
                <div className={styles.text1} key={idx}>
                  {industry}
                </div>
              </div>
              <div className={styles.bottom}>
                <div className={styles.text2}>Total Projects</div>
                <div className={styles.numofprojects}>
                  {projectCount[industry]}
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default Home;
