import React, { useEffect,useRef, useState } from 'react'
import { Toast } from "primereact/toast";
import Mlsidebar from '../../Components/Mlsidebar'
import mllogo from "../../logo/mlangles360logo.svg"
import mlimage from "../../logo/mlangles360logo.svg";
import { Link, useLocation, useNavigate, useParams } from 'react-router-dom'
import profilePic from "../../logo/profile-pic.png"
import { TabView, TabPanel } from 'primereact/tabview';
import PipeslineBox from '../../Components/PipeslineBox'
import file from "../../logo/file.png"
import { Dropdown } from "primereact";
import { FormControl, InputLabel, MenuItem, Select } from '@mui/material'
import axios from 'axios'
import Loading from '../../Components/Loading'
import Superuser from '../../Components/Superuser';
import { backend_url, backend_url1 } from '../../Config'
import Logout from '../Logout';
import { Dialog } from 'primereact/dialog';


const BatchResult = () => {

  let [project_id, setProject_id] = useState("");
  let [project_name, setProject_name] = useState("");
  const [experiment_name, setExperiment_name] = useState("");
  const [modal_name, setModal_name] = useState("");
  let path = "serving";
  const [projectName, setProjectName] = useState([]);
  const [expName, setExpName] = useState([]);
  const [modelName, setModalName] = useState([]);
  const [open, setOpen] = useState(false);
  const [postserv, setPostserv] = useState([]);
  const [fullModaldata, setFullModaldata] = useState([]);
  const [columns, setCloumns] = useState([]);
  const [colEnterData, setColEnterData] = useState({});
  const [result, setResult] = useState("");
  const [urls, setUrls] = useState("");
  const [col1, setCol1] = useState([]);
  const [feature, setFeature] = useState("");
  const navigate = useNavigate();
  const [userDetails, setUserDetails] = useState({});
  const [logout, setLogout] = useState(false);
  
  const toast = useRef(null);

  const { state } = useLocation();
  const [projectData, setProjectData] = useState({});
  const [fullData, setFullData] = useState([]);

  const [tableHead, setTableHead] = useState([]);

  let token = JSON.parse(localStorage.getItem("mlanglesToken"));
  let Header = { headers: { "Authorization": `Bearer ${token}` } };

  useEffect(() => {
    let token = JSON.parse(localStorage.getItem("mlanglesToken"));
    let userdetails = JSON.parse(localStorage.getItem("userDetails"));
    setUserDetails(userdetails);

    if (userdetails) {
      if (!token || userDetails?.permissions?.is_serve === 0) {
        navigate("/");
      }
      fetchData();
    }
    setProjectData(state);

  }, [])

  async function fetchData() {
    try {

      let data = {};
      data.project_name = state.project_name;
      data.project_id = Number(state.project_id);
      setOpen(true);
      console.log("data-------", data);
      const res = await axios.post(`${backend_url}/configuration/get_csv`, data, Header);
      setOpen(false);
      if (res.data.success) {
        setFullData(res.data.list);
        let x1 = Object.keys(res.data.list[0]);
        setTableHead(x1);
      }
      else {
        funError(res.data.message);
      }
    }
    catch (e) {
      funError(e.message);
    }
  }


  const funError = (data) => {
    toast.current.show({
      severity: "error",
      summary: "Error",
      detail: data,
      life: 2000,
    });
  };
  return (
    <div>
      <header>
        <div className='d-flex align-items-center justify-content-between' >
          <div className="d-flex align-items-center">
            <div className='logohover'>
              {/* <div className='mllogo'>
                <img src={mllogo} alt="mlangles logo" />
              </div> */}
              <div className="newlogo">
                <img src={mlimage} className='newlogos' alt="" />
              </div>
            </div>
            <h4 className='capitailze'>{userDetails?.user_full_name} workspace / </h4><Link to="/serving"><span> Serving</span></Link><span>/ Batch Serving</span>
          </div>
          <Superuser logoutClicked={() => { setLogout(true) }} />

        </div>
      </header>
      <Dialog className="logout-dialogbox" visible={logout} style={{ width: '40vw' }} onHide={() => setLogout(false)}>
        <Logout no={() => setLogout(false)} />
      </Dialog>

      <Loading loading={open} />
      <Toast ref={toast} />
      <Mlsidebar data={path} />
      <div className="middlepart serving">
        <h2 className='titleFixed'>MODEL SERVING</h2>
       


        <div className="batch-result inside-serving">
          <table>
            {/* <thead>
              <tr>
                {
                  tableHead.map((data, idx) => {
                    if (idx === tableHead.length - 1) {
                      return (
                        <th className='capitalTitle fixedLastOne' key={idx}> {data} </th>
                      )
                    } else {
                      return (
                        <th className='capitalTitle' key={idx}> {data} </th>
                      )
                    }
                  })
                }
              </tr>
            </thead> */}
            <tbody>
              <tr className='sticked'>
                {
                  tableHead.map((data, idx) => {
                    if (idx === tableHead.length - 1) {
                      return (
                        <th className='capitalTitle headback' key={idx}> {data} </th>
                      )
                    } else {
                      return (
                        <th className='capitalTitle' key={idx}> {data} </th>
                      )
                    }
                  })
                }
              </tr>
              {
                fullData.map((data, idx) => {
                  return (
                    <tr key={idx}>
                      {
                        tableHead.map((tdData, idx1) => {
                          let r1 = data[tdData];

                          if (idx1 === tableHead.length - 1) {
                            return (
                              <th className='capitalTitle fixedLastOne bodyback' key={idx1}> {r1} </th>
                            )
                          } else {
                            return (
                              <th className='capitalTitle' key={idx1}> {r1} </th>
                            )
                          }
                        })
                      }
                    </tr>
                  )
                })
              }
            </tbody>
          </table>
        </div>

      </div>
    </div>
  )
}

export default BatchResult;