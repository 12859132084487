import React, { useEffect, useRef, useState } from "react";
import { Toast } from "primereact/toast";
import Mlsidebar from "../Components/Mlsidebar";
import mllogo from "../logo/mlangles360logo.svg";
import profilePic from "../logo/profile-pic.png";
import { Link, useAsyncError, useNavigate, useParams } from "react-router-dom";
import EachPojectBox from "../Components/EachPojectBox";
import mlimage from "../logo/mlangles360logo.svg";
import { Dialog } from "primereact/dialog";
import axios from "axios";
import Loading from "../Components/Loading";
import AddIcon from "@mui/icons-material/Add";
import CloseIcon from "@mui/icons-material/Close";
import { Sidebar } from "primereact/sidebar";
import ViewDetailSideBar from "./ViewDetailSideBar";
import searchIcon from "../logo/searchIcon.png";
import plus from "../logo/plus.png";
import filter from "../logo/filter.png";
import Superuser from "../Components/Superuser";
import { backend_url, backend_url1 } from "../Config";
import Logout from "./Logout";
import chatBot from "../logo/chatBot.png";
import downArrow from "../logo/downArrow.png";
import filtericon from "../logo/filter.png";

const Allprojects = () => {
  const [viewDetailData, setViewDetailData] = useState({});
  const [name, setName] = useState("");
  const [des, setDes] = useState("");
  const [selectedOption, setSelectedOption] = useState("");
  // const [err, setErr] = useState("");
  const [open, setOpen] = useState(true);
  let [projects, setProjects] = useState([]);
  const [initalProject, setInitalProject] = useState([]);
  const [count, setCount] = useState(false);
  const [visibleRight, setVisibleRight] = useState(false);
  const [show, setShow] = useState(false);
  const navigate = useNavigate();
  const [userDetails, setUserDetails] = useState({});
  const [visible, setVisible] = useState(false);
  const [logout, setLogout] = useState(false);
  const [leftDes, setLeftDes] = useState(0);
  const [seachingInp, setSearchingInp] = useState("");
  const [updateData, setUpdateData] = useState({});
  const [shouldupdate, setShouldupdate] = useState(false);
  const [editChange, setEditChange] = useState(false);
  const [proIdUpdate, setProIdUpdate] = useState("");
  const [fullProjects, setFullProjects] = useState([]);
  const [total, setTotal] = useState([]);
  const [current, setCurrent] = useState([]);
  const toast = useRef(null);
  let token = JSON.parse(localStorage.getItem("mlanglesToken"));
  let Header = { headers: { Authorization: `Bearer ${token}` } };

  let path = window?.location?.href?.split("/")[3];

  // function errorFun() {
  //   setTimeout(() => {
  //     setErr("");
  //   }, 4000);
  // }

  console.log("l1-----", total, "--", current);

  async function allprojects() {
    try {
      setOpen(true);
      const allProjects = await axios.get(
        `${backend_url}/mlflow/projects`,
        Header
      );
      console.log("allProjects------", allProjects.data);
      if (allProjects.data.success) {
        setOpen(false);
        let l1 = Object.keys(allProjects.data.data);
        console.log("length-----", l1);
        if (l1.length === 0) {
          setProjects([]);
        } else {
          if (l1.length > 1) {
            setCount(true);
          } else {
            setCount(false);
          }
          setTotal(l1);
          setProjects(allProjects.data.data[l1[0]]);
          setInitalProject(allProjects.data.data[l1[0]]);
          setCurrent([l1[0]]);
          console.log(
            "allProjects.data.data[0]----",
            allProjects.data.data[l1[0]]
          );
          setFullProjects(allProjects.data.data);
        }
        // setFirstpro(allProjects.data.data);
        setShow(true);
      }
    } catch (e) {
      setOpen(false);
      funError(e.message);
    }
  }

  console.log("count-----", count);

  useEffect(() => {
    let token = JSON.parse(localStorage.getItem("mlanglesToken"));
    let userdetails = JSON.parse(localStorage.getItem("userDetails"));
    setUserDetails(userdetails);

    if (userdetails) {
      if (!token || userDetails?.permissions?.projects_admin === 0) {
        navigate("/");
      }
      fetchData();
    }
  }, []);

  async function fetchData() {
    try {
      setOpen(true);
      allprojects();
    } catch (e) {
      console.log("error --", e);
      setOpen(false);
      funError(e.message);
    }
  }

  async function editProjectName(data) {
    //console.log("edit data", data);
    try {
      setOpen(true);
      setUpdateData(data);
      setShouldupdate(false);
      const edit = await axios.put(
        `${backend_url}/mlflow/edit_project/${data.projectId}`,
        { project_name: data.projectName },
        Header
      );
      console.log("edit  name----", edit);
      // window.location.reload(false);

      if (edit.data.success) {
        allprojects();
        setProIdUpdate(data.projectId);
      } else {
        setOpen(false);
        funError(edit.data.message);

        // setTimeout(()=>{
        allprojects();
        // },2000)
      }
    } catch (e) {
      console.log("error", e);
      setOpen(false);
      funError(e.message);
      allprojects();
    }
  }

  async function deletingProject(data) {
    try {
      setOpen(true);
      //console.log("data ----- delete ----", data);
      let ans = { data: { project_id: data } };
      // const res = await axios.delete(`${backend_url}/mlflow/projects`, Header, ans.data);
      const res = await axios.delete(`${backend_url}/mlflow/projects`, {
        headers: { Authorization: `Bearer ${token}` },
        data: { project_id: data },
      });
      //console.log("deleted project is ", res);
      if (res.data.success) {
        allprojects();
      } else {
        funError("Error in Deleting the projects");
      }
    } catch (e) {
      console.log("error", e);
      funError(e.message);
      setOpen(false);
    }
  }

  async function submitForm(e) {
    try {
      e.preventDefault();
      //console.log(name, des);
      setOpen(true);
      if (des.length > 2000) {
        setOpen(false);
        funError();
      } else {
        setSearchingInp("");
        const res = await axios.post(
          `${backend_url}/mlflow/create_new_project`,
          {
            project_name: name,
            project_description: des,
            industry: selectedOption,
          },
          Header
        );
        if (res.data.success) {
          setName("");
          setDes("");
          setSelectedOption("");
          allprojects();
          setVisible(false);
        } else {
          setVisible(false);
          setOpen(false);
          funError("*" + res.data.message);
          setName("");

          console.log("message ----", "*" + res.data.message);
        }
      }
    } catch (err) {
      setOpen(false);
      funError(err.message);
    }
  }

  function searchInput(e) {
    let search = e.target.value.toLowerCase();
    let newArray = [];
    setSearchingInp(e.target.value);
    for (let i = 0; i < initalProject.length; i++) {
      if (initalProject[i].project_name.toLowerCase().includes(search)) {
        newArray.push(initalProject[i]);
      }
    }
    if (search.length === 0) {
      setProjects(initalProject);
    } else {
      setProjects(newArray);
    }
  }

  function viewingtab(data) {
    setVisibleRight(data);
  }

  function closedetails(data) {
    setVisibleRight(false);
  }

  async function dataFromViewBtn(data) {
    try {
      setOpen(true);
      const res = await axios.post(
        `${backend_url}/mlflow/view_details`,
        { project_id: data },
        Header
      );
      if (res.data.success) {
        setViewDetailData(res.data);
      } else {
        funError("Error in view Details");
      }
      setOpen(false);
    } catch (e) {
      setOpen(false);
      funError(e.message);
    }
  }

  function setingDes(data) {
    if (data.length <= 2000) {
      setDes(data);
      setLeftDes(data.length);
    } else {
      let newData = data.slice(0, 2000);
      setDes(newData);
      setLeftDes(newData.length);
    }
  }

  function updating(data) {
    console.log("data------", data);
    setUpdateData(data);
    setShouldupdate(true);
  }

  async function updateEdit() {
    if (shouldupdate) {
      console.log("updating editng  cbj", updateData);
      editProjectName(updateData);
    }
  }

  function parentValue(data) {
    setUpdateData(data);
  }

  function loadMore() {
    let p1 = { ...fullProjects };
    let c1 = [...current];
    let curProject = [...projects];
    let o1 = Object.keys(p1);
    for (let i of o1) {
      console.log("i--", i);
      if (!c1.includes(i)) {
        c1.push(i);
        for (let j of p1[i]) {
          curProject.push(j);
        }
        break;
      }
    }
    console.log("c1---", c1, o1);
    let flag = false;
    for (let i of c1) {
      if (!o1.includes(i)) {
        flag = true;
      }
    }

    if (flag) {
      console.log("true");
      setCount(true);
    } else {
      console.log("false");
      setCount(false);
    }
    setCurrent(c1);
    setProjects(curProject);
    setInitalProject(curProject);
  }

  const funError = (data) => {
    toast.current.show({
      severity: "error",
      summary: "Error",
      detail: data,
      life: 2000,
    });
  };

  return (
    <div className="allProjectspage" onClick={() => updateEdit()}>
      {/* <Link to="/chatbot"><img src={chatBot} alt="" className='chatbotimg' /></Link> */}

      <Loading loading={open} />

      <Toast ref={toast} />
      <header>
        <div className="d-flex align-items-center justify-content-between">
          <div className="d-flex align-items-center">
            <div className="logohover">
              {/* <div className='mllogo'>
                                <img src={mllogo} alt="mlangles logo" />
                            </div> */}
              <div className="newlogo">
                <img src={mlimage} className="newlogos" alt="" />
              </div>
            </div>
            <Link to="/Home">
              <h4 className="capitailze">
                {userDetails?.user_full_name} workspace /
              </h4>
            </Link>
            <span> Projects</span>
          </div>
          <Superuser
            logoutClicked={() => {
              setLogout(true);
            }}
          />
        </div>
      </header>

      <Dialog
        className="logout-dialogbox"
        visible={logout}
        style={{ width: "40vw" }}
        onHide={() => setLogout(false)}
      >
        <Logout no={() => setLogout(false)} />
      </Dialog>

      {visibleRight ? (
        <ViewDetailSideBar
          viewOpen={visibleRight}
          viewClose={closedetails}
          allData={viewDetailData}
        />
      ) : (
        <></>
      )}

      <Mlsidebar data={path} />

      <Dialog
        visible={visible}
        style={{ width: "30vw" }}
        className="popupProject"
        onHide={() => {
          setVisible(false);
          setName("");
          setDes("");
          setLeftDes(0);
        }}
      >
        <form onSubmit={submitForm}>
          <div>
            <div className="text-center dialogAllprojects">
              <input
                autoComplete="off"
                type="text"
                required
                onChange={(e) => setName(e.target.value)}
                value={name}
                placeholder="Enter Project Name"
              />
            </div>
            <div className="text-center dialogAllprojects">
              <select
                className="industry"
                value={selectedOption}
                onChange={(e) => setSelectedOption(e.target.value)}
              >
                <option value="" disabled hidden>
                  Select Industry
                </option>
                <option className="innerindustry" value="Insurance">
                  Insurance
                </option>
                <option className="innerindustry" value="Healthcare">
                  Healthcare
                </option>
                <option className="innerindustry" value="Oil and Gas">
                  Oil and Gas
                </option>
                <option className="innerindustry" value="Retail">
                  Retail
                </option>
                <option className="innerindustry" value="Banking and Finance">
                  Banking and Finance
                </option>
                <option className="innerindustry" value="Manufacturing">
                  Manufacturing
                </option>
                <option className="innerindustry" value="Tourism">
                  Tourism
                </option>
                <option className="innerindustry" value="Real Estate">
                  Real Estate
                </option>
                <option className="innerindustry" value="Hospitality">
                  Hospitality
                </option>
              </select>
              <img src={downArrow} alt="" className="darrow" />
            </div>
            <div className="text-center dialogAllprojects">
              <textarea
                rows="10"
                cols="40"
                type="text"
                onChange={(e) => {
                  setingDes(e.target.value);
                }}
                value={des}
                placeholder="Description"
              ></textarea>
            </div>
            <div className="leftnumber dialogAllprojects">
              <p className="white ">Character Left - {leftDes} / 2000 </p>
            </div>
            <div></div>
            <div className="text-center dialogAllprojects">
              <button type="submit">Create Project</button>
            </div>
          </div>
        </form>
      </Dialog>

      <section className="middlepart allprojects dashboard">
        <div className="each-pipehead">
          <div className="d-flex justify-content-between mar-right25 pad12">
            <div className="d-flex align-items-center">
              <h6 className="head green mypro">MY PROJECTS</h6>
              <Link to="/assignedProjects">
                <h6 className="head assignpro">ASSIGNED PROJECTS</h6>
              </Link>
            </div>
            <div className="d-flex">
              <div className="posrel">
                <input
                  autoComplete="off"
                  type="text"
                  value={seachingInp}
                  placeholder="Search Project"
                  className="searchinput"
                  onChange={(e) => searchInput(e)}
                />
                <img src={searchIcon} alt="" className="searchIcon" />
              </div>
              <button
                className="newprojectbtn marRight64"
                onClick={() => setVisible(true)}
              >
                <img src={plus} alt="plus" className="plus" /> New Project
              </button>
            </div>
          </div>
        </div>

        <div className="padTop40">
          {/* {err ? (
            <div className="d-flex align-items-center justify-content-center">
              <h4 className="error-message">{err}</h4>
             
            </div>
          ) : (
            <></>
          )} */}

          <>
            {show ? (
              <>
                {projects?.length !== 0 ? (
                  <div className="allprojts">
                    <div className="filteralign">
                      <h6 className="head green mypro">ALL PROJECTS</h6>
                      {/* <img
                        src={filter}
                        className="fa-solid fa-filter dropdown-toggle img-responsive filter"
                        alt="filter"
                      /> */}
                    </div>
                    <div className="d-flex flex-wrap w100 pad24">
                      {projects?.map((data, idx) => {
                        return (
                          <EachPojectBox
                            key={idx}
                            proId={proIdUpdate}
                            updateParent={parentValue}
                            initialEdit={editChange}
                            editing={updating}
                            value={data}
                            index={idx}
                            viewData={dataFromViewBtn}
                            viewdetails={viewingtab}
                            forDelete={deletingProject}
                            forRename={editProjectName}
                          />
                        );
                      })}
                    </div>

                    {count && (
                      <div className="d-flex justify-content-center mt-4">
                        <button onClick={() => loadMore()} className="loadmore">
                          Load More
                        </button>
                      </div>
                    )}
                  </div>
                ) : (
                  <div className="noprojects">
                    <div className="text-center">
                      <h4>No projects created</h4>
                      <button onClick={() => setVisible(true)}>
                        Create Project
                      </button>
                    </div>
                  </div>
                )}
              </>
            ) : (
              <></>
            )}
          </>
        </div>
      </section>
    </div>
  );
};

export default Allprojects;
