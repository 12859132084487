import React, { useEffect, useState } from 'react';
import rightArrow from "../../logo/rightArrow.png";
import { FormControl, InputLabel, MenuItem, Select } from '@mui/material';
import S3input from './S3input';
import axios from 'axios';
import { backend_url } from '../../Config';
import Loading from '../../Components/Loading';


const BatchInput = ({ current, data, validate }) => {

    const [sql, setSql] = useState({});
    const [postgres, setPostgres] = useState({});

    const [database, setDatabase] = useState("");
    const [shows3, setShows3] = useState(false);
    const [insidebase, setInsidebase] = useState("");
    const [insides3, setInsides3] = useState(false);
    const [open, setOpen] = useState(false);

    let token = JSON.parse(localStorage.getItem("mlanglesToken"));
    let Header = { headers: { "Authorization": `Bearer ${token}` } };

    useEffect(() => {
        console.log("current---", current);
        let d1 = current.split("-");
        if (d1[1]) {
            setDatabase(d1[1]);
            setInsidebase("");
        }
        else if (current === "url") {
            setDatabase("");
        }
        else {
            clear();
            setDatabase("");
        }
    }, [current, validate]);

    console.log(sql, postgres, "fghjgfgiujh");

    useEffect(() => {
        setShows3(false);
        if (Object.keys(sql).length > 0) {
            setSql({});
        } else if (Object.keys(postgres).length > 0) {
            setPostgres({});
        }
    }, [current]);

    function clear() {
        setSql({});
        setPostgres({});
        setShows3(false);
        setInsides3(false);
        setInsidebase("");
    }

    function datavaluesqlChange(e) {
        let name = e.target.name;
        let value = e.target.value;
        setSql({ ...sql, [name]: value });
    }

    function datavaluePostgresChange(e) {
        let name = e.target.name;
        let value = e.target.value;
        setPostgres({ ...postgres, [name]: value });
    }

    function fieldSubmit(e) {
        e.preventDefault();

        if (Object.keys(sql).length !== 0) {
            data(sql, e.target.name);
        } else if (Object.keys(postgres).length !== 0) {
            data(postgres, e.target.name);
        }
    }

    function nextBtn(e) {
        e.preventDefault();
        console.log("new     ---", sql, postgres);
        // console.log("next btn", oldsql, oldpostgres)
        if (Object.keys(sql).length !== 0) {
            data(sql, e.target.name, true);
        } else if (Object.keys(postgres).length !== 0) {
            data(postgres, e.target.name, true);
        }
    }

    function fromS3(d1, go) {
        console.log("d1-------", d1);
        if (go) {
            if (Object.keys(d1).length !== 0) {
                if (!insides3) {
                    data(d1, "s3", true);
                } else {
                    data(d1, "url", true);
                }
            }
        } else {
            if (Object.keys(d1).length !== 0) {
                if (!insides3) {
                    data(d1, "s3");
                } else {
                    data(d1, "url");
                }
            }
        }
    }

    console.log("----", sql, postgres);

    return (
        <div>
            <Loading loading={open} />

            {
                current === "url" ?
                    <div className="selectDatabase mar0">
                        <p>Location to store the predictions data?</p>
                        <div className="d-flex gap14">
                            <div className='connects3element w24' onClick={() => { setInsides3(true); setShows3(true); setInsidebase('') }} >
                                <h4>Connect to your S3</h4>
                                <div className='width18px'>
                                    <img src={rightArrow} className='img-fluid' alt="right Arrow" />
                                </div>
                            </div>
                            <div className='serpro-eachinput'>
                                <FormControl fullWidth>
                                    <InputLabel id="demo-simple-select-label">Connect to Databse</InputLabel>
                                    <Select
                                        labelId="demo-simple-select-label"
                                        id="demo-simple-select"
                                        className='demo-simple-select'
                                        value={insidebase}
                                        label="Problem Type"
                                        onChange={(e) => { setShows3(false); setInsidebase(e.target.value); }}
                                    >
                                        <MenuItem value="sql">MySql</MenuItem>
                                        <MenuItem value="postgres">Postgres</MenuItem>
                                    </Select>
                                </FormControl>
                            </div>
                        </div>
                    </div>
                    :
                    <></>
            }

            {
                shows3 || current === "s3" ?
                    <S3input show={!insides3} mainData={fromS3} validate={validate} />
                    :
                    <></>
            }

            {
                database === "sql" || insidebase === "sql" ?
                    <div className="serpro-bottom">
                        <h4>MySQL</h4>
                        <form name='mysql_db' onSubmit={fieldSubmit}>
                            <div className='serveprobottombox'>
                                <input autoComplete="off" type="text" disabled={validate?.mysql_db} required placeholder='Host Name' name="mysql_hostname" onChange={datavaluesqlChange} />
                                <input autoComplete="off" type="text" disabled={validate?.mysql_db} required placeholder='User Name' name='mysql_username' onChange={datavaluesqlChange} />
                                <input autoComplete="off" type="password" disabled={validate?.mysql_db} required placeholder='Password' name='mysql_password' onChange={datavaluesqlChange} />
                                <input autoComplete="off" type="text" disabled={validate?.mysql_db} required placeholder='Database Name' name='mysql_databasename' onChange={datavaluesqlChange} />
                                <input autoComplete="off" type="text" disabled={validate?.mysql_db} required placeholder='Table Name' name='mysql_database_tablename' onChange={datavaluesqlChange} />
                            </div>

                            {
                                validate?.mysql_db ? <button name='mysql_db' onClick={nextBtn} className='nextbtn'>Next</button> : <button type='submit' className='nextbtn'>Validate</button>
                            }

                        </form>
                    </div>
                    :
                    <></>
            }

            {
                database === "postgres" || insidebase === "postgres"
                    ?
                    <div className="serpro-bottom">
                        <h4>Postgres</h4>
                        <form name='postgres_db' onSubmit={fieldSubmit}>
                            <div className='serveprobottombox'>
                                <input autoComplete="off" type="text" disabled={validate?.postgres_db} required placeholder='Host Name' name="postgres_db_hostname" onChange={datavaluePostgresChange} />
                                <input autoComplete="off" type="text" disabled={validate?.postgres_db} required placeholder='User Name' name='postgres_db_username' onChange={datavaluePostgresChange} />
                                <input autoComplete="off" type="password" disabled={validate?.postgres_db} required placeholder='Password' name='postgres_db_password' onChange={datavaluePostgresChange} />
                                <input autoComplete="off" type="text" disabled={validate?.postgres_db} required placeholder='Database Name' name='postgres_db_databasename' onChange={datavaluePostgresChange} />
                                <input autoComplete="off" type="text" disabled={validate?.postgres_db} required placeholder='Table Name' name='postgres_db_tablename' onChange={datavaluePostgresChange} />
                            </div>

                            {
                                validate?.postgres_db ? <button name='postgres_db' onClick={nextBtn} className='nextbtn'>Next</button> : <button type='submit' className='nextbtn'>Validate</button>
                            }
                        </form>
                    </div>
                    :
                    <></>
            }
        </div >
    )
}

export default BatchInput