import React from 'react';

const InputCheckbox = ({ ele, idx, changingCheckbox, fulldata, dis }) => {
    function passs() {

    }

    return (
        <div className="each-algoinp" key={idx} onClick={() => { dis ? passs() : changingCheckbox(ele) }} >
            <div className={`boxCheck ${fulldata[ele]?.isTrue ? " active" : " nonactive"} `}>
            </div>

            {/* <input
                id={ele}
                value={ele}
                type="checkbox"
                className={`checkbox ${fulldata[ele]?.isTrue ? "active" : "nonactive"}  `}
                name="checkboxing"
            // onChange={(e) => { operation(); }}
            // defaultChecked={problemtype === "classification" ? classiCheck.includes(ele) : regCheck.includes(ele)}
            // checked={fulldata[ele]?.isTrue}
            /> */}

            <label>{ele}</label>
        </div >
    )
}

export default InputCheckbox;