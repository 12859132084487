import React, { useEffect, useRef, useState } from "react";
import { Toast } from "primereact/toast";
import Loading from "../Components/Loading";
import Superuser from "../Components/Superuser";
import mllogo from "../logo/mlangles360logo.svg";
import mlimage from "../logo/mlangles360logo.svg";
import profilePic from "../logo/profile-pic.png";
import { useNavigate } from "react-router";
import Mlsidebar from "../Components/Mlsidebar";
import axios from "axios";
import { backend_url } from "../Config";
import Logout from "./Logout";
import { Dialog } from "primereact/dialog";

const Settings = () => {
  const [open, setOpen] = useState(false);
  const [logout, setLogout] = useState(false);
  const navigate = useNavigate();
  const [userDetails, setUserDetails] = useState({});
  const [config, setConfig] = useState({});
  const [displayDetails, setdisplayDetails] = useState({});
  const path = "settings";
  const toast = useRef(null);

  let token = JSON.parse(localStorage.getItem("mlanglesToken"));
  let Header = { headers: { Authorization: `Bearer ${token}` } };

  useEffect(() => {
    let token = JSON.parse(localStorage.getItem("mlanglesToken"));
    let userdetails = JSON.parse(localStorage.getItem("userDetails"));
    setUserDetails(userdetails);
    if (!userdetails) {
      if (
        !token ||
        userdetails?.permissions?.user_management_roles_admin === 0
      ) {
        navigate("/");
      }
    }
    fetchData();
  }, []);

  async function fetchData() {
    try {
      setOpen(true);
      const getConfig = await axios.get(
        `${backend_url}/mlflow/github_configuration`,
        Header
      );
      setOpen(false);
      if (getConfig.data.success) {
        let n1 = getConfig.data;
        console.log("n1------", n1);
        setdisplayDetails(n1.data);
      }
    } catch (e) {
      console.log("There is an Error---", e);
      funError(e.message);
      setOpen(false);
    }
  }

  async function submitSetting(e) {
    try {
      e.preventDefault();
      setOpen(true);
      console.log("displayDetaillsssssss", displayDetails);
      const res = await axios.post(
        `${backend_url}/mlflow/github_configuration`,
        {
          email: displayDetails.email,
          token: displayDetails.token,
        },
        Header
      );
      setOpen(false);
      if (!res.data.success) {
        funError("Error in updating data");
      }
    } catch (e) {
      funError(e.message);
      setOpen(false);
    }
    console.log("displayDetails-------------", displayDetails);
  }

  function settingChange(e) {
    setdisplayDetails({ ...displayDetails, [e.target.name]: e.target.value });
  }
  function settingChange(e) {
    const { name, value } = e.target;
    setdisplayDetails((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  }

  const funError = (data) => {
    toast.current.show({
      severity: "error",
      summary: "Error",
      detail: data,
      life: 2000,
    });
  };

  return (
    <div>
      <Loading loading={open} />
      <Toast ref={toast} />
      <header>
        <div className="d-flex align-items-center justify-content-between">
          <div className="d-flex align-items-center">
            <div className="logohover">
              <div className="newlogo">
                <img src={mlimage} className="newlogos" alt="" />
              </div>
            </div>
            <h4 className="capitailze">
              {userDetails?.user_full_name} workspace /{" "}
            </h4>
            <span> Profile Settings</span>
          </div>
          <Superuser
            logoutClicked={() => {
              setLogout(true);
            }}
          />
        </div>
      </header>

      <Dialog
        className="logout-dialogbox"
        visible={logout}
        style={{ width: "40vw" }}
        onHide={() => setLogout(false)}
      >
        <Logout no={() => setLogout(false)} />
      </Dialog>

      <Mlsidebar data={path} />

      <div className="middlepart">
        <h4 className="page-title green fixedsubttab">SETTINGS</h4>
        <form className="padTop60" onSubmit={submitSetting}>
          <div className="setting">
            <h4>Git Configuration</h4>
            <div className="d-flex align-items-center gap30 padRight">
              <div className="eachvalue">
                <h6>Email</h6>
                <input
                  autoComplete="off"
                  type="text"
                  name="email"
                  id=""
                  onChange={settingChange}
                  value={displayDetails?.github_username}
                  required
                  placeholder="Email"
                />
              </div>
              <div className="eachvalue">
                <h6>Token</h6>
                <input
                  autoComplete="off"
                  type="text"
                  name="token"
                  id=""
                  onChange={settingChange}
                  value={displayDetails?.github_token}
                  required
                  placeholder="Token"
                />
              </div>
            </div>
            <button type="submit" className="commonbtn savebtn">
              Save
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default Settings;
