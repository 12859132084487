import React, { useEffect, useState } from 'react'
import Logout from '../Logout';
import { Dialog } from 'primereact/dialog';
import Mlsidebar from '../../Components/Mlsidebar';
import Loading from '../../Components/Loading';
import mllogo from "../../logo/mlangles360logo.svg";
import mlimage from "../../logo/mlangles360logo.svg";
import onlineServe from "../../logo/onlineServe.svg";
import onlineServeActive from "../../logo/onlineServeActive.svg";
import batchServe from "../../logo/batchServe.svg";
import batchServeActive from "../../logo/batchServeActive.svg";
import { useNavigate } from 'react-router';
import Superuser from '../../Components/Superuser';
import { Link } from 'react-router-dom';

const SelectServing = () => {
    let path = "serving";
    let navigate = useNavigate();

    const [userDetails, setUserDetails] = useState({});
    const [logout, setLogout] = useState(false);
    const [err, setErr] = useState("");
    const [open, setOpen] = useState(false);

    useEffect(() => {
        let token = JSON.parse(localStorage.getItem("mlanglesToken"));
        let userdetails = JSON.parse(localStorage.getItem("userDetails"));
        setUserDetails(userdetails);
        if (userdetails) {
            if (!token || userDetails?.permissions?.is_serve === 0) {
                navigate("/");
            }
        }
    }, [])

    return (
        <div>
            <header>
                <div className='d-flex align-items-center justify-content-between' >
                    <div className="d-flex align-items-center">
                        <div className='logohover'>
                            {/* <div className='mllogo'>
                                <img src={mllogo} alt="mlangles logo" />
                            </div> */}
                            <div className="newlogo">
                                <img src={mlimage} className='newlogos' alt="" />
                            </div>
                        </div>
                        <h4 className='capitailze'>{userDetails?.user_full_name} workspace / </h4><span> Serving</span>
                    </div>
                    <Superuser logoutClicked={() => { setLogout(true) }} />

                </div>
            </header>
            <Dialog className="logout-dialogbox" visible={logout} style={{ width: '40vw' }} onHide={() => setLogout(false)}>
                <Logout no={() => setLogout(false)} />
            </Dialog>

            <Loading loading={open} />

            <Mlsidebar data={path} />
            <div className="middlepart serving">
                <h2 className='titleFixed'>SERVING</h2>
                <div className='d-flex align-items-center justify-content-center'>
                    <h4 className='error-message'>{err}</h4>
                </div>
                <div className="inside-serving1 inside-serving">
                    {/* <h6>Select Serving</h6> */}

                    <div className="selectingServing">
                        <div className='d-flex gap30'>
                            <Link to="/online_serving" >
                                <div className='selectSeveBox online'>
                                    <div className='sameIcon'>
                                        <img src={onlineServe} alt="onlineServe" className='onlineServe' />
                                        <img src={onlineServeActive} alt="onlineServeActive" className='onlineServeActive' />
                                    </div>
                                    <div className="name-ser">
                                        <p>Online</p>
                                        <p>Serving</p>
                                    </div>
                                </div>
                            </Link>
                            <Link to="/batch_serving" >
                                <div className='selectSeveBox batch'>
                                    <div className='sameIcon'>
                                        <img src={batchServe} alt="batchServe" className='batchServe' />
                                        <img src={batchServeActive} alt="batchServeActive" className='batchServeActive' />
                                    </div>
                                    <div className="name-ser">
                                        <p>Batch</p>
                                        <p>Serving</p>
                                    </div>
                                </div>
                            </Link>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default SelectServing;