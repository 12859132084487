import React, { useEffect, useRef, useState } from "react";
import { Toast } from "primereact/toast";

import Mlsidebar from "../../Components/Mlsidebar";
import mllogo from "../../logo/mlangles360logo.svg";
import mlimage from "../../logo/mlangles360logo.svg";
import { Link, useLocation, useNavigate, useParams } from "react-router-dom";
import axios from "axios";
import { FormControl, InputLabel, MenuItem, Select } from "@mui/material";
import Loading from "../../Components/Loading";
import Superuser from "../../Components/Superuser";
import Logout from "../Logout";
import { Dialog } from "primereact/dialog";
import { backend_url } from "../../Config";
import { Dropdown } from "primereact/dropdown";

const BatchCreate = () => {
  const [selectedCity, setSelectedCity] = useState(null);
  const cities = [
    { name: "New York", code: "NY" },
    { name: "Rome", code: "RM" },
    { name: "London", code: "LDN" },
    { name: "Istanbul", code: "IST" },
    { name: "Paris", code: "PRS" },
  ];

  let passedData = useLocation();
  let path = "serving";
  const [open, setOpen] = useState(false);
  const navigate = useNavigate();
  const [userDetails, setUserDetails] = useState({});
  const [logout, setLogout] = useState(false);
  const [oldData, setOlddata] = useState(null);
  const [newJob, setNewJob] = useState({
    job_name: "",
    project: "",
    experiment: "",
    model: "",
    instance_type: "",
  });
  const [fieldErr, setFieldErr] = useState({
    job_name: "",
    project: "",
    experiment: "",
    model: "",
    instance_type: "",
  });
  const [projectName, setProjectName] = useState([]);
  const [project_id, setProject_id] = useState("");
  const [project_name, setProject_name] = useState("");
  const [postserv, setPostserv] = useState([]);
  const [modelName, setModalName] = useState([]);
  const [expName, setExpName] = useState([]);
  const [fullModaldata, setFullModaldata] = useState([]);
  const [urls, setUrls] = useState("");
  const [connectionData, setConnectionData] = useState([
    "*",
    "*",
    "*",
    "*",
    "*",
  ]);
  const instance = [
    "c6a.8xlarge",
    "g5.8xlarge",
    "g3.8xlarge",
    "g5.12xlarge",
    "p4d.24xlarge",
  ];
  const [minArray, setMinArray] = useState([1]);
  const [hoursArray, setHoursArray] = useState([1]);
  const [dayArray, setDayArray] = useState([1]);
  const [monthsArray, setMonthsArray] = useState([]);
  const [weekArray, setWeekArray] = useState([]);
  const [onemin, setOnemin] = useState({ name: "0", code: "0" });
  const [onehour, setOnehour] = useState({ name: "0", code: "0" });
  const [oneday, setOneday] = useState({ name: "1", code: "1" });
  const [onemonth, setOnemonth] = useState({ name: "1", code: "1" });
  const [oneweek, setOneweek] = useState({ name: "0", code: "0" });
  const toast = useRef(null);

  let token = JSON.parse(localStorage.getItem("mlanglesToken"));
  let Header = { headers: { Authorization: `Bearer ${token}` } };

  useEffect(() => {
    let token = JSON.parse(localStorage.getItem("mlanglesToken"));
    let userdetails = JSON.parse(localStorage.getItem("userDetails"));
    setUserDetails(userdetails);
    let { state } = passedData;
    console.log("state-----", state);
    setOlddata(state);
    // console.log("this.props.state-----", this.props.state);
    if (userdetails) {
      if (!token || userDetails?.permissions?.is_serve === 0) {
        navigate("/");
      }
    }
    getMins();
    getDays();
    getHours();
    getMonths();
    getweeks();
    getData();
  }, []);

  function getMins() {
    let min = [];
    for (let i = 0; i < 60; i++) {
      // min.push(i);
      min.push({ name: i.toString(), code: i.toString() });
    }
    setMinArray(min);
  }

  function getHours() {
    let hr = [];
    for (let i = 0; i < 24; i++) {
      // hr.push(i);
      hr.push({ name: i.toString(), code: i.toString() });
    }
    setHoursArray(hr);
  }
  function getDays() {
    let day = [];
    for (let i = 1; i <= 31; i++) {
      // day.push(i);
      day.push({ name: i.toString(), code: i.toString() });
    }
    setDayArray(day);
  }

  function getMonths() {
    let months = [];
    for (let i = 1; i <= 12; i++) {
      // day.push(i);
      months.push({ name: i.toString(), code: i.toString() });
    }
    setMonthsArray(months);
  }

  function getweeks() {
    let weeks = [];
    for (let i = 0; i <= 6; i++) {
      // day.push(i);
      weeks.push({ name: i.toString(), code: i.toString() });
    }
    setWeekArray(weeks);
  }

  function errorHide() {
    window.scrollTo(0, 0);
    setTimeout(() => {
      setFieldErr({
        job_name: "",
        project: "",
        experiment: "",
        model: "",
        instance_type: "",
      });
    }, 5000);
  }

  async function getData() {
    try {
      setOpen(true);
      const res = await axios.get(`${backend_url}/tracking/serving`, Header);
      setOpen(false);
      if (res.data.success) {
        setProjectName(res.data.data);
      }
    } catch (e) {
      console.log("e---", e);
      setOpen(false);
      funError(e.message);
    }
  }

  async function jobChange(e) {
    try {
      let name = e.target.name;
      let value = e.target.value;
      setNewJob({ ...newJob, [name]: value });
      if (name === "project") {
        {
          setProject_name(value);
          let project_name = e.target.value;
          let id = "";
          for (let i of projectName) {
            if (i.project_name === project_name) {
              id = i.project_id;
              setProject_id(id);
              break;
            }
          }
          setOpen(true);
          try {
            const res = await axios.post(
              `${backend_url}/tracking/serving`,
              { project_id: id },
              Header
            );
            if (res.data.success) {
              setOpen(false);
              let data = res.data.data;
              setPostserv(data);
              let arrayData = [];
              for (let i of data) {
                arrayData.push(i.run_name);
              }
              setExpName(arrayData);
              setModalName([]);
              setNewJob({
                ...newJob,
                project: value,
                experiment: "",
                model: "",
              });
            } else {
              setOpen(false);
              setExpName([]);
              setModalName([]);
              setNewJob({
                ...newJob,
                project: value,
                experiment: "",
                model: "",
              });
            }
          } catch (e) {
            console.log("error---", e);
            setOpen(false);
          }
        }
      } else if (name === "experiment") {
        let expName = e.target.value;
        let run_id = "";
        for (let i of postserv) {
          if (i.run_name === expName) {
            run_id = i.run_id;
            break;
          }
        }
        setOpen(true);
        try {
          const res = await axios.post(
            `${backend_url}/tracking/get_models_`,
            { project_id: project_id, run_id: run_id, run_name: expName },
            Header
          );
          if (res.data.success) {
            setOpen(false);
            let ans = res.data.list_of_algo_names;
            setFullModaldata(res.data.urls);
            let newArray = [];
            for (let i of ans) {
              let newStr = i.replace("(", "").replace(")", "");
              //console.log(i, typeof i, newStr);
              newArray.push(newStr);
            }
            setModalName(newArray);
          } else {
            setModalName([]);
          }
        } catch (e) {
          console.log("error---", e);
          setOpen(false);
        }
      } else {
        let modal = e.target.value;
        // let modal = e.target.value + "()";
        for (let i of fullModaldata) {
          console.log("modal data----", i, "-------", modal);
          if (modal === i.algorithem) {
            let urls = i.urls[0];
            // console.log("urls-----", i.urls[0]);
            setUrls(urls);
            setOpen(true);
            try {
              const res = await axios.post(
                `${backend_url}/tracking/getColoumns`,
                { project_id: project_id, model_url: urls, algorithem: urls },
                Header
              );
              console.log("res-------", res);
              if (res.data.success) {
                setOpen(false);
                let colObj = {};
                for (let i of res.data.coloums) {
                  colObj[i] = 0;
                }
              } else {
                setOpen(false);
              }
              break;
            } catch (e) {
              console.log("error---", e);
              setOpen(false);
            }
          }
        }
      }
    } catch (e) {
      console.log("e---", e);
      setOpen(false);
      funError(e.message);
    }
  }
  // function errFunction() {
  //     window.scrollTo(0, 0);
  //     setTimeout(() => {
  //         setErr("");
  //     }, 4000);
  // }

  async function createBatch(e) {
    try {
      let flag = false;
      let e1 = fieldErr;
      if (!newJob.job_name) {
        e1 = { ...e1, job_name: "Enter Job Name" };
        flag = true;
      }
      if (!newJob.project) {
        e1 = { ...e1, project: "Select Project Name" };
        flag = true;
      }
      if (!newJob.experiment) {
        e1 = { ...e1, experiment: "Select Experiment" };
        flag = true;
      }
      if (!newJob.model) {
        e1 = { ...e1, model: "Select Model" };
        flag = true;
      }
      if (!newJob.instance_type) {
        e1 = { ...e1, instance_type: "Select Instance Type" };
        flag = true;
      }

      console.log("e1-----", e1);
      setFieldErr(e1);

      if (flag) {
        // funError();
        errorHide();
      } else {
        let fulldata = {
          connection_time_data: connectionData,
          instance_type: newJob.instance_type,
          job_name: newJob.job_name,
          project_name: project_name,
          project_id: project_id,
          ...oldData,
          url: urls,
        };

        console.log("fulldata--------", fulldata);

        setOpen(true);
        try {
          const res = await axios.post(
            `${backend_url}/configuration/create_dag`,
            fulldata,
            Header
          );
          setOpen(false);
          if (res.data.success) {
            navigate("/batch_serving");
          } else {
            funError(res.data.message);
          }
        } catch (e) {
          console.log("error---", e);
          setOpen(false);
        }
      }
    } catch (e) {
      console.log("error---", e);
      setOpen(false);
      funError(e.mesage);
    }
  }

  let mins = [
    "Every 15 Minutes#1/15",
    "Every 30 Minutes#1/30",
    "Every 45 Minutes#1/45",
    "custom",
    "none#*",
  ];
  let hours = [
    "Every Hours#*",
    "Even Hours#2/2",
    "Odd Hours#1/2",
    "Every 6 Hours#1/6",
    "Every 12 Hours#1/12",
    "custom",
    "none#*",
  ];
  let days = [
    "Every Days#*",
    "Even Days#2-31/2",
    "Odd Days#1/2",
    "Every 5 Days#1/5",
    "Every 10 Days#1/10",
    "Every Half Month#1/15",
    "custom",
    "none#*",
  ];
  let months = [
    "Every Month#*",
    "Even Month#2/2",
    "Odd Month#1/2",
    "Every 4 Month#1/4",
    "Every Half Year#1/6",
    "custom",
    "none#*",
  ];
  let weeks = [
    "Every day#*",
    "Monday - Friday#1-5",
    "Saturday - Sunday#6-7",
    "custom",
    "none#*",
  ];

  console.log("connectionData------>", connectionData);

  function times(e, data, custom) {
    let newData = JSON.parse(JSON.stringify(connectionData));
    let value = e.target.value.toString();

    console.log("newData-----", newData);

    if (data === "minutes") {
      if (custom) {
        newData[0] = onemin.code.toString();
        setConnectionData(newData);
      } else {
        newData[0] = value;
        setConnectionData(newData);
      }
    } else if (data === "hours") {
      if (custom) {
        newData[1] = onehour.code.toString();
        setConnectionData(newData);
      } else {
        newData[1] = value;
        setConnectionData(newData);
      }
    } else if (data === "days") {
      if (custom) {
        newData[2] = oneday.code.toString();
        setConnectionData(newData);
      } else {
        newData[2] = value;
        setConnectionData(newData);
      }
    } else if (data === "months") {
      if (custom) {
        newData[3] = onemonth.code.toString();
        setConnectionData(newData);
      } else {
        newData[3] = value;
        setConnectionData(newData);
      }
    } else if (data === "weeks") {
      if (custom) {
        newData[4] = oneweek.code.toString();
        setConnectionData(newData);
      } else {
        newData[4] = value;
        setConnectionData(newData);
      }
    }
  }

  function selectRadio(e) {
    let newData = connectionData;
    let name = e.target.name;
    let value = e.target.value.code;

    console.log("value---", value, "newdata---", newData);
    console.log("all data------");

    let mins = document.querySelectorAll('[minutes="minutes"]')[0];
    let hrs = document.querySelectorAll('[hours="hours"]')[0];
    let days = document.querySelectorAll('[days="days"]')[0];
    let months = document.querySelectorAll('[months="months"]')[0];
    let weeks = document.querySelectorAll('[weeks="weeks"]')[0];

    if (mins.checked && name === "minutes") {
      newData[0] = value.toString();
      setConnectionData(newData);
    }
    if (hrs.checked && name === "hours") {
      newData[1] = value.toString();
      setConnectionData(newData);
    }
    if (days.checked && name === "days") {
      newData[2] = value.toString();
      setConnectionData(newData);
    }
    if (months.checked && name === "months") {
      newData[3] = value.toString();
      setConnectionData(newData);
    }
    if (weeks.checked && name === "weeks") {
      newData[4] = value.toString();
      setConnectionData(newData);
    }
  }
  const funError = (data) => {
    toast.current.show({
      severity: "error",
      summary: "Error",
      detail: data,
      life: 2000,
    });
  };
  return (
    <div>
      <header>
        <div className="d-flex align-items-center justify-content-between">
          <div className="d-flex align-items-center">
            <div className="logohover">
              {/* <div className="mllogo">
                                <img src={mllogo} alt="mlangles logo" />
                            </div> */}
              <div className="newlogo">
                <img src={mlimage} className="newlogos" alt="" />
              </div>
            </div>
            <h4 className="capitailze">
              {userDetails?.user_full_name} workspace /{" "}
            </h4>
            <Link to="/serving">
              <span> Serving</span>
            </Link>
            <span>/ Batch Serving</span>
          </div>
          <Superuser
            logoutClicked={() => {
              setLogout(true);
            }}
          />
        </div>
      </header>
      <Dialog
        className="logout-dialogbox"
        visible={logout}
        style={{ width: "40vw" }}
        onHide={() => setLogout(false)}
      >
        <Logout no={() => setLogout(false)} />
      </Dialog>

      <Loading loading={open} />
      <Toast ref={toast} />

      <Mlsidebar data={path} />
      <div className="middlepart serving">
        <h2 className="titleFixed">BATCH PREDICTIONS</h2>

        <div className="serve-inside">
          <div className="serpro-bottom1 padbot0px">
            <div>
              <h4>New Job</h4>
              <div className="serveprobottombox">
                <div className="w23percent">
                  <input
                    autoComplete="off"
                    type="text"
                    placeholder="Job Name"
                    onChange={jobChange}
                    name="job_name"
                    value={newJob.job_name}
                    required
                    className="blueblack w20per"
                  />
                  <p className="fieldError">{fieldErr.job_name}</p>
                </div>
                <div className="w23percent">
                  <FormControl className="w20per">
                    <InputLabel id="demo-simple-select-label">
                      Select Project
                    </InputLabel>
                    <Select
                      labelId="demo-simple-select-label"
                      id="demo-simple-select"
                      className="demo-simple-select"
                      value={newJob.project}
                      name="project"
                      label="Problem Type"
                      onChange={jobChange}
                      required
                    >
                      {projectName.map((data, idx) => {
                        return (
                          <MenuItem key={idx} value={data.project_name}>
                            {data.project_name}
                          </MenuItem>
                        );
                      })}
                    </Select>
                  </FormControl>
                  <p className="fieldError">{fieldErr.project}</p>
                </div>
                <div className="w23percent">
                  <FormControl className="w20per">
                    <InputLabel id="demo-simple-select-label">
                      Select Experiment
                    </InputLabel>
                    <Select
                      labelId="demo-simple-select-label"
                      id="demo-simple-select"
                      className="demo-simple-select"
                      value={newJob.experiment}
                      name="experiment"
                      label="Problem Type"
                      onChange={jobChange}
                      required
                    >
                      {expName.map((data, idx) => {
                        return (
                          <MenuItem key={idx} value={data}>
                            {data}
                          </MenuItem>
                        );
                      })}
                    </Select>
                  </FormControl>
                  <p className="fieldError">{fieldErr.experiment}</p>
                </div>

                <div className="w23percent">
                  <FormControl className="w20per">
                    <InputLabel id="demo-simple-select-label">
                      Select Model
                    </InputLabel>
                    <Select
                      labelId="demo-simple-select-label"
                      id="demo-simple-select"
                      className="demo-simple-select"
                      value={newJob.model}
                      name="model"
                      label="Problem Type"
                      onChange={jobChange}
                      required
                    >
                      {modelName.map((data, idx) => {
                        return (
                          <MenuItem key={idx} value={data}>
                            {data}
                          </MenuItem>
                        );
                      })}
                    </Select>
                  </FormControl>
                  <p className="fieldError">{fieldErr.model}</p>
                </div>
                <div className="w23percent">
                  <FormControl className="w20per">
                    <InputLabel id="demo-simple-select-label">
                      Select Instance
                    </InputLabel>
                    <Select
                      labelId="demo-simple-select-label"
                      id="demo-simple-select"
                      className="demo-simple-select"
                      value={newJob.instance_type}
                      name="instance_type"
                      label="Select Instance"
                      onChange={jobChange}
                      required
                    >
                      {instance.map((data, idx) => {
                        return (
                          <MenuItem key={idx} value={data}>
                            {data}
                          </MenuItem>
                        );
                      })}
                    </Select>
                  </FormControl>
                  <p className="fieldError">{fieldErr.instance_type}</p>
                </div>
              </div>
            </div>
          </div>
          <div className="serpro-bottom1">
            <div>
              <h4>Schedule Time</h4>
              <div className="serveprobottombox">
                <div className="scheduleEachBox">
                  <h3>Minutes</h3>
                  <div className="d-flex justifyBetween">
                    <div className="scheduleCheckbox">
                      {mins.map((data, idx) => {
                        if (data === "custom") {
                          return (
                            <div className="d-flex settingselect" key={idx}>
                              <input
                                autoComplete="off"
                                type="radio"
                                minutes="minutes"
                                name="minutes"
                                id={data}
                                onChange={(e) => times(e, "minutes", "minCus")}
                              />
                              <label htmlFor="">
                                Select
                                <Dropdown
                                  value={onemin}
                                  // defaultValue={onemin}
                                  name="minutes"
                                  onChange={(e) => {
                                    selectRadio(e);
                                    setOnemin(e.value);
                                  }}
                                  options={minArray}
                                  optionLabel="name"
                                />
                                minutes
                              </label>
                              {/* <Dropdown value={selectedCity} onChange={(e) => setSelectedCity(e.value)} options={cities} optionLabel="name"
                                                                    placeholder="Select a City"/> */}
                              {/* <input autoComplete="off" type="radio" minutes="minutes" name='minutes' id={data} onChange={(e) => times(e, "minutes", "minCus")} /> <label htmlFor={data} className="schedulelabel"> Select
                                                                    <FormControl className='formselect'>
                                                                        <InputLabel id="demo-simple-select-label">Select Project</InputLabel>
                                                                        <Select
                                                                            labelId="demo-simple-select-label"
                                                                            id="demo-simple-select-minutes"
                                                                            className='demo-simple-select'
                                                                            value={onemin}
                                                                            name="minutes"
                                                                            label="Problem Type"
                                                                            onChange={(e) => { selectRadio(e); setOnemin(e.target.value) }}
                                                                            required
                                                                        >
                                                                            {
                                                                                minArray?.map((data, idx) => {
                                                                                    return (
                                                                                        <MenuItem key={idx} value={data}>{data}</MenuItem>
                                                                                    )
                                                                                })
                                                                            }
                                                                        </Select>
                                                                    </FormControl>
                                                                    Minutes</label> */}
                            </div>
                          );
                        } else {
                          let d1 = data.split("#");
                          return (
                            <div className="d-flex" key={idx}>
                              <input
                                autoComplete="off"
                                type="radio"
                                name="minutes"
                                value={d1[1]}
                                id={data}
                                onChange={(e) => times(e, "minutes")}
                              />
                              <label htmlFor={data} className="schedulelabel">
                                {d1[0]}
                              </label>
                            </div>
                          );
                        }
                      })}
                    </div>
                  </div>
                </div>
                <div className="scheduleEachBox">
                  <h3>Hours</h3>
                  <div className="d-flex justifyBetween">
                    <div className="scheduleCheckbox">
                      {hours.map((data, idx) => {
                        if (data === "custom") {
                          return (
                            <div className="d-flex settingselect" key={idx}>
                              <input
                                autoComplete="off"
                                type="radio"
                                name="hours"
                                hours="hours"
                                id={data}
                                onChange={(e) => times(e, "hours", "hourCus")}
                              />
                              <label htmlFor="">
                                Select
                                <Dropdown
                                  value={onehour}
                                  defaultValue={onehour}
                                  name="hours"
                                  onChange={(e) => {
                                    selectRadio(e);
                                    setOnehour(e.value);
                                  }}
                                  options={hoursArray}
                                  optionLabel="name"
                                />
                                hours
                              </label>
                              {/* <label htmlFor={data} className="schedulelabel"> Select
                                                                <FormControl className='formselect'>
                                                                    <InputLabel id="demo-simple-select-label">Select Project</InputLabel>
                                                                    <Select
                                                                        labelId="demo-simple-select-label"
                                                                        id="demo-simple-select-hours"
                                                                        className='demo-simple-select'
                                                                        value={onehour}
                                                                        name="hours"
                                                                        label="Problem Type"
                                                                        onChange={(e) => { selectRadio(e); setOnehour(e.target.value) }}
                                                                        required
                                                                    >
                                                                        {
                                                                            hoursArray.map((data, idx) => {
                                                                                return (
                                                                                    <MenuItem key={idx} value={data}>{data}</MenuItem>
                                                                                )
                                                                            })
                                                                        }
                                                                    </Select>
                                                                </FormControl>

                                                                Hours</label> */}
                            </div>
                          );
                        } else {
                          let d1 = data.split("#");
                          return (
                            <div className="d-flex" key={idx}>
                              <input
                                autoComplete="off"
                                type="radio"
                                name="hours"
                                id={data}
                                value={d1[1]}
                                onChange={(e) => times(e, "hours")}
                              />{" "}
                              <label htmlFor={data} className="schedulelabel">
                                {d1[0]}
                              </label>
                            </div>
                          );
                        }
                      })}
                    </div>
                  </div>
                </div>
                <div className="scheduleEachBox">
                  <h3>Days of Month</h3>
                  <div className="d-flex justifyBetween">
                    <div className="scheduleCheckbox">
                      {days.map((data, idx) => {
                        if (data === "custom") {
                          return (
                            <div className="d-flex settingselect" key={idx}>
                              <input
                                autoComplete="off"
                                type="radio"
                                name="days"
                                days="days"
                                value={idx}
                                id={data}
                                onChange={(e) => times(e, "days", "daysCus")}
                              />
                              <label htmlFor="">
                                Select
                                <Dropdown
                                  value={oneday}
                                  defaultValue={oneday}
                                  name="days"
                                  onChange={(e) => {
                                    selectRadio(e);
                                    setOneday(e.value);
                                  }}
                                  options={dayArray}
                                  optionLabel="name"
                                />
                                days
                              </label>
                              {/* <label htmlFor={data} className="schedulelabel"> Select
                                                                <FormControl className='formselect'>
                                                                    <InputLabel id="demo-simple-select-label">Select Project</InputLabel>
                                                                    <Select
                                                                        labelId="demo-simple-select-label"
                                                                        id="demo-simple-select-days"
                                                                        className='demo-simple-select'
                                                                        value={oneday}
                                                                        name="days"
                                                                        label="Problem Type"
                                                                        onChange={(e) => { selectRadio(e); setOneday(e.target.value) }}
                                                                        required
                                                                    >
                                                                        {
                                                                            dayArray.map((data, idx) => {
                                                                                return (
                                                                                    <MenuItem key={idx} value={data}>{data}</MenuItem>
                                                                                )
                                                                            })
                                                                        }
                                                                    </Select>
                                                                </FormControl>
                                                                Days</label> */}
                            </div>
                          );
                        } else {
                          let d1 = data.split("#");

                          return (
                            <div className="d-flex" key={idx}>
                              <input
                                autoComplete="off"
                                type="radio"
                                name="days"
                                id={data}
                                value={d1[1]}
                                onChange={(e) => times(e, "days")}
                              />{" "}
                              <label htmlFor={data} className="schedulelabel">
                                {d1[0]}
                              </label>
                            </div>
                          );
                        }
                      })}
                    </div>
                  </div>
                </div>
                <div className="scheduleEachBox">
                  <h3>Months</h3>
                  <div className="d-flex justifyBetween">
                    <div className="scheduleCheckbox">
                      {months.map((data, idx) => {
                        if (data === "custom") {
                          return (
                            <div className="d-flex settingselect" key={idx}>
                              <input
                                autoComplete="off"
                                type="radio"
                                name="months"
                                months="months"
                                value={idx}
                                id={data}
                                onChange={(e) =>
                                  times(e, "months", "monthsCus")
                                }
                              />
                              <label htmlFor="">
                                Select
                                <Dropdown
                                  value={onemonth}
                                  defaultValue={onemonth}
                                  name="months"
                                  onChange={(e) => {
                                    selectRadio(e);
                                    setOnemonth(e.value);
                                  }}
                                  options={monthsArray}
                                  optionLabel="name"
                                />
                                months
                              </label>
                              {/* <label htmlFor={data} className="schedulelabel"> Select
                                                                <FormControl className='formselect'>
                                                                    <InputLabel id="demo-simple-select-label">Select Project</InputLabel>
                                                                    <Select
                                                                        labelId="demo-simple-select-label"
                                                                        id="demo-simple-select-months"
                                                                        className='demo-simple-select'
                                                                        value={onemonth}
                                                                        name="months"
                                                                        label="Problem Type"
                                                                        onChange={(e) => { selectRadio(e); setOnemonth(e.target.value) }}
                                                                        required
                                                                    >
                                                                        {
                                                                            monthsArray.map((data, idx) => {
                                                                                return (
                                                                                    <MenuItem key={idx} value={data}>{data}</MenuItem>
                                                                                )
                                                                            })
                                                                        }
                                                                    </Select>
                                                                </FormControl>

                                                                Months</label> */}
                            </div>
                          );
                        } else {
                          let d1 = data.split("#");

                          return (
                            <div className="d-flex" key={idx}>
                              <input
                                autoComplete="off"
                                type="radio"
                                name="months"
                                value={d1[1]}
                                id={data}
                                onChange={(e) => times(e, "months")}
                              />{" "}
                              <label htmlFor={data} className="schedulelabel">
                                {d1[0]}
                              </label>
                            </div>
                          );
                        }
                      })}
                    </div>
                  </div>
                </div>
                <div className="scheduleEachBox">
                  <h3>Days of Week</h3>
                  <div className="d-flex justifyBetween">
                    <div className="scheduleCheckbox">
                      {weeks.map((data, idx) => {
                        if (data === "custom") {
                          return (
                            <div className="d-flex settingselect" key={idx}>
                              <input
                                autoComplete="off"
                                type="radio"
                                name="weeks"
                                weeks="weeks"
                                value={idx}
                                id={data}
                                onChange={(e) => times(e, "weeks", "weeksCus")}
                              />
                              <label htmlFor="">
                                Select
                                <Dropdown
                                  value={oneweek}
                                  defaultValue={oneweek}
                                  name="weeks"
                                  onChange={(e) => {
                                    selectRadio(e);
                                    setOneweek(e.value);
                                  }}
                                  options={weekArray}
                                  optionLabel="name"
                                />
                                Weeks
                              </label>
                              {/* <label htmlFor={data} className="schedulelabel"> Select
                                                                    <FormControl className='formselect'>
                                                                        <InputLabel id="demo-simple-select-label">Select Project</InputLabel>
                                                                        <Select
                                                                            labelId="demo-simple-select-label"
                                                                            id="demo-simple-select-weeks"
                                                                            className='demo-simple-select'
                                                                            value={oneweek}
                                                                            name="weeks"
                                                                            label="Problem Type"
                                                                            onChange={(e) => { selectRadio(e); setOneweek(e.target.value) }}
                                                                            required
                                                                        >
                                                                            {
                                                                                weekArray.map((data, idx) => {
                                                                                    return (
                                                                                        <MenuItem key={idx} value={data}>{data}</MenuItem>
                                                                                    )
                                                                                })
                                                                            }
                                                                        </Select>
                                                                    </FormControl>
                                                                    Weeks</label> */}
                            </div>
                          );
                        } else {
                          let d1 = data.split("#");
                          return (
                            <div className="d-flex" key={idx}>
                              <input
                                autoComplete="off"
                                type="radio"
                                name="weeks"
                                value={d1[1]}
                                id={data}
                                onChange={(e) => times(e, "weeks")}
                              />{" "}
                              <label htmlFor={data} className="schedulelabel">
                                {d1[0]}
                              </label>
                            </div>
                          );
                        }
                      })}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <button
            onClick={createBatch}
            className="common-btn scheduleBtn ml52px"
          >
            Submit
          </button>
        </div>
      </div>
    </div>
  );
};

export default BatchCreate;
