import React from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Activation from "./Authentication/Activation";
import CheckinEmail from "./Authentication/CheckinEmail";
import Register from "./Authentication/Register";
import Resetpassword from "./Authentication/Resetpassword";
import Resetpasswordconfirm from "./Authentication/Resetpasswordconfirm";
import SucessfullyRegistered from "./Authentication/Sucessfully_Registered";
import MLselection from "./Pages/MLselection";
import Login from "./Authentication/Login";
import Activated from "./Authentication/Activated";
import Home from "./Pages/Homee";
import Allprojects from "./Pages/Allprojects";
import Projectpipeline from "./Pages/Projects/Projectpipeline";
import Pipes from "./Pages/Projects/Pipes";
import Allpipeline from "./Pages/Pipelines/Allpipeline";
import Allexperiments from "./Pages/Experiments/Allexperiments";
import OnlineServe from "./Pages/Serving/OnlineServe";
import CreateRun from "./Pages/Projects/CreateRun";
import Profile from "./User_management/Profile";
import UserManage from "./User_management/UserManage";
import RoleManage from "./Role_Management/RoleManage";
import AssignedProjects from "./Pages/AssignedProjects";
import Jupyter from "./Pages/Jupyter";
import Monitoring from "./Pages/Monitoring";
import Acceptproject from "./Authentication/Acceptproject";
import Settings from "./Pages/Settings";
import ExperimentTracking from "./Pages/Projects/ExperimentTracking";
import SelectServing from "./Pages/Serving/SelectServing";
import BatchCreate from "./Pages/Serving/BatchCreate";
import BatchResult from "./Pages/Serving/BatchResult";
import BatchServe from "./Pages/Serving/BatchServe";
import BatchProcess from "./Pages/Serving/BatchProcess";
import UserCreate from "./User_management/UserCreate";
import DefaultParameter from "./Pages/Projects/DefaultParameter";
import MLHub from "./Pages/ML Hub/MLHub";
import Navigate from "./Navigate";
import Feature from "./Pages/Projects/feature";
import IndustryDistribution from "./Pages/Home";
import IndustryLevelProjects from "./Pages/industryProjects";
// import New from "./Pages/Projects/new";
// import Chatbot from "./Pages/Chatbot/Chatbot";
// import DragandDrop from "./Pages/Chatbot/DragandDrop";
// import ContentPage from "./Pages/Chatbot/ContentPage";
// import PluginPage from "./Pages/Chatbot/PluginPage";

const Routing = () => {
  return (
    <Router>
      <Routes>
        <Route path="/" element={<Login />} />
        <Route path="/register" element={<Register />} />
        <Route path="/activated" element={<Activated />} />
        <Route
          path="/acceptProject/:project_id/:owner_id/:user_id"
          element={<Acceptproject />}
        />
        <Route path="/reset_password" element={<Resetpassword />} />
        <Route path="/checkinemail" element={<CheckinEmail />} />
        <Route path="/register_success" element={<SucessfullyRegistered />} />
        <Route path="/:User_id" element={<Resetpasswordconfirm />} />
        <Route path="activate/:uid/:token" element={<Activation />} />
        <Route path="/mlselect" element={<MLselection />} />
        {/* <Route path="/home" element={<Home />} /> */}
        <Route path="/projects" element={<Allprojects />} />
        <Route path="/assignedProjects" element={<AssignedProjects />} />
        <Route path="/Jupyter Notebook" element={<Jupyter />} />
        <Route path="/pipelines" element={<Allpipeline />} />
        <Route path="/experiments" element={<Allexperiments />} />
        <Route path="/serving" element={<SelectServing />} />
        <Route path="/online_serving" element={<OnlineServe />} />
        <Route path="/batch_serving" element={<BatchServe />} />
        <Route path="/batch_process" element={<BatchProcess />} />
        <Route path="/batch_create" element={<BatchCreate />} />
        <Route path="/batch_result" element={<BatchResult />} />
        <Route path="/feature" element={<Feature />} />

        <Route
          path="/projectpipeline/:path/:project_name/:project_id"
          element={<Projectpipeline />}
        />
        <Route
          path="/eachPipes/:path/:project_name/:project_id/:each_build/:experiment"
          element={<Pipes />}
        />
        <Route
          path="/createRun/:path/:project_name/:project_id"
          element={<CreateRun />}
        />
        <Route
          path="/experimentTracking/:path/:project_name/:project_id"
          element={<ExperimentTracking />}
        />
        <Route
          path="/defaultParamter/:project_name/:project_id"
          element={<DefaultParameter />}
        />
        <Route path="/profile" element={<Profile />} />
        <Route path="/usermanage" element={<UserManage />} />
        <Route path="/usercreate" element={<UserCreate />} />
        <Route path="/rolemanage/:createUser" element={<RoleManage />} />
        <Route path="/Monitoring" element={<Monitoring />} />
        <Route path="/settings" element={<Settings />} />
        <Route path="/Model Hub" element={<MLHub />} />

        <Route path="*" element={<Navigate />} />
        <Route path="/Home" element={<IndustryDistribution />} />
        <Route
          path="/Home/:industryName"
          element={<IndustryLevelProjects />}
        />
        {/* <Route path="/New/:path/:project_name/:project_id" element={<New />} /> */}

        {/* <Route path="*" element={<Login />} /> */}
        {/* <Route path="/BotAngle" element={<Chatbot />} />
        <Route path="/BOTAngle/content" element={<ContentPage />} />
        <Route path="/BOTAngle/plugins" element={<PluginPage />} />
        <Routes path="/dragAnddrop" element={<DragandDrop />} /> */}
      </Routes>
    </Router>
  );
};

export default Routing;
