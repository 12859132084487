import Routing from "./Routing";
import 'primeicons/primeicons.css';

function App() {

  return (
    <div>
      <Routing />
    </div>
  );
}

export default App;
