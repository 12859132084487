import React, { useState } from "react";
import styles from "./feature.module.css";
import {
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Radio,
  RadioGroup,
  FormControlLabel,
  TextField,
} from "@mui/material";

const AccordionItem = ({
  header,
  content,
  options,
  handleSelectChange,
  selectedValue,
  handleCustomValueChange,
  customValue,
}) => {
  const [isOpen, setIsOpen] = useState(false);

  return (
    <div className={styles.accordionitem}>
      <h2 className={styles.accordionheader} id={`heading${header}`}>
        <button
          className={`accordion-button ${isOpen ? "" : "collapsed"}`}
          type="button"
          onClick={() => setIsOpen(!isOpen)}
          aria-expanded={isOpen ? "true" : "false"}
          aria-controls={`collapse${header}`}
        >
          {header}
        </button>
      </h2>

      <div
        id={`collapse${header}`}
        className={`accordion-collapse collapse ${isOpen ? "show" : ""}`}
        aria-labelledby={`heading${header}`}
      >
        <div className="accordion-body">
          <div>
            <FormControl fullWidth>
              <InputLabel id={`select-label-${header}`}>
                select the options
              </InputLabel>
              <Select
                labelId={`select-label-${header}`}
                id={`select-${header}`}
                label={header}
                value={selectedValue}
                onChange={(e) => handleSelectChange(e, header)}
                required
              >
                {content?.map((option, index) => (
                  <MenuItem key={index} value={option}>
                    {option}
                  </MenuItem>
                ))}
              </Select>

              {selectedValue === "Imputation" && (
                <div>
                  <RadioGroup
                    aria-label="imputation-options"
                    className={styles.radio}
                    name="imputation-options"
                  >
                    <FormControlLabel
                      value="mean"
                      control={<Radio />}
                      label="Mean"
                    />
                    <FormControlLabel
                      value="mode"
                      control={<Radio />}
                      label="Mode"
                    />
                    <FormControlLabel
                      value="median"
                      control={<Radio />}
                      label="Median"
                    />
                  </RadioGroup>
                  <input
                    type="text"
                    className={styles.nullip}
                    required
                    placeholder="Custom Value"
                  />
                  {/* <TextField
                    id="custom-value"
                    label="Custom Value"
                    value={customValue}
                    onChange={(e) => handleCustomValueChange(e, header)}
                    fullWidth
                    variant="outlined"
                    margin="normal"
                  /> */}
                </div>
              )}

              {selectedValue === "Deletion" && (
                <RadioGroup
                  aria-label="deletion-options"
                  className={styles.radio}
                  name="deletion-options"
                >
                  <FormControlLabel
                    value="row"
                    control={<Radio />}
                    label="Row"
                  />
                  <FormControlLabel
                    value="column"
                    control={<Radio />}
                    label="Column"
                  />
                </RadioGroup>
              )}
            </FormControl>
          </div>
        </div>
      </div>
    </div>
  );
};

const Feature = () => {
  const [selectedOptions, setSelectedOptions] = useState({});

  // Function to handle the change in the Select component
  const handleSelectChange = (event, header) => {
    const value = event.target.value;
    setSelectedOptions((prevState) => ({
      ...prevState,
      [header]: value,
    }));
  };

  // Function to handle the change in the custom value input field
  const handleCustomValueChange = (event, header) => {
    const value = event.target.value;
    setSelectedOptions((prevState) => ({
      ...prevState,
      [header]: value,
    }));
  };

  // Placeholder accordion data
  const accordionData = [
    {
      header: "Null Values",
      content: ["Imputation", "Deletion", "Forward fill", "Backward fill"],
      options: ["mean", "mode", "median"],
    },
    { header: "Duplicate Values", options: ["remove duplicates"] },
    { header: "Encoding", options: ["One-hot encoding", "Label encoding"] },
    {
      header: "Balancing data",
      content: ["Over sampling", "Under sampling", "Smote"],
    },
    { header: "Normalization", content: ["Min-Max scaling", "Z Score"] },
    { header: "Outliers removal", content: ["IQR", "Z Score"] },
    {
      header: "Feature Generation",
      content: ["Custom feature generation methods"],
    },
  ];

  return (
    <div className={styles.main}>
      <div className={styles.flex100}></div>
      <div className={styles.flex20}>
        <div className={styles.feature}>
          <h4>Feature Engineering</h4>
        </div>
        <div className="accordion" id="accordionExample">
          <div className={styles.inside}>
            {accordionData.map((item, index) => (
              <AccordionItem
                className={styles.inside}
                key={index}
                header={item.header}
                content={item.content}
                options={item.options}
                handleSelectChange={handleSelectChange}
                selectedValue={selectedOptions[item.header]}
                handleCustomValueChange={handleCustomValueChange}
                customValue={selectedOptions[item.header]}
              />
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Feature;
