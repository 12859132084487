import React, { useContext, useEffect, useState } from "react";
import FileRow from "./FileRow";
import rightArrow from "../../../src/logo/rightArrow.svg"
import { modelHubData } from "../context/context";

const Folder = ({ item }) => {
  const [temp, setTemp] = useState(false);
  const [checkShow, setCheckShow] = useState(false);

  const [selectedML, setSelectedML, fullData, checkData] = useContext(modelHubData);

  useEffect(() => {
    // console.log('checkData--->', checkData);
    // console.log('selectedML--->', selectedML);
    if (selectedML?.[item?.project_name]?.length === checkData?.[item?.project_name]?.length) {
      setCheckShow(true);
    } else {
      setCheckShow(false);
    }
  }, [selectedML])

  // console.log("checkShow->", checkShow);

  function fun1() {
    setTemp(!temp);
  }

  function checkBoxEach(e) {
    // e.stopPropagation();
    let checkboxparent = document.getElementsByClassName("checkboxparent")[0];
    let eachCheck = document.getElementsByClassName("eachcheckbox");
    for (let i of eachCheck) {
      if (!i.checked) {
        checkboxparent.checked = false;
      }
    }
  }

  function rowCheckBox() {
    let copyData = JSON.parse(JSON.stringify(selectedML));

    // console.log("copyData---", copyData);

    if (copyData?.[item?.project_name]) {
      if (copyData?.[item?.project_name].length === checkData?.[item?.project_name].length) {
        delete copyData?.[item?.project_name];
      } else {
        copyData[item?.project_name] = checkData?.[item?.project_name];
      }
    }
    else {
      let copyFullData = JSON.parse(JSON.stringify(fullData));
      let newObj = {};
      for (let i of copyFullData) {
        if (i.project_name === item?.project_name) {
          newObj = i;
        }
      }
      let a1 = [];
      for (let i of newObj.data) {
        // a1.push(i.model)
        a1.push({ model: i.model, version: i.verison });
      }
      copyData[item?.project_name] = a1;
    }
    setSelectedML(copyData);
  }

  return (
    <>
      <tr>
        <td className="newr1" scope="row">
          <input
            type="checkbox"
            className="eachcheckbox"
            // onChange={checkBoxEach}
            onChange={rowCheckBox}
            // checked={selectedML[item?.project_name]}
            checked={checkShow}
          />
          <span>
            {item.project_name}
          </span>
        </td>
        <td className="newclrrd">{item.created_by}</td>
        <td className="newclrrd">{item.created_at}</td>
        <td className="newclrrd">{item.verison}</td>
        <td className="newclrrd">{item.status}</td>
        <td className="newclrrdarrow" onClick={() => fun1()}>
          <img src={rightArrow} alt="" />
        </td>
      </tr>

      {temp && (
        <>
          {item?.data?.map((d1, idx1) => {
            return (
              <FileRow key={idx1} item={d1} model_name={item.project_name} />
            );
          })}
        </>
      )}
    </>
  );
};

export default Folder;
