import React, { useEffect } from 'react'
import { useNavigate } from 'react-router-dom'

const Navigate = () => {
    const navigate = useNavigate();

    useEffect(() => {
        navigate("/");
    }, []);

    console.log("naviiiiiiiiiii");

    return (
        <div></div>
    )
}

export default Navigate