import React, { useEffect, useRef, useState } from "react";
import { Toast } from "primereact/toast";
import Mlsidebar from "../../Components/Mlsidebar";
import mllogo from "../../logo/mlangles360logo.svg";
import mlimage from "../../logo/mlangles360logo.svg";
import { Link, useNavigate, useParams } from "react-router-dom";
import profilePic from "../../logo/profile-pic.png";
import { TabView, TabPanel } from "primereact/tabview";
import PipeslineBox from "../../Components/PipeslineBox";
import file from "../../logo/file.png";
import { Dropdown } from "primereact";
import { FormControl, InputLabel, MenuItem, Select } from "@mui/material";
import axios from "axios";
import Loading from "../../Components/Loading";
import Superuser from "../../Components/Superuser";
import { backend_url, backend_url1 } from "../../Config";
import Logout from "../Logout";
import { Dialog } from "primereact/dialog";
import ReactCrop from "react-image-crop";

const OnlineServe = () => {
  let [project_id, setProject_id] = useState("");
  let [project_name, setProject_name] = useState("");
  const [experiment_name, setExperiment_name] = useState("");
  const [modal_name, setModal_name] = useState("");
  let path = "serving";
  const [projectName, setProjectName] = useState([]);
  const [expName, setExpName] = useState([]);
  const [modelName, setModalName] = useState([]);
  const [open, setOpen] = useState(false);
  const [postserv, setPostserv] = useState([]);
  const [fullModaldata, setFullModaldata] = useState([]);
  const [columns, setCloumns] = useState([]);
  const [colEnterData, setColEnterData] = useState({});
  const [result, setResult] = useState(null);
  const [urls, setUrls] = useState("");
  const [col1, setCol1] = useState([]);
  // const [feature, setFeature] = useState("");
  const navigate = useNavigate();
  const [userDetails, setUserDetails] = useState({});
  const [logout, setLogout] = useState(false);
  const [timing, setTiming] = useState({ year: "", month: "", date: "" });
  const [associationAlgo, setAssociationAlgo] = useState([
    "apriori",
    "hmine",
    "fpgrowth",
    "ECLAT",
  ]);
  const [filename, setfilename] = useState("");
  const [associationInput, setAssociationInput] = useState([""]);
  const [imageUpload, setImageUpload] = useState(false);
  const [uploadForm, setUploadForm] = useState(null);
  const [runName, setRunName] = useState("");
  const [problem_type, setProblem_type] = useState("");
  const [imageUrl, setImageUrl] = useState("");
  const [crop, setCrop] = useState(null);
  const [fileDrag, setFileDrag] = useState(null);
  const toast = useRef(null);

  let token = JSON.parse(localStorage.getItem("mlanglesToken"));
  let Header = { headers: { Authorization: `Bearer ${token}` } };

  useEffect(() => {
    let token = JSON.parse(localStorage.getItem("mlanglesToken"));
    let userdetails = JSON.parse(localStorage.getItem("userDetails"));
    setUserDetails(userdetails);

    if (userdetails) {
      if (!token || userDetails?.permissions?.is_serve === 0) {
        navigate("/");
      }
      fetchData();
    }
  }, []);

  async function fetchData() {
    setOpen(true);
    const res = await axios.get(`${backend_url}/tracking/serving`, Header);
    setOpen(false);
    if (res.data.success) {
      //console.log(res.data.data);
      setProjectName(res.data.data);
    }
  }

  async function handleChange(e) {
    try {
      setProject_name(e.target.value);
      let project_name = e.target.value;
      let id = "";
      for (let i of projectName) {
        if (i.project_name === project_name) {
          //console.log(project_name, i.project_id)
          id = i.project_id;
          setProject_id(id);
          break;
        }
      }
      setOpen(true);
      // setOpen(false);
      //console.log(id);
      const res = await axios.post(
        `${backend_url}/tracking/serving`,
        { project_id: id },
        Header
      );
      if (res.data.success) {
        setOpen(false);
        let data = res.data.data;
        setPostserv(data);
        let arrayData = [];
        for (let i of data) {
          arrayData.push(i.run_name);
        }
        setExpName(arrayData);
        setModalName([]);
        setExperiment_name("");
        setModal_name("");
        setResult("");
        setColEnterData("");
        setCloumns([]);
        setCol1(res.data.columns);
        setImageUpload(false);
        setImageUrl("");
        setfilename("");
      } else {
        setOpen(false);
        setExpName([]);
        setModalName([]);
        setExperiment_name("");
        setModal_name("");
        setResult("");
        setColEnterData("");
        setCloumns([]);
        setImageUpload(false);
        setImageUrl("");
        setfilename("");
      }
    } catch (e) {
      setOpen(false);
      funError(e.message);
    }
  }

  async function handleChange1(e) {
    try {
      setExperiment_name(e.target.value);
      let expName = e.target.value;
      console.log("expName------->>>>", expName);
      let run_id = "";
      for (let i of postserv) {
        if (i.run_name === expName) {
          run_id = i.run_id;
          setRunName(i.run_name);
          setProblem_type(i.problem_type);
          if (i?.problem_type.includes("image")) {
            setImageUpload(true);
          } else {
            setImageUpload(false);
          }
          break;
        }
      }

      setOpen(true);
      const res = await axios.post(
        `${backend_url}/tracking/get_models_`,
        { project_id: project_id, run_id: run_id, run_name: expName },
        Header
      );
      setOpen(false);

      if (res.data.success) {
        let ans = res.data.list_of_algo_names;
        setFullModaldata(res.data.urls);
        let newArray = [];
        for (let i of ans) {
          let newStr = i.replace("(", "").replace(")", "");
          newArray.push(newStr);
        }
        setModalName(newArray);
        setModal_name("");
        setResult("");
        setColEnterData("");
        setCloumns([]);
        // setImageUpload(false);
        setImageUrl("");
        setfilename("");
      } else {
        setModalName([]);
        setModal_name("");
        setResult("");
        setColEnterData("");
        setCloumns([]);
        // setImageUpload(false);
        setImageUrl("");
      }
    } catch (e) {
      setOpen(false);

      funError(e.message);
    }
  }

  async function handleChange2(e) {
    console.log(e.target);
    try {
      setModal_name(e.target.value);
      let modal = e.target.value;
      console.log("fullModaldata--------", fullModaldata, "-----", modal);
      for (let i of fullModaldata) {
        if (modal?.toLowerCase() === i?.algorithem.toLowerCase()) {
          let urls = i.urls[0];
          console.log("urls-----", i.urls[0]);
          setUrls(urls);
          setOpen(true);
          let body = {
            model_url: urls,
            project_id: project_id,
            algorithem: modal,
          };
          if (modal === "Prophet") {
            body = {
              model_url: urls,
              algorithem: modal,
              project_id: project_id,
            };
          }
          const res = await axios.post(
            `${backend_url}/tracking/getColoumns`,
            body,
            Header
          );
          console.log("res-------", res);
          if (res.data.success) {
            setOpen(false);
            setCloumns(res.data.columns);
            // if (res?.data?.type === "dp") {
            //   setImageUpload(true);
            // } else {
            //   setImageUpload(false);
            // }
            let colObj = {};
            if (res.data?.columns?.length > 0) {
              for (let i of res.data.columns) {
                colObj[i] = 0;
              }
            }
            setColEnterData(colObj);
          } else {
            setOpen(false);
            setCloumns([]);
            setResult("");
          }
          // console.log(res);
          break;
        }
      }
    } catch (e) {
      console.log(" getColoumns error---", e.message);
      setOpen(false);
      funError(e.message);

      // setCloumns([]);
      setResult("");
    }
  }

  function inputDataChange(e) {
    //console.log("e.target--------", e.target.name, e.target.value);
    setColEnterData({ ...colEnterData, [e.target.name]: e.target.value });
  }

  console.log("colEnterData-----", colEnterData);

  function getData() {
    const imageData = document.getElementById("imageData");
    console.log(imageData.offsetWidth);
    console.log(imageData.offsetHeight);
    const imageWidth = imageData.offsetWidth;
    const imageHeight = imageData.offsetHeight;

    let [xMin, yMin, xMax, yMax] = [0, 0, 0, 0];
    xMin = crop.x;
    xMax = crop.x + crop.width;

    yMin = crop.y;
    yMax = crop.height + crop.y;

    console.log("utodcfvygilkhuj--------->",[xMin, yMin, xMax, yMax]);
    return [xMin, yMin, xMax, yMax];

    // yMax = imageHeight - crop.y;
    // yMin = imageHeight - (crop.y + crop.height);
  }

  console.log("crop-------->", crop);
  console.log("expName------->>>>>", expName);

  async function predicting(e) {
    try {
      e.preventDefault();
      if (modal_name === "Prophet") {
        setOpen(true);
        let body = {
          model_url: urls,
          rule: "",
          algorithem: "Prophet",
          data: [
            Number(colEnterData["Year"]),
            Number(colEnterData["Month"]),
            Number(colEnterData["Date"]),
          ],
          project_id: project_id,
          run_name: experiment_name,
        };
        const res = await axios.post(
          `${backend_url}/tracking/loadAndPredict`,
          body,
          Header
        );
        setOpen(false);
        if (res.data.success) {
          setResult(res.data.result);
        } else {
          funError(res.data.message);
        }
      } else if (associationAlgo.includes(modal_name)) {
        setOpen(true);
        let body = {
          algorithem: modal_name,
          data: associationInput,
          model_url: urls,
          project_id: project_id,
          run_name: experiment_name,
        };
        console.log("body------", body);
        const res = await axios.post(
          `${backend_url}/tracking/loadAndPredict`,
          body,
          Header
        );
        setOpen(false);
        if (res.data.success) {
          setResult(res.data.result.toString());
        } else {
          funError(res.data.message);
        }
      } else if (imageUpload) {
        let copyUploadForm = new FormData();
        for (let i of uploadForm) {
          let [name, value] = i;
          copyUploadForm.append(name, value);
        }
        if (modal_name === "medsam") {
          if (!crop) {
            copyUploadForm.append("medsamData", [
              0,
              crop.height,
              crop.width,
              0,
            ]);
          } else {
            copyUploadForm.append("medsamData", getData());
          }
        }
        setOpen(true);
        const res = await axios.post(
          `${backend_url}/tracking/DeepLearningServing`,
          copyUploadForm,
          Header
        );
        setOpen(false);
        if (res.data.success) {
          if (res?.data?.image) {
            setImageUrl(res.data.image);
          } else {
            setResult(res.data.data.toString());
          }
        } else {
          funError(res.data.message);
        }
      } else {
        let newData = [];
        for (let i in colEnterData) {
          newData.push(Number(colEnterData[i]));
        }
        setOpen(true);

        const res = await axios.post(
          `${backend_url}/tracking/loadAndPredict`,
          {
            model_url: urls,
            data: newData,
            algorithem: modal_name,
            project_id: project_id,
            run_name: experiment_name,
          },
          Header
        );
        if (res.data.success) {
          setOpen(false);
          let ans1 = res.data.result.toString();

          setResult(ans1);
        } else {
          funError(res.data.message);
        }
      }
    } catch (e) {
      console.log("There is an Error--", e);
      setOpen(false);
      funError(e.message);
    }
  }

  const funError = (data) => {
    toast.current.show({
      severity: "error",
      summary: "Error",
      detail: data,
      life: 2000,
    });
  };

  function timingChange(e) {
    const { name, value } = e.target;
    setTiming({ ...timing, [name]: value });
  }

  function associationInputChange(e) {
    const { name, value } = e.target;
    console.log("name---", name, "value--", value);
    let copyAssociationInput = JSON.parse(JSON.stringify(associationInput));
    copyAssociationInput[name] = value;
    setAssociationInput(copyAssociationInput);
  }

  function increaseInputField() {
    setAssociationInput([...associationInput, ""]);
  }

  function fileChangingUploadFile(e) {
    e.preventDefault();
    setfilename(e.target.files[0].name);

    const formData = new FormData();
    formData.append("files", e.target.files[0]);
    formData.append("model_url", urls);
    formData.append("run_name", runName);
    formData.append("problem_type", problem_type);
    formData.append("algorithm", modal_name);
    formData.append("project_id", project_id);

    if (modal_name === "medsam") {
      setFileDrag(URL.createObjectURL(e.target.files[0]));
    } else {
      setFileDrag(null);
    }

    setUploadForm(formData);
  }

  console.log("fileDrag----", fileDrag);

  console.log("associationInput---", associationInput);

  //   let fileInput = document.getElementById("myFile");
  //   let span = document.getElementById("fileName");
  //   // Fires on file upload
  //   fileInput.addEventListener("change", function (event) {
  //     // Get file name
  //     let fileName = fileInput.files[0].name;

  //     // Update file name in span
  //     span.innerText = fileName;
  //   });

  return (
    <div>
      <header>
        <div className="d-flex align-items-center justify-content-between">
          <div className="d-flex align-items-center">
            <div className="logohover">
              {/* <div className='mllogo'>
                                <img src={mllogo} alt="mlangles logo" />
                            </div> */}
              <div className="newlogo">
                <img src={mlimage} className="newlogos" alt="" />
              </div>
            </div>
            <h4 className="capitailze">
              {userDetails?.user_full_name} workspace /
            </h4>
            <Link to="/serving">
              <span> Serving</span>
            </Link>
            <span>/ Online Serving</span>
          </div>
          <Superuser
            logoutClicked={() => {
              setLogout(true);
            }}
          />
        </div>
      </header>

      <Dialog
        className="logout-dialogbox"
        visible={logout}
        style={{ width: "40vw" }}
        onHide={() => setLogout(false)}
      >
        <Logout no={() => setLogout(false)} />
      </Dialog>

      <Loading loading={open} />
      <Toast ref={toast} />
      <Mlsidebar data={path} />
      <div className="middlepart serving">
        <h2 className="titleFixed">ONLINE PREDICTIONS</h2>
        {/* <div className='d-flex align-items-center justify-content-center'>
                    <h4 className='error-message'>{err}</h4>
                </div> */}
        <div className="padside32 mt100px">
          <div className="servingdata">
            <div className="d-flex">
              <div className="col-md-4 flexCol">
                <label>Select Project Name</label>
                <FormControl className="dropdown-serving" fullWidth>
                  <InputLabel id="demo-simple-select-label">
                    Select Your Project Name
                  </InputLabel>
                  <Select
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    value={project_name}
                    label="Select your Project"
                    onChange={handleChange}
                    name="project_name"
                  >
                    {projectName?.map((data, idx) => {
                      return (
                        <MenuItem key={idx} value={data.project_name}>
                          {data.project_name}
                        </MenuItem>
                      );
                    })}
                  </Select>
                </FormControl>
              </div>

              <div className="col-md-4 flexCol">
                <label>Select Experiment Name</label>
                <FormControl className="dropdown-serving" fullWidth>
                  <InputLabel id="demo-simple-select-label">
                    Select Your Experiment Name
                  </InputLabel>
                  <Select
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    value={experiment_name}
                    label="Select your Project"
                    onChange={handleChange1}
                    name="experiment_name"
                  >
                    {expName.map((data, idx) => {
                      return (
                        <MenuItem key={idx} value={data}>
                          {data}
                        </MenuItem>
                      );
                    })}
                  </Select>
                </FormControl>
              </div>

              <div className="col-md-4 flexCol">
                <label>Select Model</label>
                <FormControl className="dropdown-serving" fullWidth>
                  <InputLabel id="demo-simple-select-label">
                    Select Your Model Name
                  </InputLabel>
                  <Select
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    value={modal_name}
                    label="Select your Project"
                    onChange={handleChange2}
                    name="modal_name"
                  >
                    {modelName.map((data, idx) => {
                      return (
                        <MenuItem key={idx} value={data}>
                          {data}
                        </MenuItem>
                      );
                    })}
                  </Select>
                </FormControl>
              </div>
            </div>
          </div>

          {associationAlgo?.includes(modal_name) && (
            <>
              <form onSubmit={predicting}>
                <div className="asso-input d-flex alignEnd flexWrap">
                  {associationInput?.map((item, idx) => {
                    return (
                      <>
                        <div className="col-md-4 flexCol mt32">
                          <label>Enter Item Name</label>
                          <FormControl className="dropdown-serving" fullWidth>
                            <input
                              type="text"
                              value={item}
                              name={idx}
                              onChange={associationInputChange}
                            />
                          </FormControl>
                        </div>
                      </>
                    );
                  })}
                  <span className="addInputBtn" onClick={increaseInputField}>
                    Add Input
                  </span>
                </div>
                <div className="last d-flex align-items-center">
                  <button type="submit" className="predict">
                    Predict
                  </button>
                  {result && (
                    <p className="result">
                      <span className="green">Result </span>
                      <span className="finalres">
                        {JSON.stringify(result).replaceAll('"', "")}
                      </span>
                    </p>
                  )}
                </div>
              </form>
            </>
          )}

          {imageUpload && modal_name && (
            <>
              <div className="asso-input mt32 d-flex">
                <div className="col-md-4 flexCol mt32">
                  <div className="w80 posrel fileUploadServing">
                    <h6 className="uploadFileText">Upload File</h6>
                    <input
                      type="file"
                      name="file"
                      //   value={filename}
                      id="myFile"
                      onChange={fileChangingUploadFile}
                      className="inputAbsFileUpload"
                    />
                  </div>
                  <p className="fileNameUpload">{filename}</p>
                </div>
              </div>

              {fileDrag && (
                <div className="justifyCenter m-12 mb-12">
                  <div className="w30">
                    <ReactCrop crop={crop} onChange={(c) => setCrop(c)}>
                      <img src={fileDrag} alt="" id="imageData" />
                    </ReactCrop>
                  </div>
                </div>
              )}

              <div className="last d-flex align-items-center">
                <button type="submit" onClick={predicting} className="predict">
                  Predict
                </button>
                {/* {result ? <p className='result'>  <span className='green'>Result  </span> <span className='finalres'>{feature} {result} </span>  </p> : <></>} */}
                {result && (
                  <p className="result">
                    <span className="green">Result </span>
                    <span className="finalres"> {result} </span>
                  </p>
                )}
              </div>
            </>
          )}

          {columns?.length > 0 && (
            <>
              <form onSubmit={predicting}>
                <div className="servinginputs heightfix54 posrel">
                  <h2 className="martoping22 sticky">Enter the inputs</h2>
                  <div className="d-flex flex-wrap fullinputs padtop38">
                    {columns?.map((ele, index) => {
                      let len = ele.length;
                      let show = false;
                      if (len > 10) {
                        show = true;
                      }
                      return (
                        <div
                          className={show ? "hover eachinput" : "eachinput"}
                          key={index}
                        >
                          {/* <label htmlFor="caret" className='largeData'>{ele}</label> */}
                          <div className="d-flex justify-content-between">
                            <label htmlFor="caret" className="textoverflow">
                              {ele}
                            </label>
                            <input
                              autoComplete="off"
                              required
                              step="any"
                              type="number"
                              name={ele}
                              onChange={inputDataChange}
                              id=""
                            />
                          </div>
                        </div>
                      );
                    })}
                  </div>
                </div>
                <div className="last d-flex align-items-center">
                  <button type="submit" className="predict">
                    Predict
                  </button>
                  {/* {result ? <p className='result'>  <span className='green'>Result  </span> <span className='finalres'>{feature} {result} </span>  </p> : <></>} */}
                  {result && (
                    <p className="result">
                      <span className="green">Result </span>
                      <span className="finalres"> {result} </span>
                    </p>
                  )}
                </div>
              </form>
            </>
          )}

          {imageUrl && (
            <div className="justifyCenter m-12 mb-12">
              <div className="w30">
                <img src={imageUrl} className="img-fluid" alt="" />
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default OnlineServe;
