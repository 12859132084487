import React, { useEffect, useRef, useState } from "react";
import { Toast } from "primereact/toast";
import { Link, useNavigate } from "react-router-dom";
import Mlsidebar from "../../Components/Mlsidebar";
import mllogo from "../../logo/mlangles360logo.svg";
import mlimage from "../../logo/mlangles360logo.svg";
import axios from "axios";
import Loading from "../../Components/Loading";
import { FormControl, InputLabel, MenuItem, Select } from "@mui/material";
import ExperimentSidebar from "../Projects/ExperimentSidebar";
import Superuser from "../../Components/Superuser";
import deleteIcon from "../../logo/deleteIcon.png";
import filterIcon from "../../logo/clear_filter.png";
import plus from "../../logo/plus.png";
import filter from "../../logo/filter.png";
import filterActive from "../../logo/filterActive.png";
import { backend_url } from "../../Config";
import Logout from "../Logout";
import { Dialog } from "primereact/dialog";
import NewExperiment from "../Projects/NewExperiment";

const Allexperiments = () => {
  const [exp, setexp] = useState([]);
  const [open, setOpen] = useState(false);
  const [tableHead, setTableHead] = useState([]);
  let [projectName, setProjectName] = useState("");
  const [status, setStatus] = useState(false);
  const [initialData, setInitialData] = useState([]);
  const [createdBy, setCreatedBy] = useState(false);
  const [searchCreated, setSearchCreated] = useState("");
  const [project_id, setProject_id] = useState("");
  const [openSidebar, setOpenSidebar] = useState(false);
  const [sidebarData, setSidebarData] = useState({});
  const [projects, setProjects] = useState([]);
  const navigate = useNavigate();
  const [btnshow, setBtnshow] = useState(false);
  const [allProjects, setAllProjects] = useState([]);
  const [user, setUser] = useState([]);
  const [initialUser, setInitialUser] = useState([]);
  // const [err, setErr] = useState("");
  const toast = useRef(null);
  const [projectFilter, setProjectFilter] = useState(false);
  const [initialproject, setInitialproject] = useState([]);
  const [searchProject, setSearchProject] = useState("");
  const [userDetails, setUserDetails] = useState({});
  const [logout, setLogout] = useState(false);
  const [metricsTh, setMetricsTh] = useState([]);
  const [paramTh, setParamTh] = useState([]);
  const [fullPage, setFullPage] = useState(false);

  const [filterAll, setFilterAll] = useState({
    projectName: true,
    createdBy: true,
    status: true,
  });
  const [deleteStatus, setDeleteStatus] = useState(true);
  let token = JSON.parse(localStorage.getItem("mlanglesToken"));
  let Header = { headers: { Authorization: `Bearer ${token}` } };

  let path = "experiments";

  useEffect(() => {
    let token = JSON.parse(localStorage.getItem("mlanglesToken"));
    let userdetails = JSON.parse(localStorage.getItem("userDetails"));
    setUserDetails(userdetails);
    if (userdetails) {
      if (!token || userDetails?.permissions?.experiments === 0) {
        navigate("/");
      }
      fetchData();
    }
  }, []);


  const funError = (data) => {
    toast.current.show({
      severity: "error",
      summary: "Error",
      detail: data,
      life: 2000,
    });
  };

  async function fetchData() {
    try {
      setOpen(true);
      const res = await axios.get(
        `${backend_url}/tracking/all_experiments`,
        Header
      );
      if (res.data.success) {
        setOpen(false);
        setexp(res.data.all_experiments);
        let pro = res.data.projects;
        let projectArray = [];
        for (let i of pro) {
          projectArray.push(i.project_name);
        }
        setProjects(projectArray);
        setInitialproject(projectArray);
        setAllProjects(res.data.projects);

        setInitialData(res.data.all_experiments);
        if (res.data.all_experiments[0]) {
          setTableHead(Object.keys(res.data.all_experiments[0]));
        }
        let user1 = new Set();
        let data1 = res.data.all_experiments;

        let uniqueMetrics = new Set();
        let uniqueParam = new Set();

        for (let i = 0; i < data1.length; i++) {
          //   console.log(data1[i]);
          user1.add(data1[i]?.created_by);
        }

        // for (let i = 0; i < data1.length; i++) {
        //   user1.add(data1[i].created_by);
        //   let parameters = data1[i]?.data?.data;
        //   if (parameters?.params) {
        //     for (let i of parameters?.params) {
        //       uniqueParam.add(i.key);
        //     }
        //   }
        //   if (parameters?.params) {
        //     for (let i of parameters?.metrics) {
        //       uniqueMetrics.add(i.key);
        //     }
        //   }
        // }

        // let onlyMetric = Array.from(uniqueMetrics);
        // let onlyParam = Array.from(uniqueParam);
        // let newd2 = [...uniqueParam];
        // // console.log("onlyMetric11111----------", onlyMetric, onlyMetric.length);

        // let already = 0;
        // for (let i = 0; i < onlyMetric.length; i++) {
        //   if (onlyMetric[i] === "Score") {
        //     onlyMetric.splice(i, 1);
        //     already = 1;
        //     break;
        //   }
        // }

        // if (already) {
        //   onlyMetric.push("Score");
        // }

        // setMetricsTh(onlyMetric);
        // setParamTh(onlyParam);
        console.log("user1---", user1);
        setUser(Array.from(user1));
        setInitialUser(Array.from(user1));
      } else {
        setOpen(false);
        funError("Error Occured in all_experiments");

      }
    } catch (err) {
      setOpen(false);
      console.log("There is a error--", err);
      funError(err.message);

    }
  }

  console.log("user only---", user);

  function projectNamesearch(e) {
    console.log("initialproject---------", initialproject);

    let data = e.target.value?.toLowerCase();
    setSearchProject(data);
    let newData = [];
    for (let i = 0; i < initialproject.length; i++) {
      if (initialproject[i]?.toLowerCase().includes(data)) {
        newData.push(initialproject[i]);
      }
    }
    setProjects(newData);
  }

  function handleChange(e) {
    setBtnshow(true);
    setProjectName(e.target.value);
  }

  function newRunClicked() {
    let projectId = "";
    let status1 = "";
    for (let x of allProjects) {
      console.log(x);
      if (x.project_name === projectName) {
        projectId = x.project_id;
        status1 = x.status;
        break;
      }
    }
    console.log("newRunClicked----- status1-----", status1);
    if (status1) {
      navigate(`/createRun/Projects/${projectName}/${projectId}`);
    } else {
      navigate(`/projectpipeline/Projects/${projectName}/${projectId}`);
    }
  }

  function allCommonFilter() {
    toggle();
    let eachcheckstatus = document.getElementsByClassName("eachcheckstatus");
    let eachcheckcreatedby =
      document.getElementsByClassName("eachcheckcreatedby");
    let eachprojectname = document.getElementsByClassName("projectname");

    let selCreatedBy = [];
    for (let i = 0; i < eachcheckcreatedby.length; i++) {
      if (eachcheckcreatedby[i].checked) {
        selCreatedBy.push(eachcheckcreatedby[i].name);
      }
    }

    let selStatus = [];
    for (let i = 0; i < eachcheckstatus.length; i++) {
      if (eachcheckstatus[i].checked) {
        selStatus.push(eachcheckstatus[i].name);
      }
    }

    let selProject = [];
    for (let i = 0; i < eachprojectname.length; i++) {
      if (eachprojectname[i].checked) {
        selProject.push(eachprojectname[i].name);
      }
    }

    let newData = [];

    let initialData123 = JSON.parse(JSON.stringify(initialData));
    let changeFilter = { ...filterAll };

    for (let i = 0; i < initialData123.length; i++) {
      if (
        (selCreatedBy.length !== 0
          ? selCreatedBy.includes(initialData123[i].created_by?.toLowerCase())
          : true) &&
        (selStatus.length !== 0
          ? selStatus.includes(
            initialData123[i].data.info.status?.toLowerCase()
          )
          : true) &&
        (selProject.length !== 0
          ? selProject.includes(initialData123[i].project_name?.toLowerCase())
          : true)
      ) {
        newData.push(initialData123[i]);
      }
    }
    console.log("newData------", newData);
    if (selCreatedBy.length !== 0) {
      changeFilter.createdBy = false;
    } else {
      changeFilter.createdBy = true;
    }
    if (selStatus.length !== 0) {
      changeFilter.status = false;
    } else {
      changeFilter.status = true;
    }
    if (selProject.length !== 0) {
      changeFilter.projectName = false;
    } else {
      changeFilter.projectName = true;
    }

    setFilterAll(changeFilter);

    setexp(newData);
  }

  async function runIdClicked(data) {
    console.log("data---------", data, projects);
    // setOpenSidebar(true);

    setSidebarData({ data: data.data });
    setFullPage(true);
    let projectId = "";

    console.log("allProjects---->", allProjects);

    for (let x of allProjects) {
      if (x.project_name === data.project_name) {
        projectId = x.project_id;
        setProject_id(projectId);
        break;
      }
    }
  }

  console.log("project_id----->", project_id);

  function searchCreating(e) {
    let data = e.target.value.toLowerCase();
    setSearchCreated(data);
    let newData = [];
    for (let i = 0; i < initialUser.length; i++) {
      if (initialUser[i].toLowerCase().includes(data)) {
        newData.push(initialUser[i]);
      }
    }
    setUser(newData);
  }

  function closing() {
    setOpenSidebar(false);
  }

  async function deleteExperiment() {
    try {
      console.log("deleting");
      let deleteArray = [];

      let checkboxparent = document.getElementsByClassName("checkboxparent")[0];
      let eachCheck = document.getElementsByClassName("eachcheckbox");

      for (let i of eachCheck) {
        if (i.checked) {
          console.log(i.value);
          let d1 = i?.value?.split("-");
          deleteArray.push({ run_name: d1[0], run_id: d1[1] });
          i.checked = false;
        }
      }

      if (deleteArray.length > 0) {
        setOpen(true);
        const deleting = await axios.delete(
          `${backend_url}/tracking/deleteExperimentRun`,
          {
            data: { run_ids: deleteArray },
            headers: { Authorization: `Bearer ${token}` },
          }
        );
        fetchData();
        if (deleting.data.success) {
          setDeleteStatus(true);
          clearing();
        } else {
        }
        checkboxparent.checked = false;
      }
    } catch (err) {
      setOpen(false);
      console.log("There is a error--", err);
      funError(err.message);

    }
  }

  function changecheckBoxParent(e) {
    let checkboxparent = document.getElementsByClassName("checkboxparent")[0];
    let eachCheck = document.getElementsByClassName("eachcheckbox");

    if (checkboxparent.checked) {
      for (let i = 0; i < eachCheck.length; i++) {
        eachCheck[i].checked = true;
      }
    } else {
      for (let i = 0; i < eachCheck.length; i++) {
        eachCheck[i].checked = false;
      }
    }
  }

  function checkBoxEach(e) {
    //console.log(e)
    let checkboxparent = document.getElementsByClassName("checkboxparent")[0];
    let eachCheck = document.getElementsByClassName("eachcheckbox");
    let flag = false;
    for (let i of eachCheck) {
      if (!i.checked) {
        checkboxparent.checked = false;
      } else {
        flag = true;
      }
    }
    if (flag) {
      setDeleteStatus(false);
    } else {
      setDeleteStatus(true);
    }
  }

  function pass() { }

  function uncheckAll() {
    const checkboxAll = document.querySelectorAll("[type=checkbox]");
    // console.log("checkboxAll-------", checkboxAll);
    for (let i of checkboxAll) {
      console.log(i);
      if (i.checked) {
        // console.log("checked already");
        i.checked = false;
      }
    }
  }

  function toggle() {
    const toggleDropdown = document.getElementsByClassName("dropdown-menu");
    console.log("toggleDropdown----", toggleDropdown);
    for (let i of toggleDropdown) {
      console.log(i);
      i.classList.remove("show");
    }
    // toggleDropdown.classList.remove("show");
  }


  function clearing() {
    uncheckAll();
    setSearchProject("");
    setFilterAll({ projectName: true, createdBy: true, status: true });
    setDeleteStatus(true);
    setProjects(initialproject);
  }

  function empltyList() {
    setFullPage(false);
    fetchData();
  }

  return (
    <div className="allpipeline">
      <header>
        <div className="d-flex align-items-center justify-content-between">
          <div className="d-flex align-items-center">
            <div className="logohover">
              <div className="newlogo">
                <img src={mlimage} className="newlogos" alt="" />
              </div>
            </div>
            <h4 className="capitailze">
              {userDetails?.user_full_name} workspace /
            </h4>
            <span> Experiments</span>
          </div>

          <Superuser
            logoutClicked={() => {
              setLogout(true);
            }}
          />
        </div>
      </header>

      <Dialog
        className="logout-dialogbox"
        visible={logout}
        style={{ width: "40vw" }}
        onHide={() => setLogout(false)}
      >
        <Logout no={() => setLogout(false)} />
      </Dialog>

      <Loading loading={open} />
      <Toast ref={toast} />

      {/* {openSidebar && (
        <ExperimentSidebar
          data={sidebarData}
          project_id={project_id}
          close={closing}
        />
      )} */}

      <Mlsidebar data={path} />

      <section className="middlepart allprojects dashboard allpipelines">
        <h2>All EXPERIMENTS</h2>


        {/* <div className="d-flex align-items-center justify-content-center">
          <h4 className="error-message">{err}</h4>
        </div> */}

        {fullPage ? (
          <>
            <div className="martop48">
              <NewExperiment
                sidebarData={sidebarData}
                project_id={project_id}
                allProjects={allProjects}
                valueExp={exp}
                experiment="allExperiment"
                hide={empltyList}
              />
            </div>
          </>
        ) : (
          <>
            <div className="d-flex justify-content-between align-items-center paddboth">
              <div className="d-flex align-items-center w50">
                <div className="w40 allexperiments">
                  <div className="each-check1">
                    <label className="labelstyle" htmlFor="">
                      Create Run
                    </label>
                    <FormControl fullWidth>
                      <InputLabel id="demo-simple-select-label">
                        Select Project Name
                      </InputLabel>
                      <Select
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        className="demo-simple-select"
                        value={projectName}
                        label="Problem Type"
                        onChange={handleChange}
                        required
                      >
                        {projects.map((data, idx) => {
                          return (
                            <MenuItem key={idx} value={data}>
                              {data}
                            </MenuItem>
                          );
                        })}
                      </Select>
                    </FormControl>
                  </div>
                </div>
                {btnshow ? (
                  <button className="createbtn" onClick={() => newRunClicked()}>
                    <img src={plus} alt="plus" className="plus" /> New Run
                  </button>
                ) : (
                  <></>
                )}
              </div>
              <div className="d-flex">
                <div
                  className="expcommonbtn"
                  style={{
                    opacity:
                      !filterAll.projectName ||
                        !filterAll.createdBy ||
                        !filterAll.status
                        ? 1
                        : 0.5,
                  }}
                  onClick={() => {
                    setexp(initialData);
                    clearing();
                  }}
                >
                  <img src={filterIcon} alt="" /> <span>Clear Filter</span>
                </div>
                <div
                  className="expcommonbtn"
                  style={{ opacity: deleteStatus ? 0.5 : 1 }}
                  onClick={() => {
                    deleteExperiment();
                  }}
                >
                  <img className="deletebtn" src={deleteIcon} alt="" />
                  <span>Delete</span>
                </div>
              </div>
            </div>

            <div className="fixedsize">
              <table className="sample" style={{ width: "max-content" }}>
                <thead className="fixing darkblue">
                  <tr className="smp">

                  </tr>
                  <tr>
                    <th>
                      <input
                        type="checkbox"
                        className="checkboxparent"
                        onChange={changecheckBoxParent}
                      />
                    </th>
                    <th className="posrel">
                      PROJECT NAME
                      {filterAll?.projectName ? (
                        <img
                          src={filter}
                          className="fa-solid fa-filter dropdown-toggle img-responsive filter"
                          data-bs-toggle="dropdown"
                          alt="filter"
                        />
                      ) : (
                        <img
                          src={filterActive}
                          className="fa-solid fa-filter dropdown-toggle img-responsive filter"
                          data-bs-toggle="dropdown"
                          alt="filter"
                        />
                      )}
                      <div
                        id="toggleDropdown"
                        className="posabs search-content dropdown-menu options"
                        onClick={(e) => e.stopPropagation()}
                      >
                        <input
                          autoComplete="off"
                          type="text"
                          placeholder="Search"
                          className="textinput"
                          value={searchProject}
                          onChange={(e) => projectNamesearch(e)}
                        />
                        <div className="d-flex allcheck">
                          {projects.map((ele, index) => {
                            return (
                              <div className="eachboxcheck" key={index}>
                                <input
                                  type="checkbox"
                                  id={ele}
                                  name={ele?.toLowerCase()}
                                  className="eachcheck projectname"
                                />
                                <label htmlFor={ele}>{ele} </label>
                              </div>
                            );
                          })}
                        </div>
                        <div className="text-end">
                          <button
                            onClick={() => allCommonFilter()}
                            className="apply"
                            id="applycreatedby"
                          >
                            Apply
                          </button>
                        </div>
                      </div>
                    </th>

                    <th>
                      RUN ID <span> </span>
                    </th>
                    <th>
                      RUN NAME<span> </span>
                    </th>
                    <th className="posrel width140px">
                      STATUS
                      {filterAll?.status ? (
                        <img
                          src={filter}
                          className="fa-solid fa-filter dropdown-toggle img-responsive filter"
                          data-bs-toggle="dropdown"
                          alt="filter"
                        />
                      ) : (
                        <img
                          src={filterActive}
                          className="fa-solid fa-filter dropdown-toggle img-responsive filter"
                          data-bs-toggle="dropdown"
                          alt="filter"
                        />
                      )}
                      {/* <img
                        src={filter}
                        className="fa-solid fa-filter dropdown-toggle img-responsive"
                        data-bs-toggle="dropdown"
                        alt=""
                        aria-expanded="false"
                        onClick={() => setStatus(!status)}
                      /> */}
                      <div
                        id="toggleDropdown"
                        className="posabs search-content dropdown-menu options"
                        onClick={(e) => e.stopPropagation()}
                      >
                        <div className="d-flex allcheck">
                          <div className="eachboxcheck">
                            <input
                              type="checkbox"
                              id="success"
                              name="finished"
                              className="eachcheckstatus"
                            />
                            <label htmlFor="success">Success </label>
                          </div>
                          <div className="eachboxcheck">
                            <input
                              type="checkbox"
                              id="failed"
                              name="failed"
                              className="eachcheckstatus"
                            />
                            <label htmlFor="failed">Failed </label>
                          </div>
                          <div className="eachboxcheck">
                            <input
                              type="checkbox"
                              id="running"
                              name="running"
                              className="eachcheckstatus"
                            />
                            <label htmlFor="running">Running </label>
                          </div>
                        </div>
                        <div className="text-end">
                          <button
                            onClick={() => allCommonFilter()}
                            className="apply"
                            id="applyStatus"
                          >
                            Apply
                          </button>
                        </div>
                      </div>
                    </th>
                    <th className="posrel wdth">
                      CREATED BY
                      {filterAll?.createdBy ? (
                        <img
                          src={filter}
                          className="fa-solid fa-filter dropdown-toggle img-responsive filter"
                          data-bs-toggle="dropdown"
                          alt="filter"
                        />
                      ) : (
                        <img
                          src={filterActive}
                          className="fa-solid fa-filter dropdown-toggle img-responsive filter"
                          data-bs-toggle="dropdown"
                          alt="filter"
                        />
                      )}
                      {/* <img
                        src={filter}
                        className="fa-solid fa-filter dropdown-toggle img-responsive"
                        data-bs-toggle="dropdown"
                        aria-expanded="false"
                        alt=""
                        onClick={() => {
                          setCreatedBy(!createdBy);
                        }}
                      /> */}
                      <div
                        id="toggleDropdown"
                        className="posabs search-content dropdown-menu options"
                      >
                        <input
                          autoComplete="off"
                          type="text"
                          placeholder="Search"
                          className="textinput"
                          value={searchCreated}
                          onChange={(e) => searchCreating(e)}
                        />
                        <div className="d-flex allcheck">
                          {user.map((ele, index) => {
                            return (
                              <div className="eachboxcheck" key={index}>
                                <input
                                  type="checkbox"
                                  id={ele}
                                  name={ele.toLowerCase()}
                                  className="eachcheck eachcheckcreatedby"
                                />
                                <label htmlFor={ele}>{ele} </label>
                              </div>
                            );
                          })}
                        </div>
                        <div className="text-end">
                          <button
                            onClick={() => allCommonFilter()}
                            className="apply"
                            id="applycreatedby"
                          >
                            Apply
                          </button>
                        </div>
                      </div>
                    </th>
                    <th>
                      START TIME<span> </span>
                    </th>
                    <th>
                      END TIME<span> </span>
                    </th>

                  </tr>
                </thead>
                <tbody>
                  {exp.map((allData, idx) => {
                    let starttime = new Date(
                      allData?.data?.info?.start_time
                    ).toLocaleString(undefined, { timeZone: "Asia/Kolkata" });
                    let endtime = new Date(
                      allData?.data?.info?.end_time
                    ).toLocaleString(undefined, { timeZone: "Asia/Kolkata" });

                    return (
                      <tr key={idx}>
                        <td>
                          <input
                            type="checkbox"
                            className="eachcheckbox"
                            value={`${allData?.data?.info?.run_name}-${allData?.data?.info?.run_id}`}
                            onChange={checkBoxEach}
                          />
                        </td>
                        <td>{allData.project_name}</td>
                        <td
                          className="runidtd cursorhover"
                          onClick={() =>
                            // ["finished", "success"].includes(
                            !["running"].includes(
                              allData?.data?.info?.status?.toLowerCase()
                            )
                              ? runIdClicked(allData)
                              : pass()
                          }
                        >
                          {allData?.data?.info?.run_id}
                        </td>
                        <td>{allData?.data?.info?.run_name}</td>

                        {["FINISHED", "SUCCESS"].includes(
                          allData?.data?.info?.status?.toUpperCase()
                        ) ? (
                          <td className="green">Success</td>
                        ) : (
                          <></>
                        )}
                        {["FAILED", "FAILURE"].includes(
                          allData?.data?.info?.status?.toUpperCase()
                        ) ? (
                          <td className="red">Failed</td>
                        ) : (
                          <></>
                        )}
                        {["ERROR"].includes(
                          allData?.data?.info?.status?.toUpperCase()
                        ) ? (
                          <td className="red">Error</td>
                        ) : (
                          <></>
                        )}
                        {["RUNNING", "", "UNFINISHED"].includes(
                          allData?.data?.info?.status?.toUpperCase()
                        ) || !allData?.data?.info?.status ? (
                          <td className="blue">Running...</td>
                        ) : (
                          <></>
                        )}

                        <td>{allData.created_by}</td>
                        <td>{starttime}</td>
                        <td>{endtime}</td>

                      </tr>
                    );
                  })}
                </tbody>
              </table>
            </div>
          </>
        )}
      </section>
    </div>
  );
};

export default Allexperiments;
