import React, { useEffect, useRef, useState } from 'react'
import Mlsidebar from '../../Components/Mlsidebar'
import mllogo from "../../logo/mlangles360logo.svg";
import rightArrow from "../../logo/rightArrow.png";
import uploadFile from "../../logo/uploadFile.png";
import mlimage from "../../logo/mlangles360logo.svg";
import greenInfo from "../../logo/greenInfo.png";
import { Link, useNavigate, useParams } from 'react-router-dom'
import { FormControl, InputLabel, MenuItem, Select } from '@mui/material';
import axios from 'axios'
import Loading from '../../Components/Loading'
import Superuser from '../../Components/Superuser';
import { backend_url, backend_url1 } from '../../Config'
import Logout from '../Logout';
import { Dialog } from 'primereact/dialog';
import BatchInput from './BatchInput';
import { Toast } from "primereact/toast";

const BatchProcess = () => {

    const toast = useRef(null);
    let path = "serving";
    const [open, setOpen] = useState(false);
    const navigate = useNavigate();
    const [userDetails, setUserDetails] = useState({});
    const [logout, setLogout] = useState(false);
    const [err, setErr] = useState("");
    const [showBottom, setShowBottom] = useState("");
    const [database, setDatabase] = useState("");
    const [csv, setCsv] = useState();
    // const formData = new FormData();
    const [fileName, setFileName] = useState("");
    const [files3, setFileS3] = useState(false);
    const [dataurls, setDataurls] = useState("");
    const [uploadFileurl, setUploadFileurl] = useState("");

    let token = JSON.parse(localStorage.getItem("mlanglesToken"));
    let Header = { headers: { "Authorization": `Bearer ${token}` } };
    const [validate1, setValidate1] = useState({
        s3: false,
        mysql_db: false,
        postgres_db: false,
    })

    useEffect(() => {
        let token = JSON.parse(localStorage.getItem("mlanglesToken"));
        let userdetails = JSON.parse(localStorage.getItem("userDetails"));
        setUserDetails(userdetails);

        if (userdetails) {
            if (!token || userDetails?.permissions?.is_serve === 0) {
                navigate("/");
            }
        }
    }, [])

    function errorFun() {
        setTimeout(() => {
            setErr("");
        }, 4000)
    }

    function fileUnselect() {
        setCsv("");
        setFileName("");
        setUploadFileurl("");
    }

    async function fileSelecting(e) {

        let file = e.target.files[0];
        let name = e.target.files[0].name;
        setCsv(e.target.files[0]);

        e.preventDefault();

        const formData = new FormData();
        // formData.append("csv", file, name);
        formData.append("csv", file);

        setOpen(true);

        try {
            const res = await axios.post(`${backend_url}/tracking/multi_prediction`, formData, Header);
            console.log("res---", res);
            setOpen(false);
            if (res.data.success) {
                setUploadFileurl(res.data.url_for_prediction);
                setFileName(e.target.files[0].name);
                setShowBottom("url");
            }
        } catch (e) {
            console.log("error----", e);
            setErr(e);
            setOpen(false);
        }
    }

    function dataBaseHandle(e) {
        let d1 = e.target.value;
        setDatabase(d1);
        setShowBottom(`database-${d1}`);
    }

    // console.log("uploadFileurl----", uploadFileurl);

    async function formInput(data, part, next) {
        try {
            console.log("formInput------", data, part, next);

            let part1 = "";
            if (part === "url") {
                part1 = "DataURL"
            } else {
                part1 = part;
            }
            let validate = data;
            let newValidate = {};

            let allLowerCase = false;
            for (let i in validate) {
                if (i.includes("AWS_ACCESS_KEY_ID")) {
                    allLowerCase = true;
                }
            }

            if (allLowerCase) {
                for (let i in validate) {
                    newValidate[i.toLowerCase()] = validate[i];
                }
            }

            for (let i in validate) {

                // console.log("ii------", i, validate[i]);

                if (i.includes("tablename")) {
                    newValidate["tablename"] = validate[i];
                }
                else if (i.includes("databasename")) {
                    newValidate["databasename"] = validate[i];
                }
                else if (i.includes("hostname")) {
                    newValidate["hostname"] = validate[i];
                }
                else if (i.includes("username")) {
                    newValidate["username"] = validate[i];
                }
                else if (i.includes("password")) {
                    newValidate["password"] = validate[i];
                }
            }

            newValidate["data_type"] = part;

            if (next) {
                let data_connection = {};
                data_connection[part1] = data;
                if (part1 === "DataURL") {
                    console.log("uploadFileurl----", uploadFileurl)
                    data_connection[part1]["dataurl"] = uploadFileurl;
                }
                let d1 = {
                    "connection_type": part1,
                    data_connection
                }
                setValidate1({
                    s3: false,
                    mysql_db: false,
                    postgres_db: false,
                });
                // console.log("navigate------- /batch_create-----------", d1);
                navigate("/batch_create", { state: d1 });
            } else {
                setOpen(true);
                try {
                    const res = await axios.post(`${backend_url}/configuration/check_credentials_and_path`, newValidate, Header);
                    if (res.data.success) {
                        setValidate1({ ...validate1, [part]: true });
                    } else {
                        toast.current.show({ severity: 'error', summary: 'Error', detail: res.data.message, life: 3000 });
                    }
                } catch (e) {
                    setErr(e.message);
                    toast.current.show({ severity: 'error', summary: 'Error', detail: e.message, life: 3000 });
                }
                setOpen(false);
            }
        }
        catch (e) {
            console.log("e------", e);
        }
    }

    function clearValidate() {
        setValidate1({
            s3: false,
            mysql_db: false,
            postgres_db: false,
        })
    }

    return (
        <div>
            <header>
                <div className='d-flex align-items-center justify-content-between' >
                    <div className="d-flex align-items-center">
                        <div className='logohover'>
                            {/* <div className='mllogo'>
                                <img src={mllogo} alt="mlangles logo" />
                            </div> */}
                            <div className="newlogo">
                                <img src={mlimage} className='newlogos' alt="" />
                            </div>
                        </div>
                        <h4 className='capitailze'>{userDetails?.user_full_name} workspace / </h4><Link to="/serving"><span> Serving</span></Link><span>/ Batch Serving</span>
                    </div>
                    <Superuser logoutClicked={() => { setLogout(true) }} />

                </div>
            </header>

            <Dialog className="logout-dialogbox" visible={logout} style={{ width: '40vw' }} onHide={() => setLogout(false)}>
                <Logout no={() => setLogout(false)} />
            </Dialog>

            <Loading loading={open} />

            <Mlsidebar data={path} />

            <div className="middlepart serving">
                <h2 className='titleFixed'>BATCH PREDICTIONS</h2>
                {
                    err ? <div className='d-flex align-items-center justify-content-center'>
                        <h4 className='error-message'>{err}</h4>
                    </div> : <></>
                }
                <Toast ref={toast} />
                <div className='serve-inside inside-serving'>
                    <div className='serpro-to'>
                        <h4 className='uploadHead'>Upload Your Data</h4>
                        <div className="serpro-top">
                            <div className='serpro-eachinput' onClick={() => { setDatabase(""); setDataurls(""); clearValidate(); setUploadFileurl("") }}>
                                <div className='posrel'>
                                    <div className='connects3element fileup'>
                                        {fileName ? <p> {fileName} </p> : <p>Upload Cleaned dataset file</p>}
                                        <div className='width18px'>
                                            <img src={uploadFile} className='img-fluid' alt="right Arrow" />
                                        </div>
                                        {/*  */}
                                        <input autoComplete="off" type="file" className='fileupload' required accept=".csv,.pdf" onChange={fileSelecting} />
                                        {/*  */}
                                    </div>
                                    {/* <div className='fileuploading'>
                                        <input autoComplete="off" type="file" required accept=".csv,.pdf" onChange={fileSelecting} />
                                    </div> */}
                                    {
                                        fileName ?
                                            <div className='fileSuccessInfo'>
                                                <img src={greenInfo} alt="greenInfo" />
                                                Your file has uploaded sucessfully
                                            </div>
                                            :
                                            <></>
                                    }

                                </div>
                            </div>
                            <div className='serpro-eachinput' onClick={() => { setShowBottom("s3"); setDatabase(""); setDataurls(""); fileUnselect(); clearValidate(); setUploadFileurl("") }}>
                                <div className='connects3element'>
                                    <p className='' >Connect to your S3</p>
                                    <div className='width18px'>
                                        <img src={rightArrow} className='img-fluid' alt="right Arrow" />
                                    </div>
                                </div>

                            </div>
                            <div className='serpro-eachinput' onClick={() => { setDataurls(""); fileUnselect(); clearValidate(); setUploadFileurl("") }} >
                                <FormControl fullWidth>
                                    <InputLabel id="demo-simple-select-label">Connect to Database</InputLabel>
                                    <Select
                                        labelId="demo-simple-select-label"
                                        id="demo-simple-select"
                                        className='demo-simple-select'
                                        // onClick={() => setShowBottom("database")}
                                        value={database}
                                        label="Problem Type"
                                        onChange={dataBaseHandle}
                                    // required
                                    >
                                        <MenuItem value="sql">MySql</MenuItem>
                                        <MenuItem value="postgres">Postgres</MenuItem>
                                    </Select>
                                </FormControl>
                            </div>
                            <div className='serpro-eachinput' onClick={() => { setShowBottom("url"); setDatabase(""); fileUnselect(); clearValidate(); setUploadFileurl("") }}>
                                <div className='connects3element pad0 lastInput'>
                                    <input autoComplete="off" type="text" placeholder='Provide clean data URL' value={dataurls} onChange={(e) => { setUploadFileurl(e.target.value); setDataurls(e.target.value) }} />
                                    <div className='width18px'>
                                        <img src={rightArrow} className='img-fluid' alt="right Arrow" />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    {
                        showBottom ? <BatchInput current={showBottom} data={formInput} validate={validate1} /> : <></>
                    }
                </div>
            </div>
        </div>
    )
}

export default BatchProcess;