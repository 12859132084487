
function changecheckBoxParent(e) {
    let checkboxparent = document.getElementsByClassName("checkboxparent")[0];
    let eachCheck = document.getElementsByClassName("eachcheckbox");

    if (checkboxparent.checked) {
        for (let i = 0; i < eachCheck.length; i++) {
            eachCheck[i].checked = true;
        }
    } else {
        for (let i = 0; i < eachCheck.length; i++) {
            eachCheck[i].checked = false;
        }
    }
}

function checkBoxEach(e) {
    //console.log(e)
    let checkboxparent = document.getElementsByClassName("checkboxparent")[0];
    let eachCheck = document.getElementsByClassName("eachcheckbox");
    for (let i of eachCheck) {
        if (!i.checked) {
            checkboxparent.checked = false;
        }
    }
}


function clearCheckBox() {
    console.log('checkboxForRole')
    const checkbox = document.getElementsByClassName("checkboxForRole");
    for (let i of checkbox) {
        i.checked = false;
    }
    const checkbox1 = document.getElementsByClassName("checkbox");
    for (let i of checkbox1) {
        i.checked = false;
    }
}

let token = JSON.parse(localStorage.getItem("mlanglesToken"));


function scrollToView(data) {
    console.log('scrollToView------->', data);
    setTimeout(() => {
        let s1 = document.getElementById(data);
        console.log('s1------', s1);
        if (s1) {
            console.log('showingggggggg')
            s1.scrollIntoView({ block: "center", behavior: "smooth" });
        }
    }, 100)
}

export { scrollToView, changecheckBoxParent, checkBoxEach, clearCheckBox, token };