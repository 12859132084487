import React, { useEffect, useRef, useState } from "react";
import { Toast } from "primereact/toast";

import Mlsidebar from "../../Components/Mlsidebar";
import { Link, useNavigate, useParams } from "react-router-dom";
import mllogo from "../../logo/mlangles360logo.svg";
import mlimage from "../../logo/mlangles360logo.svg";
import profilePic from "../../logo/profile-pic.png";
import { TabView, TabPanel } from "primereact/tabview";
import github from "../../logo/github.png";
import ProjectTable from "./ProjectTable";
import ExperimentTracking from "./ExperimentTracking";
import axios from "axios";
import Loading from "../../Components/Loading";
import filter from "../../logo/filter.png";
import clearFilter from "../../logo/clear_filter.png";
import filterActive from "../../logo/filterActive.png";
import plus from "../../logo/plus.png";
import deleteIcon from "../../logo/deleteIcon.png";
import Superuser from "../../Components/Superuser";
import { backend_url, backend_url1 } from "../../Config";
import { Dialog } from "primereact/dialog";
import Logout from "../Logout";

const Projectpipeline = () => {
  const { path, project_name, project_id } = useParams();
  const [inputData, setInputData] = useState("");
  const [totalBuilds, setTotalBuilds] = useState([]);
  const [edit, setEdit] = useState(false);
  const [open, setOpen] = useState(true);
  const navigate = useNavigate();
  const [createdBy, setCreatedBy] = useState(false);
  const [status, setStatus] = useState(false);
  const [user, setUser] = useState([]);
  const [initialData, setInitialData] = useState([]);
  const [initialUser, setInitialUser] = useState([]);
  const [searchCreated, setSearchCreated] = useState("");
  const [userDetails, setUserDetails] = useState({});
  const [logout, setLogout] = useState(false);
  const toast = useRef(null);

  const [filterAll, setFilterAll] = useState({ createdBy: true, status: true });
  const [deleteStatus, setDeleteStatus] = useState(true);

  let token = JSON.parse(localStorage.getItem("mlanglesToken"));
  let Header = { headers: { Authorization: `Bearer ${token}` } };

  function rowclicked(data) {
    navigate(`${data}`);
  }

  const funError = (data) => {
    toast.current.show({
      severity: "error",
      summary: "Error",
      detail: data,
      life: 2000,
    });
  };
  async function fetchData() {
    try {
      setOpen(true);
      const res = await axios.post(
        `${backend_url}/pipeline/get_git_url`,
        { project_id: project_id },
        Header
      );
      setOpen(false);
      if (res.data.success) {
        setInputData(res.data.git_hub_url);
        setEdit(true);
        setOpen(true);
        let totalBuildList = await axios.get(
          `${backend_url}/pipeline/total_build_list/${project_id}`,
          Header
        );

        setOpen(false);
        if (totalBuildList.data.success) {
          let uniqueUser = new Set();
          let data1 = totalBuildList.data.data;
          for (let i = 0; i < data1.length; i++) {
            uniqueUser.add(data1[i].created_by);
          }
          let new1 = Array.from(uniqueUser);
          setUser(new1);
          setInitialUser(new1);
          setTotalBuilds(totalBuildList.data.data);
          setInitialData(totalBuildList.data.data);
        } else {
          funError("Error Occured");
        }
      }
    } catch (e) {
      console.log("error--", e);
      funError(e.message);
      setOpen(false);
    }
  }

  useEffect(() => {
    let token = JSON.parse(localStorage.getItem("mlanglesToken"));
    let userdetails = JSON.parse(localStorage.getItem("userDetails"));
    setUserDetails(userdetails);
    if (userdetails) {
      if (!token || userDetails?.permissions?.is_pipeline === 0) {
        navigate("/");
      }
      fetchData();
    }
  }, []);

  async function buildOrRun() {
    try {
      setOpen(true);
      const totalBuildList = await axios.get(
        `${backend_url}/pipeline/total_build_list/${project_id}`,
        Header
      );
      setOpen(false);
      //console.log("totalBuildList---", totalBuildList)
      if (totalBuildList.data.success) {
        setTotalBuilds(totalBuildList.data.data);
        let newData = totalBuildList.data.data;
        let lastData = newData[0];
        let each = lastData?.build?.split("#");
        let each_build = each[1];

        if (!newData[0].result) {
          // setOpen(false);
          navigate(
            `/eachPipes/Projects/${project_name}/${project_id}/${each_build}/false`
          );
        } else {
          // setOpen(false);
          navigate(
            `/eachPipes/Projects/${project_name}/${project_id}/${each_build}/false`
          );
        }
      } else {
        funError("Error occured in getting total_build_list");
      }
    } catch (e) {
      console.log("There is an Error--", e);
      setOpen(false);
      funError(e.message);
    }
  }

  async function gitSubmit(e) {
    try {
      e.preventDefault();
      setOpen(true);

      if (!edit) {
        const giturlSubmit = await axios.post(
          `${backend_url}/pipeline/create_jenkins_pipeline/${project_id}`,
          {
            git_link: inputData,
            project_id: project_id,
          },
          Header
        );
        //console.log("giturlSubmit---", giturlSubmit);
        // setOpen(false);
        if (giturlSubmit.data.success) {
          //console.log("success")
          setTimeout(() => {
            buildOrRun();
          }, 10000);
        } else {
          funError(giturlSubmit.data.message);
          setOpen(false);
        }
      } else {
        setOpen(true);
        const runBuild = await axios.post(
          `${backend_url}/pipeline/run_build`,
          { project_name: project_name },
          Header
        );
        //console.log("runBuild--", runBuild);
        if (runBuild.data.success) {
          //console.log("settimeout");
          setOpen(true);
          setTimeout(() => {
            buildOrRun();
          }, 8000);
        }
      }
    } catch (e) {
      console.log("There is an error--", e);
      setOpen(false);
      funError(e.message);
    }
  }
  function allCommonFilter() {
    toggle();
    let eachcheckstatus = document.getElementsByClassName("eachcheckstatus");
    let eachcheckcreatedby =
      document.getElementsByClassName("eachcheckcreatedby");
    // let eachprojectname = document.getElementsByClassName("projectname");

    let selCreatedBy = [];
    for (let i = 0; i < eachcheckcreatedby.length; i++) {
      if (eachcheckcreatedby[i].checked) {
        selCreatedBy.push(eachcheckcreatedby[i].name);
      }
    }

    let selStatus = [];
    for (let i = 0; i < eachcheckstatus.length; i++) {
      if (eachcheckstatus[i].checked) {
        selStatus.push(eachcheckstatus[i].name);
      }
    }

    console.log("selected---", selCreatedBy, selStatus);

    let newData = [];

    let initialData123 = JSON.parse(JSON.stringify(initialData));
    console.log("initialData123------->", initialData123);
    let changeFilter = { ...filterAll };

    for (let i = 0; i < initialData123.length; i++) {
      // console.log(initialData123[i]);

      if (
        (selCreatedBy.length !== 0
          ? selCreatedBy.includes(initialData123[i].created_by?.toLowerCase())
          : true) &&
        (selStatus.length !== 0
          ? selStatus.includes(initialData123[i].result?.toLowerCase())
          : true)
      ) {
        newData.push(initialData123[i]);
      }
    }
    console.log("newData------", newData);
    if (selCreatedBy.length !== 0) {
      changeFilter.createdBy = false;
    } else {
      changeFilter.createdBy = true;
    }
    if (selStatus.length !== 0) {
      changeFilter.status = false;
    } else {
      changeFilter.status = true;
    }

    setFilterAll(changeFilter);
    setTotalBuilds(newData);
  }

  function searchCreating(e) {
    let data = e.target.value?.toLowerCase();
    setSearchCreated(data);
    let newData = [];
    for (let i = 0; i < initialUser.length; i++) {
      if (initialUser[i]?.toLowerCase().includes(data)) {
        newData.push(initialUser[i]);
      }
    }
    setUser(newData);
  }

  async function deleteFunction() {
    try {
      let eachCheck = document.getElementsByClassName("eachcheckbox");
      let deleteData = [];
      for (let i of eachCheck) {
        if (i.checked) {
          // checkboxparent.checked = false;
          console.log(i);
          console.log(i.target);
          console.log(i.value);
          console.log(i.className);
          let spliting = i?.value?.split("-");
          deleteData.push({
            project_id: Number(spliting[0]),
            build_id: Number(spliting[1]),
          });
        }
      }
      if (deleteData.length > 0) {
        setOpen(true);
        const deletingPipeline = await axios.delete(
          `${backend_url}/pipeline/delete_pipeline_builds`,
          {
            data: { project: deleteData },
            headers: { Authorization: `Bearer ${token}` },
          }
        );
        if (deletingPipeline.data.success) {
          setOpen(false);
        } else {
          setOpen(false);
        }
        for (let i of eachCheck) {
          if (i.checked) {
            i.checked = false;
          }
        }
        setDeleteStatus(true);
        let checkboxparent =
          document.getElementsByClassName("checkboxparent")[0];
        checkboxparent.checked = false;
        clearing();
        fetchData();
      }
    } catch (e) {
      console.log("There is an Error--", e);
      setOpen(false);
      funError(e.message);
    }
  }

  function changecheckBoxParent(e) {
    let checkboxparent = document.getElementsByClassName("checkboxparent")[0];
    let eachCheck = document.getElementsByClassName("eachcheckbox");

    if (checkboxparent.checked) {
      for (let i = 0; i < eachCheck.length; i++) {
        eachCheck[i].checked = true;
      }
      setDeleteStatus(false);
    } else {
      for (let i = 0; i < eachCheck.length; i++) {
        eachCheck[i].checked = false;
      }
      setDeleteStatus(true);
    }
  }

  function checkBoxEach(e) {
    //console.log(e)
    let checkboxparent = document.getElementsByClassName("checkboxparent")[0];
    let eachCheck = document.getElementsByClassName("eachcheckbox");
    let flag = false;
    for (let i of eachCheck) {
      if (!i.checked) {
        checkboxparent.checked = false;
      } else {
        flag = true;
      }
    }
    if (flag) {
      setDeleteStatus(false);
    } else {
      setDeleteStatus(true);
    }
  }

  function uncheckAll() {
    const checkboxAll = document.querySelectorAll("[type=checkbox]");
    // console.log("checkboxAll-------", checkboxAll);
    for (let i of checkboxAll) {
      console.log(i);
      if (i.checked) {
        // console.log("checked already");
        i.checked = false;
      }
    }
  }

  function toggle() {
    const toggleDropdown = document.getElementsByClassName("dropdown-menu");
    for (let i of toggleDropdown) {
      i.classList.remove("show");
    }
    // toggleDropdown.classList.remove("show");
  }

  function clearing() {
    setSearchCreated("");
    uncheckAll();
    setFilterAll({ createdBy: true, status: true });
    setDeleteStatus(true);
    // filterCreatedBy();
  }

  return (
    <div>
      <header>
        <div className="d-flex align-items-center justify-content-between">
          <div className="d-flex align-items-center">
            <div className="logohover">
              {/* <div className='mllogo'>
                                <img src={mllogo} alt="mlangles logo" />
                            </div> */}
              <div className="newlogo">
                <img src={mlimage} className="newlogos" alt="" />
              </div>
            </div>
            <Link to="/Home">
              <h4 className="capitailze">
                {userDetails?.user_full_name} workspace /
              </h4>
            </Link>
            <Link to="/Projects">
              <span> Projects /</span>
            </Link>
            <span className="capitailze">{project_name} Pipeline</span>
          </div>
          <Superuser
            logoutClicked={() => {
              setLogout(true);
            }}
          />
        </div>
      </header>

      <Dialog
        className="logout-dialogbox"
        visible={logout}
        style={{ width: "40vw" }}
        onHide={() => setLogout(false)}
      >
        <Logout no={() => setLogout(false)} />
      </Dialog>

      <Loading loading={open} />
      <Toast ref={toast} />

      <Mlsidebar data={path} />

      <div className="middlepart">
        <div className="card">
          <div className="each-pipehead">
            <div className=" d-flex align-items-center justify-content-between mar-right25 pad12 he56">
              <div className="d-flex align-items-center">
                <h6 className="head green mypro cursor">PIPELINES</h6>
                <Link
                  to={`/experimentTracking/${path}/${project_name}/${project_id}`}
                >
                  <h6 className="head assignpro cursor">EXPERIMENT TRACKING</h6>
                </Link>
              </div>
              {/* <div>
                                <Link to="/serving"><button className='goto-serv'>Go to Serving</button></Link>
                            </div> */}
            </div>
          </div>

          {/* pipeline */}
          <div className="mt82px d-flex justify-content-center align-items-center">
            <div className="width90">
              {/* <div className="d-flex align-items-center justify-content-center">
                <h4 className="error-message">{err}</h4>
              </div> */}
              <form onSubmit={gitSubmit}>
                <div className="d-flex w100 align-items-center pipelinebox justify-content-between posrel">
                  <div className="w70 d-flex align-items-center">
                    <input
                      autoComplete="off"
                      type="text"
                      required
                      disabled={edit ? true : false}
                      value={inputData}
                      onChange={(e) => {
                        setInputData(e.target.value);
                      }}
                      className="inputpipeline"
                      placeholder="Provide your Github URL here..."
                    />
                    <a target="_blank" href="https://github.com/">
                      <img src={github} alt="" className="github" />
                    </a>
                  </div>
                  <div className="git">
                    <p className="samplelook ">Hey Take a look in github...</p>
                  </div>
                  <button type="submit" className="pipelinebtn commonbtn">
                    
                    <img src={plus} className="plus" alt="" /> New Pipeline
                  </button>
                </div>
              </form>

              <div className="d-flex justify-content-end martop32">
                <div
                  className="commonbtn"
                  style={{
                    opacity:
                      !filterAll.createdBy || !filterAll.status ? 1 : 0.5,
                  }}
                  onClick={() => {
                    clearing();
                    setTotalBuilds(initialData);
                  }}
                >
                  <img src={clearFilter} className="filter" alt="" /> Clear
                  Filter
                </div>
                <div
                  className="commonbtn"
                  style={{ opacity: deleteStatus ? 0.5 : 1 }}
                  onClick={() => deleteFunction()}
                >
                  <img src={deleteIcon} className="deleteIcon" alt="" /> Delete
                </div>
              </div>

              <div className="w100 martop20 fixedTable">
                <table className="w100 hometable nonitolight">
                  <thead className="nonitolight" style={{ fontWeight: "100" }}>
                    <tr className="nonitolight">
                      <td className="w40px">
                        <input
                          type="checkbox"
                          name=""
                          id=""
                          className="checkboxparent"
                          onChange={changecheckBoxParent}
                        />
                      </td>
                      <td className="nonitolight">PIPELINE NAME</td>
                      <td>PIPELINE DURATION (ms)</td>
                      <td className="posrel">
                        CREATED BY
                        {filterAll?.createdBy ? (
                          <img
                            src={filter}
                            className="fa-solid fa-filter dropdown-toggle img-responsive filter"
                            data-bs-toggle="dropdown"
                            alt="filter"
                          />
                        ) : (
                          <img
                            src={filterActive}
                            className="fa-solid fa-filter dropdown-toggle img-responsive filter"
                            data-bs-toggle="dropdown"
                            alt="filter"
                          />
                        )}
                        <div
                          onClick={(e) => e.stopPropagation()}
                          id="toggleDropdown"
                          className="posabs search-content dropdown-menu options"
                        >
                          <input
                            autoComplete="off"
                            type="text"
                            placeholder="Search"
                            className="textinput"
                            value={searchCreated}
                            onChange={(e) => searchCreating(e)}
                          />
                          <div className="d-flex allcheck">
                            {user.map((ele, index) => {
                              return (
                                <div className="eachboxcheck" key={index}>
                                  <input
                                    type="checkbox"
                                    id={ele}
                                    name={ele?.toLowerCase()}
                                    className="eachcheck eachcheckcreatedby"
                                  />
                                  <label htmlFor={ele}>{ele} </label>
                                </div>
                              );
                            })}
                          </div>
                          <div className="text-end">
                            <button
                              onClick={() => allCommonFilter()}
                              className="apply"
                              id="applycreatedby"
                            >
                              Apply
                            </button>
                          </div>
                        </div>
                      </td>
                      <td>CREATED AT</td>
                      <td className="posrel">
                        STATUS
                        {filterAll?.status ? (
                          <img
                            src={filter}
                            className="fa-solid fa-filter dropdown-toggle img-responsive filter"
                            data-bs-toggle="dropdown"
                            alt="filter"
                          />
                        ) : (
                          <img
                            src={filterActive}
                            className="fa-solid fa-filter dropdown-toggle img-responsive filter"
                            data-bs-toggle="dropdown"
                            alt="filter"
                          />
                        )}
                        <div
                          id="toggleDropdown"
                          onClick={(e) => e.stopPropagation()}
                          className="posabs search-content dropdown-menu options"
                        >
                          <div className="d-flex allcheck">
                            <div className="eachboxcheck">
                              <input
                                type="checkbox"
                                id="success"
                                name="success"
                                className="eachcheckstatus"
                              />
                              <label htmlFor="success">Success </label>
                            </div>
                            <div className="eachboxcheck">
                              <input
                                type="checkbox"
                                id="failure"
                                name="failure"
                                className="eachcheckstatus"
                              />
                              <label htmlFor="failed">Failed </label>
                            </div>
                            <div className="eachboxcheck">
                              <input
                                type="checkbox"
                                id="running"
                                name="running"
                                className="eachcheckstatus"
                              />
                              <label htmlFor="running">Running </label>
                            </div>
                          </div>
                          <div className="text-end">
                            <button
                              onClick={() => allCommonFilter()}
                              className="apply"
                              id="applyStatus"
                            >
                              Apply
                            </button>
                          </div>
                        </div>
                      </td>
                    </tr>
                  </thead>
                  <tbody>
                    {totalBuilds.map((data, idx) => {
                      let each = data?.build?.split("#");
                      let each_build = each[1];
                      let dateAndTime = new Date(
                        data.created_at
                      ).toLocaleString(undefined, { timeZone: "Asia/Kolkata" });
                      return (
                        <tr key={idx}>
                          <td>
                            <input
                              type="checkbox"
                              name={data.build}
                              value={`${project_id + "-" + each_build}`}
                              id=""
                              className="eachcheckbox"
                              onChange={checkBoxEach}
                            />
                          </td>

                          {/* {["FINISHED", "SUCCESS", "UNFINISHED"].includes(
                            data?.result?.toUpperCase()
                          ) ? (
                            <td
                              className="cursor cursorhover"
                              onClick={() =>
                                rowclicked(
                                  `/eachPipes/Projects/${project_name}/${project_id}/${each_build}/false`
                                )
                              }
                            >
                              {data.build}
                            </td>
                          ) : (
                            <td className="cursor cursorhover">
                              {data.build}
                            </td>
                          )} */}

                          <td
                            className="cursor cursorhover"
                            onClick={() =>
                              rowclicked(
                                `/eachPipes/Projects/${project_name}/${project_id}/${each_build}/false`
                              )
                            }
                          >
                            {data.build}
                          </td>

                          <td>{data.pipeline_duration} </td>
                          <td>{data.created_by} </td>
                          <td>{dateAndTime}</td>
                          {["FINISHED", "SUCCESS"].includes(
                            data?.result?.toUpperCase()
                          ) ? (
                            <td className="green">Success </td>
                          ) : (
                            <></>
                          )}
                          {["FAILED", "FAILURE"].includes(
                            data?.result?.toUpperCase()
                          ) ? (
                            <td className="red">Failed </td>
                          ) : (
                            <></>
                          )}

                          {["RUNNING", "", "UNFINISHED"].includes(
                            data?.result?.toUpperCase()
                          ) || !data.result ? (
                            <td className="blue">Running </td>
                          ) : (
                            <></>
                          )}
                        </tr>
                      );
                    })}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Projectpipeline;
