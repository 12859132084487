import React, { useEffect, useRef, useState } from "react";
import { Toast } from "primereact/toast";
import Loading from "../../Components/Loading";
import { backend_url } from "../../Config";
import axios from "axios";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import projectIcon from "../../logo/experiment_tracking/projectIcon.svg";
import successIcon from "../../logo/experiment_tracking/successIcon.svg";
import failIcon from "../../logo/experiment_tracking/failIcon.svg";
import runIcon from "../../logo/experiment_tracking/runIcon.svg";
import subfile from "../../logo/subfile.png";
import InputLabel from "@mui/material/InputLabel";
import deleteIcon from "../../logo/deleteIcon.png";
import filterIcon from "../../logo/clear_filter.png";
import tableIcon from "../../logo/table.png";
import DownloadIcon from "@mui/icons-material/Download";
import folderDown from "../../logo/folderDown.png";
import FolderIcon from "@mui/icons-material/Folder";
import { clearCheckBox, scrollToView } from "../AllFunctions";

import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";
import { Navigate, useNavigate } from "react-router-dom";

const NewExperiment = ({
  sidebarData,
  project_id,
  experiment,
  valueExp,
  hide,
  allProjects,
}) => {
  const [activeState, setActiveState] = useState("Console");
  const [open, setOpen] = useState(true);
  const [fulldata, setFulldata] = useState([]);
  const [initialData, setInitialData] = useState({});
  const [myhubModel, setmyhubModel] = useState(false);
  const [runname, setRunname] = useState("");
  const [runid, setRunid] = useState("");
  const [creatDate, setCreatDate] = useState("");
  const [showDownload, setShowDownload] = useState(false);
  const [folder, setFolder] = useState("");
  const [fullArtifacts, setFullArtifacts] = useState({});
  const [modelReg, setModelReg] = useState([]);
  const [allImages, setAllImages] = useState([]);
  const [allConsole, setAllConsole] = useState([]);
  const [inpSearch, setInpSearch] = useState("");
  const [opti, setOpti] = useState(false);
  const [parameters, setParameters] = useState([]);
  const [metrics, setMetrics] = useState([]);
  const [modelArray, setModelArray] = useState([]);
  const [getModel, setGetModel] = useState({});
  const [postModel, setPostModel] = useState({});
  const toast = useRef(null);
  const [fileDownload, setFileDownload] = useState([]);
  const navigate = useNavigate();

  function handleChange1(e) {
    console.log("ee", e);
    let name = e.target.name;
    let value = e.target.value;
    setPostModel({ ...postModel, [name]: value });
  }

  console.log("project_id------->", project_id);

  // console.log("postModel---", postModel);
  // console.log("getModel---", getModel);

  function handleClose() {
    setmyhubModel(false);
    setPostModel({});
  }
  let token = JSON.parse(localStorage.getItem("mlanglesToken"));

  let Header = { headers: { Authorization: `Bearer ${token}` } };

  useEffect(() => {
    if (experiment === "allExperiment") {
      setFulldata(valueExp);
      setInitialData(valueExp);
    } else {
      fetchData();
    }
    artifactsFn(sidebarData.data.info.run_id);
  }, []);

  async function fetchData() {
    try {
      setOpen(true);
      const res = await axios.get(
        `${backend_url}/tracking/experiment_tracking/${project_id}`,
        Header
      );
      // setOpen(false);
      if (res.data.success) {
        setFulldata(res.data.data);
        setInitialData(res.data.data);
      } else {
        setFulldata([]);
        setInitialData([]);
      }
    } catch (e) {
      console.log("There is an Error--", e);
      // funError(e.message);
    }
  }

  async function mlHubBtn() {
    try {
      console.log(project_id, runid);
      let body = {
        run_id: runid,
        pi: project_id,
      };
      setOpen(true);

      const mlhubGet = await axios.post(
        `${backend_url}/deploy/get_models`,
        body,
        Header
      );
      setmyhubModel(true);

      setOpen(false);
      // console.log("mlhubGet---", mlhubGet);
      if (mlhubGet.data.success) {
        setGetModel(mlhubGet.data.data);
        setModelArray(mlhubGet.data.data.created_models);
      } else {
        setGetModel({});
      }
    } catch (e) {
      console.log("error---", e);
    }
  }

  function boxClicked(data) {
    setActiveState("Console");
    console.log("data----- ", data);
    console.log("allProjects---", allProjects);
    setShowDownload(false);

    let projectId1;
    if (allProjects) {
      for (let i of allProjects) {
        if (i.project_name === data.project_name) {
          projectId1 = i.project_id;

          break;
        }
      }
    }
    artifactsFn(data.data.info.run_uuid, projectId1);
  }

  async function artifactsFn(runId, projectId1) {
    try {
      setOpen(true);
      let r1 = runId ? runId : "";
      const artifactsFile = await axios.post(
        `${backend_url}/tracking/get_mlfow_artifacts/${
          projectId1 ? projectId1 : project_id
        }`,
        { run_id: r1 },
        Header
      );
      console.log("artifactsFile----", artifactsFile);
      setOpen(false);
      if (artifactsFile.data.success) {
        setFullArtifacts(artifactsFile.data);
        let moedlA1 = [];
        console.log("artifactsFile.data.urls---", artifactsFile.data.urls);
        for (let i of artifactsFile.data.urls) {
          moedlA1.push(i.algorithem);
        }
        console.log("moedlA1---", moedlA1);
        setModelReg(moedlA1);

        setAllImages(artifactsFile.data.img_urls[0]);
        setAllConsole(artifactsFile.data.triles);

        // console.log('runId---', runId);
        let a1 = artifactsFile?.data?.data?.runs;
        console.log("a1--------", a1);
        for (let i of a1) {
          if (i?.info?.run_id === runId) {
            console.log("runid-------->", runId, "--------", i?.info?.run_id);
            console.log("i.data-", i.data);
            setMetrics(i.data.metrics);
            setParameters(i.data.params);
            setRunname(i.info.run_name);
            setRunid(i.info.run_id);
            let starttime = new Date(i.info?.start_time).toLocaleString(
              undefined,
              { timeZone: "Asia/Kolkata" }
            );
            setCreatDate(starttime);
          }
        }

        if (artifactsFile.data.model_type === "optimisation") {
          setOpti(true);
          setActiveState("Console");
        } else {
          setOpti(false);
          setActiveState("Parameters");
        }
      }
      scrollToView(runId);
    } catch (e) {
      console.log("error--", e);
      setOpen(false);
    }
  }

  function toggleDownload(data, check) {
    let latest = showDownload;
    setShowDownload(!showDownload);
    setFolder(data);
    if (check === "all") {
      if (!latest) {
        setTimeout(() => {
          const downloadAllonce =
            document.getElementsByClassName("downloadAllonce");
          var urls = [];
          for (let i = 0; i < downloadAllonce.length; i++) {
            downloadAllonce[i].target = "_self";
            console.log(downloadAllonce[i]);
            urls.push(downloadAllonce[i]);
          }
          console.log("urls---", urls);
          let interval = setInterval(repeat, 1000, urls);
          function repeat(urls) {
            var url = urls.pop();
            url.click();
            console.log("url---", url);
            if (urls.length === 0) {
              clearInterval(interval);
              console.log("done");
            }
          }
        }, 1000);
      }
    }
  }

  function stoping(e) {
    console.log("stoping");
    e.stopPropagation();
  }

  async function deleteExperiment() {
    try {
      let deleteArray = [];
      let eachCheck = document.getElementsByClassName("sidecheckbox");

      for (let i of eachCheck) {
        if (i.checked) {
          let d1 = i?.value?.split("$");
          console.log(d1[0], "-----", d1[1]);
          deleteArray.push({ run_name: d1[0], run_id: d1[1] });
          i.checked = false;
        }
      }
      if (deleteArray.length > 0) {
        setOpen(true);
        const deleting = await axios.delete(
          `${backend_url}/tracking/deleteExperimentRun`,
          {
            data: { run_ids: deleteArray },
            headers: { Authorization: `Bearer ${token}` },
          }
        );
        if (deleting.data.success) {
          const res = await axios.get(
            `${backend_url}/tracking/experiment_tracking/${project_id}`,
            Header
          );
          // setOpen(false);
          if (res.data.success) {
            setFulldata(res.data.data);
            setInitialData(res.data.data);
            if (res.data.data.length === 0) {
              hide();
            }
            boxClicked(res.data.data[0]);
          } else {
            setOpen(false);
            setFulldata([]);
            hide();
          }
        }
      }
    } catch (e) {
      console.log("error---", e);
    }
  }

  function serachFn(e) {
    let {
      target: { value },
    } = e;
    console.log("value--", value);
    let newArrray = [];
    setInpSearch(value);
    let n1 = value?.toLowerCase();
    console.log("n1---", n1);
    for (let i of initialData) {
      console.log("i--", i);
      if (i?.data?.info?.run_name.toLowerCase().includes(n1)) {
        newArrray.push(i);
      }
    }
    setFulldata(newArrray);
    if (n1.length === 0) {
      setFulldata(initialData);
    }
  }

  async function modelSubmit(e) {
    try {
      e.preventDefault();
      console.log("postModel--- before---", postModel);
      if (postModel?.inputModel) {
        postModel.model_name = postModel.inputModel;
      } else {
        postModel.model_name = postModel.model_name;
      }
      console.log(
        "postModel---after---",
        postModel,
        "getModel-----",
        getModel,
        "postModel.selected_model---",
        postModel.selected_model
      );

      let finalData = {};
      finalData.model_url = getModel.models[postModel.selected_model]?.urls[0];
      finalData.run_id = runid;
      finalData.pi = project_id;
      finalData.model_name = postModel.model_name;
      finalData.selected_model = postModel.selected_model;

      console.log("finalData--2345----", finalData, getModel);

      setOpen(true);
      setmyhubModel(false);

      const modelPopup = await axios.post(
        `${backend_url}/deploy/create_register_model`,
        finalData,
        Header
      );
      setOpen(false);

      console.log("modelPopup-----", modelPopup);
      if (modelPopup.data.success) {
        console.log("success-----");
        setmyhubModel(false);
        navigate("/Model Hub");
      } else {
        setOpen(false);
      }
    } catch (e) {
      setOpen(false);
      console.log("error-->", e);
      funError(e.message);
    }
  }

  const funError = (data) => {
    toast.current.show({
      severity: "error",
      summary: "Error",
      detail: data,
      life: 2000,
    });
  };

  return (
    <>
      <Loading loading={open} />
      <Toast ref={toast} />

      <div className="fl mt34px">
        <div className="expList">
          <div className="fl alignCenter justifyBetween fixinlist">
            <h4 className="pri">Experiments List</h4>
            <div className="posrel">
              <input
                type="text"
                value={inpSearch}
                placeholder="Search Experiment"
                onChange={(e) => serachFn(e)}
              />
              <i className="srchcn fa-solid fa-magnifying-glass "></i>
            </div>
            <div className="fl tableChange">
              {/* <div
                className="smallbtn"
                onClick={() => {
                  clearCheckBox();
                  setInpSearch("");
                  setFulldata(initialData);
                }}
              >
                <img src={filterIcon} alt="" />
              </div> */}
              <div
                className="smallbtn"
                onClick={() => {
                  hide("sdfvgbn");
                }}
              >
                <img src={tableIcon} alt="" />
              </div>
              <div className="smallbtn" onClick={() => deleteExperiment()}>
                <img src={deleteIcon} className="deleteIcon" alt="" />
                {/* Delete */}
              </div>
              {/* <i className="fa-regular fa-rectangle-list"></i>
                            <i className="fa-solid fa-table-list"></i> */}
            </div>
          </div>
          {fulldata.map((ele, idx) => {
            // console.log('ele--', ele);
            let runName = ele?.data?.info?.run_name;
            let starttime = new Date(
              ele?.data?.info?.start_time
            ).toLocaleString(undefined, { timeZone: "Asia/Kolkata" });
            return (
              <div
                className={`expBox ${
                  ele?.data?.info?.run_name === runname ? "expBoxactive" : ""
                } `}
                key={idx}
                id={ele?.data?.info?.run_id}
                onClick={() => {
                  console.log("box colci");
                  // if (["finished", "success"].includes(
                  //   ele?.data?.info?.status?.toLowerCase()
                  // )) {
                  //   boxClicked(ele);
                  // }
                  if (
                    !["running"].includes(
                      ele?.data?.info?.status?.toLowerCase()
                    )
                  ) {
                    boxClicked(ele);
                  }
                }}
              >
                <div className="w10">
                  <input
                    type="checkbox"
                    className="checkbox sidecheckbox"
                    value={`${ele?.data?.info?.run_name}$${ele?.data?.info?.run_id}`}
                    onClick={(e) => {
                      stoping(e);
                    }}
                  />
                </div>
                <div className="fl dirCol w90">
                  <div>
                    <div className="fl dirRow">
                      <div className="w10">
                        <img src={projectIcon} alt="" />
                      </div>
                      <h4 className="pri w68">Exp Name: {runName} </h4>
                      {["finished", "success"].includes(
                        ele?.data?.info?.status?.toLowerCase()
                      ) ? (
                        <div className="fl statusexp w20 end">
                          <img src={successIcon} alt="successIcon" />
                          <p className={`successGreen`}>Success</p>
                        </div>
                      ) : ["failed", "failure", "unfinished"].includes(
                          ele?.data?.info?.status?.toLowerCase()
                        ) ? (
                        <div className="fl statusexp w20 end">
                          <img src={failIcon} alt="failIcon" />
                          <p className={`failRed`}>Failure</p>
                        </div>
                      ) : (
                        <div className="fl statusexp w20 end">
                          <img src={runIcon} alt="failIcon" />
                          <p className={`runblue`}>Running</p>
                        </div>
                      )}
                    </div>
                    <p className={`sec conentExp`}>{ele?.data?.info?.run_id}</p>
                  </div>
                  <div className="fl dirRow">
                    <p className="ter creatBy w50 strt">
                      Created by {ele?.created_by}
                    </p>

                    <p className="ter creatBy w50 end">{starttime}</p>
                  </div>
                </div>
              </div>
            );
          })}
        </div>

        <Loading loading={open} />

        <div className="w75">
          <div className="expTable">
            <div className="stickyHead">
              <div className="tabhead">
                <h4>
                  <i className="fa-solid fa-person-running"></i> Run Name:{" "}
                  {runname}
                </h4>
                <h4>
                  <i className="fa-regular fa-id-card"></i>Run ID: {runid}
                </h4>
                <h4>
                  <i className="fa-solid fa-calendar-days"></i>Created AT:{" "}
                  {creatDate}
                </h4>
              </div>
              <div className="inHead">
                {opti ? (
                  <>
                    <h5
                      className={activeState === "Console" ? "currentBlue" : ""}
                      onClick={() => setActiveState("Console")}
                    >
                      Console
                    </h5>
                    <h5
                      className={
                        activeState === "Visualization" ? "currentBlue" : ""
                      }
                      onClick={() => setActiveState("Visualization")}
                    >
                      Visualization
                    </h5>
                  </>
                ) : (
                  <></>
                )}

                <h5
                  className={activeState === "Parameters" ? "currentBlue" : ""}
                  onClick={() => setActiveState("Parameters")}
                >
                  Parameters
                </h5>
                <h5
                  className={activeState === "Metrics" ? "currentBlue" : ""}
                  onClick={() => setActiveState("Metrics")}
                >
                  Metrics
                </h5>
                <h5
                  className={activeState === "Artifacts" ? "currentBlue" : ""}
                  onClick={() => setActiveState("Artifacts")}
                >
                  Artifacts
                </h5>
              </div>
            </div>

            {activeState === "Console" ? (
              <div className="pad24">
                <div className="consolefull">
                  {allConsole?.map((item, idx) => {
                    return (
                      <div className="consoleEach" key={idx}>
                        {JSON.stringify(item)}
                      </div>
                    );
                  })}
                </div>
              </div>
            ) : (
              <></>
            )}

            {activeState === "Visualization" ? (
              <div className="visufull">
                {allImages?.map((item, idx) => {
                  return (
                    <img
                      src={item}
                      alt={`display ${idx}`}
                      key={idx}
                      className="visuImg"
                    />
                  );
                })}
              </div>
            ) : (
              <></>
            )}

            {activeState === "Artifacts" ? (
              <>
                <div className="eachhh1">
                  <div className="d-flex w70">
                    <div className="d-flex flex-column wie50 align-items-start">
                      <table className="fullfolder">
                        <tbody>
                          {fullArtifacts?.urls?.map((ele, idx) => {
                            let folderName = ele.algorithem
                              .replace("(", "")
                              .replace(")", "");
                            // console.log("folderName----", folderName);
                            return (
                              <tr key={idx}>
                                <td>
                                  <FolderIcon />
                                </td>
                                <td className="cursor">
                                  <span
                                    onClick={() => {
                                      toggleDownload(folderName);
                                    }}
                                  >
                                    {folderName}
                                  </span>
                                </td>
                                <td
                                  className="cursor"
                                  onClick={() => {
                                    toggleDownload(folderName, "all");
                                  }}
                                >
                                  <img
                                    src={folderDown}
                                    alt=""
                                    className="cursorShow"
                                    onClick={() => {
                                      toggleDownload(folderName, "all");
                                    }}
                                  />
                                </td>
                              </tr>
                            );
                          })}
                        </tbody>
                      </table>
                    </div>

                    <div className="d-flex flex-column wie50 align-items-start">
                      {fullArtifacts?.urls?.map((ele, idx) => {
                        let folderName = ele.algorithem
                          .replace("(", "")
                          .replace(")", "");
                        if (folder === folderName) {
                          return (
                            <div
                              key={idx}
                              className={
                                showDownload
                                  ? "visBlock d-flex flex-col"
                                  : "visNone d-flex flex-col"
                              }
                            >
                              <table className="subfile">
                                <tbody>
                                  {ele?.urls[0] && (
                                    <tr>
                                      <td>
                                        <img src={subfile} />
                                      </td>
                                      <td>
                                        <span>ML model</span>
                                      </td>
                                      <td className="cursor downloadAllfile">
                                        <a
                                          download="one"
                                          href={ele?.urls[0]}
                                          rel="noreferrer"
                                          className="downloadAllonce"
                                        >
                                          <DownloadIcon />
                                        </a>
                                      </td>
                                    </tr>
                                  )}

                                  {ele?.urls[1] && (
                                    <tr>
                                      <td>
                                        <img src={subfile} />
                                      </td>
                                      <td>
                                        <span>Conda.yaml</span>
                                      </td>
                                      <td className="cursor downloadAllfile">
                                        <a
                                          download="two"
                                          href={ele?.urls[1]}
                                          rel="noreferrer"
                                          className="downloadAllonce"
                                        >
                                          <DownloadIcon />
                                        </a>
                                      </td>
                                    </tr>
                                  )}

                                  {ele?.urls[2] && (
                                    <tr>
                                      <td>
                                        <img src={subfile} />
                                      </td>
                                      <td>
                                        <span>model.pkl</span>
                                      </td>
                                      <td className="cursor downloadAllfile">
                                        <a
                                          download="three"
                                          href={ele?.urls[2]}
                                          rel="noreferrer"
                                          className="downloadAllonce"
                                        >
                                          <DownloadIcon />
                                        </a>
                                      </td>
                                    </tr>
                                  )}

                                  {ele?.urls[3] && (
                                    <tr>
                                      <td>
                                        <img src={subfile} />
                                      </td>
                                      <td>
                                        <span>python_env.yaml</span>
                                      </td>
                                      <td className="cursor downloadAllfile">
                                        <a
                                          download="four"
                                          href={ele?.urls[3]}
                                          rel="noreferrer"
                                          className="downloadAllonce"
                                        >
                                          <DownloadIcon />
                                        </a>
                                      </td>
                                    </tr>
                                  )}
                                </tbody>
                              </table>
                            </div>
                          );
                        }
                      })}
                    </div>
                  </div>
                  <div className="d-flex w30">
                    <button
                      className="mybtn"
                      onClick={() => {
                        mlHubBtn();
                      }}
                    >
                      Model Hub
                    </button>
                  </div>
                </div>
              </>
            ) : (
              <></>
            )}

            {activeState === "Metrics" && (
              <div className="eachhh">
                <table className="metrics">
                  <thead>
                    <tr className="trrun">
                      <th>NAME</th>
                      <th>VALUE</th>
                    </tr>
                  </thead>
                  <tbody>
                    {metrics?.map((ele, idx) => {
                      return (
                        <tr key={idx}>
                          <td>{ele?.key}</td>
                          <td>{ele?.value}</td>
                        </tr>
                      );
                    })}
                  </tbody>
                </table>
              </div>
            )}

            {activeState === "Parameters" && (
              <div className="eachhh">
                <table className="metrics">
                  <thead>
                    <tr className="trrun">
                      <th>NAME</th>
                      <th>VALUE</th>
                    </tr>
                  </thead>
                  <tbody>
                    {parameters?.map((ele, idx) => {
                      return (
                        <tr key={idx}>
                          <td>{ele?.key}</td>
                          <td>{ele?.value}</td>
                        </tr>
                      );
                    })}
                  </tbody>
                </table>
              </div>
            )}
          </div>
        </div>
      </div>

      <Modal
        open={myhubModel}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        className="center"
      >
        <div className="box">
          <div className="hdr">Register Model</div>
          <form onSubmit={modelSubmit}>
            <div>
              <FormControl fullWidth>
                <InputLabel id="demo-simple-select-label">
                  Select Algorithm
                </InputLabel>
                <Select
                  className={"modelselect inpt"}
                  name="selected_model"
                  value={postModel?.selected_model}
                  onChange={handleChange1}
                  required
                >
                  {modelReg?.map((item, idx) => {
                    return (
                      <MenuItem key={idx} value={item} className="opt">
                        {item}
                      </MenuItem>
                    );
                  })}
                </Select>
              </FormControl>
              <FormControl fullWidth>
                <InputLabel id="demo-simple-select-label">
                  Select Model
                </InputLabel>
                <Select
                  className={"modelselect inpt"}
                  name="model_name"
                  value={postModel?.model_name}
                  onChange={handleChange1}
                  required
                >
                  <MenuItem value="new_model" className="create">
                    + Create New Model
                  </MenuItem>
                  {modelArray?.map((item, idx) => {
                    return (
                      <MenuItem key={idx} value={item} className="opt">
                        {item}
                      </MenuItem>
                    );
                  })}
                </Select>
              </FormControl>
              {postModel?.model_name === "new_model" && (
                <input
                  className={"inpt1"}
                  type="text"
                  name="inputModel"
                  autoComplete="off"
                  placeholder="Model Name"
                  value={postModel?.inputModel}
                  onChange={handleChange1}
                  required
                />
              )}

              <div className="prblkbtn">
                <button className="blkbtn">Register</button>
                <button onClick={handleClose} className="blkbtn">
                  Cancel
                </button>
              </div>
            </div>
          </form>
        </div>
      </Modal>
    </>
  );
};

export default NewExperiment;
