import React, { useContext, useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import Mlsidebar from "../../Components/Mlsidebar";
import mlimage from "../../logo/mlangles360logo.svg";
import axios from "axios";
import { Dropdown } from "primereact";
import successIcon from "../../logo/successIcon.png";
import Loading from "../../Components/Loading";

import filter from "../../logo/filter.png";
import Superuser from "../../Components/Superuser";
import deleteIcon from "../../logo/deleteIcon.png";
import filterIcon from "../../logo/clear_filter.png";
import { backend_url, backend_url1 } from "../../Config";
import Logout from "../Logout";
import { Dialog } from "primereact/dialog";
import Folder from "./Folder";
import { modelHubData } from "../context/context";
const MLHub = () => {
  let path = "Model Hub";

  const [logout, setLogout] = useState(false);
  const [deploymodel, setDeploymodel] = useState(false);
  const [open, setOpen] = useState(false);
  const [fullData, setFullData] = useState([]);
  const [selectedML, setSelectedML] = useState({});
  const [toggleCheck, setToggleCheck] = useState(false);
  const [checkData, setCheckData] = useState({});
  const [showDelete, setShowDelete] = useState(false);
  const [userDetails, setUserDetails] = useState({});

  let token = JSON.parse(localStorage.getItem("mlanglesToken"));
  let Header = { headers: { Authorization: `Bearer ${token}` } };

  useEffect(() => {
    let userdetails = JSON.parse(localStorage.getItem("userDetails"));
    setUserDetails(userdetails);
    fetchdata();
  }, []);

  useEffect(() => {
    firstCall(checkData);
  }, [selectedML]);

  function firstCall(checkData) {
    let k1 = Object.keys(checkData);
    let k2 = Object.keys(selectedML);
    // console.log(k1, k2);
    if (k1.length === k2.length) {
      if (JSON.stringify(checkData) === JSON.stringify(selectedML)) {
        setToggleCheck(true);
      } else {
        setToggleCheck(false);
      }
    } else {
      setToggleCheck(false);
    }
    if (Object.keys(selectedML).length > 0) {
      setShowDelete(true);
    } else {
      setShowDelete(false);
    }
  }

  async function fetchdata() {
    try {
      setOpen(true);
      const allData = await axios.get(
        `${backend_url}/deploy/list_models_rmodels`,
        Header
      );
      setOpen(false);
      // console.log("allData---->", allData.data.data);
      if (allData.data.success) {
        setFullData(allData.data.data);

        let o1 = allData.data.data;
        let newData = {};
        for (let i of o1) {
          let algo = [];
          for (let j of i.data) {
            // algo.push(j.model);
            // algo.push(j.verison);
            algo.push({ model: j.model, version: j.verison });
          }
          newData[i.project_name] = algo;
        }
        setCheckData(newData);
        firstCall(newData);
      } else {
        setFullData([]);
      }
    } catch (e) {
      console.log("error--,e");
    }
  }
  console.log("checkData-------->", checkData);

  function changecheckBoxParent(e) {
    let checkboxparent = document.getElementsByClassName("checkboxparent")[0];
    let eachCheck = document.getElementsByClassName("eachcheckbox");

    if (checkboxparent.checked) {
      for (let i = 0; i < eachCheck.length; i++) {
        eachCheck[i].checked = true;
      }
    } else {
      for (let i = 0; i < eachCheck.length; i++) {
        eachCheck[i].checked = false;
      }
    }
  }

  function checkBoxEach(e) {
    let checkboxparent = document.getElementsByClassName("checkboxparent")[0];
    let eachCheck = document.getElementsByClassName("eachcheckbox");
    for (let i of eachCheck) {
      if (!i.checked) {
        checkboxparent.checked = false;
      }
    }
  }

  function parentChanging(e) {
    e.stopPropagation();

    // console.log("fulldata-----", fullData);

    // setToggleCheck(!toggleCheck);
    // console.log("toggleCheck-----", toggleCheck);

    if (!toggleCheck) {
      setToggleCheck(true);
    }

    if (toggleCheck) {
      setSelectedML({});
    } else {
      let newData = {};
      for (let i of fullData) {
        let algo = [];
        for (let j of i.data) {
          // algo.push(j.model);
          algo.push({ model: j.model, version: j.verison });
        }
        newData[i.project_name] = algo;
      }
      // console.log(newData);
      setSelectedML(newData);
    }
  }

  const mlHub = useContext(modelHubData);

  async function deleteModelHub(e) {
    console.log("ddleete", selectedML);
    let deleteData = [];
    console.log("i----", selectedML);
    for (let i of Object.keys(selectedML)) {
      let o1 = {};
      o1.modelName = i;
      o1.algo = [];
      o1.version = [];
      for (let j of selectedML[i]) {
        console.log("j-----", j);
        o1.algo.push(j?.model);
        o1.version.push(j?.version);
      }
      deleteData.push(o1);
    }
    // console.log('fullData------>', fullData);
    console.log("deleteData----->", deleteData);
    const deleteDataAPI = await axios.delete(
      `${backend_url}/deploy/deleteAlgorithm`,
      {
        headers: { Authorization: `Bearer ${token}` },
        data: deleteData,
      }
    );
    console.log("deleteDataAPI------", deleteDataAPI);
    // if(deleteDataAPI)
  }

  console.log("selectedML---", selectedML);
  // console.log("mlhub------", mlHub);

  // console.log("fullDatataa----", fullData);

  return (
    <>
      <modelHubData.Provider
        value={[selectedML, setSelectedML, fullData, checkData]}
      >
        <div className="allpipeline">
          <Loading loading={open} />

          <header>
            <div className="d-flex align-items-center justify-content-between">
              <div className="d-flex align-items-center">
                <div className="logohover">
                  <div className="newlogo">
                    <img src={mlimage} className="newlogos" alt="" />
                  </div>
                </div>
                <h4 className="capitailze">
                  {" "}
                  {userDetails?.user_full_name} workspace /{" "}
                </h4>
                <span> Model Hub</span>
              </div>
              <Superuser
                logoutClicked={() => {
                  setLogout(true);
                }}
              />
            </div>
          </header>

          <Dialog
            className="logout-dialogbox"
            visible={logout}
            style={{ width: "40vw" }}
            onHide={() => setLogout(false)}
          >
            <Logout no={() => setLogout(false)} />
          </Dialog>

          <Mlsidebar data={path} />

          <section className="middlepart1 allprojects dashboard allpipelines">
            <h2>Model Hub</h2>
            <div className="d-flex align-items-center justify-content-center">
              <h4 className="error-message">error</h4>
            </div>
            <div className="hdrnew">
              <div className="d-flex">
                <div className="commonbtn">
                  <img src={filterIcon} alt="" /> <span>Clear Filter</span>
                </div>
                {showDelete ? (
                  <div onClick={deleteModelHub} className="commonbtn">
                    <img src={deleteIcon} alt="" /> <span>Delete</span>
                  </div>
                ) : (
                  <div style={{ opacity: 0.5 }} className="commonbtn">
                    <img src={deleteIcon} alt="" /> <span>Delete</span>
                  </div>
                )}
              </div>
            </div>
            <div className="fixedsize">
              <table className="sample" style={{ width: "100%" }}>
                <thead className="fixing">
                  <tr className="darkblue">
                    <th className="posrel">
                      <input
                        type="checkbox"
                        className="posrel allpipecheckbox checkboxparent"
                        // onChange={changecheckBoxParent}
                        onChange={parentChanging}
                        checked={toggleCheck}
                      />
                      Model Name
                    </th>

                    <th className="tbhead" scope="col">
                      Created by
                    </th>
                    {/* <th className={styles.tbhead} scope="col">
                Duration
              </th> */}
                    <th className="tbhead" scope="col">
                      Created at
                    </th>
                    <th className="tbhead" scope="col">
                      Version
                    </th>
                    <th className="tbhead" scope="col">
                      Status
                    </th>
                    <th className="tbhead" scope="col"></th>
                  </tr>
                </thead>
                <tbody>
                  {fullData?.map((item, idx) => {
                    // console.log("item---", item);
                    return (
                      <>
                        <Folder key={idx} item={item} />
                      </>
                    );
                  })}
                </tbody>
              </table>
            </div>
          </section>
        </div>
      </modelHubData.Provider>
    </>
  );
};

export default MLHub;
