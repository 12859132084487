import { FormControl, InputLabel, MenuItem, Select } from "@mui/material";
import { useEffect, useState } from "react";

const TabularLayers = ({ tabularLayers, changingInputLayersValueForTABULAR, index, tabularType, tabularlayerChanging, tabularlayerValueChanging }) => {

    const [layer, setLayer] = useState("");
    const [layerType, setLayerType] = useState("");

    function layerChange(e) {
        const { value } = e.target;
        setLayer(value);
        tabularlayerChanging({ index: index, layerType: layerType, layer: value })
    }

    function layersTypingFun(data) {
        tabularType({ index: index, value: data });
        setLayerType(data);
        setLayer("");
    }

    function tablurValueChangingFun(data) {
        let { key, value } = data;
        tabularlayerValueChanging({ index: index, layerType: layerType, layer: layer, key: key, value: value })
        // changingInputLayersValueForTABULAR({ index: index, layerType: layerType, layer: layer, key: key, value: value })
    }

    return (
        <div className="layerFullData run-checkbox">
            <div className="each-check">
                <label htmlFor="">Layer Type</label>
                <FormControl fullWidth>
                    <InputLabel id="demo-simple-select-label">
                        Select Layer Type
                    </InputLabel>
                    <Select
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        className="demo-simple-select"
                        value={layerType}
                        label="Data Version"
                        onChange={(e) => { layersTypingFun(e.target.value) }}
                    >
                        {
                            Object.keys(tabularLayers)?.map((data, idx) => {
                                return (
                                    <MenuItem key={idx} value={data}>
                                        {data}
                                    </MenuItem>
                                );
                            })}
                    </Select>
                    {/* <span className="fieldError">{fieldErr.dataVersion}</span> */}
                </FormControl>
            </div>
            {
                layerType &&
                <div className="each-check">
                    <label htmlFor="">Layer</label>
                    <FormControl fullWidth>
                        <InputLabel id="demo-simple-select-label">
                            Select Layer
                        </InputLabel>
                        <Select
                            labelId="demo-simple-select-label"
                            id="demo-simple-select"
                            className="demo-simple-select"
                            value={layer}
                            label="Data Version"
                            onChange={layerChange}
                        >
                            {
                                Object.keys(tabularLayers.input)?.map((data, idx) => {
                                    return (
                                        <MenuItem key={idx} value={data}>
                                            {data}
                                        </MenuItem>
                                    );
                                })}
                        </Select>
                        {/* <span className="fieldError">{fieldErr.dataVersion}</span> */}
                    </FormControl>
                </div>
            }

            {(layerType && layer) && <>
                {
                    Object.keys(tabularLayers[layerType][layer]).map((item, idx) => {
                        if (tabularLayers[layerType][layer][item]?.options?.length === 0) {
                            return (
                                <div key={idx+layer} className="each-check">
                                    <label htmlFor="">{item}</label>
                                    <input
                                        className="brightColor"
                                        autoComplete="off"
                                        type="text"
                                        name={item}
                                        placeholder="Enter Output features"
                                        // value={tabularLayers[layerType][layer][item]?.value === "null" ? "" : tabularLayers[layerType][layer][item]?.value}
                                        onChange={(e) => { tablurValueChangingFun({ key: item, value: e.target.value }) }}
                                    />
                                </div>
                            )
                        } else {
                            return (
                                <div key={idx+layer} className="each-check">
                                    <label htmlFor="">{item}</label>
                                    <FormControl fullWidth>
                                        <Select
                                            labelId="demo-simple-select-label"
                                            id="demo-simple-select"
                                            className="demo-simple-select"
                                            // value={tabularLayers[layerType][layer][item]?.value === "null" ? "" : tabularLayers[layerType][layer][item]?.value}
                                            onChange={(e) => { tablurValueChangingFun({ key: item, value: e.target.value }) }}                                        >
                                            {
                                                tabularLayers[layerType][layer][item]?.options?.map((data, idx) => {
                                                    return (
                                                        <MenuItem key={idx} value={data}>
                                                            {data}
                                                        </MenuItem>
                                                    );
                                                })}
                                        </Select>
                                    </FormControl>
                                </div>
                            );
                        }
                    })
                }
            </>

            }

        </div>
    )
}

export default TabularLayers;
