import {
  Drawer,
  FormControl,
  InputLabel,
  Modal,
  Select,
  MenuItem,
} from "@mui/material";
import React, { useContext, useEffect, useState } from "react";
import axios from "axios";
import { backend_url } from "../../Config";
import Loading from "../../Components/Loading";
import { Toast } from "primereact/toast";
import { useRef } from "react";
import close from "../../../src/logo/close.png";
import { modelHubData } from "../context/context";
import styles from "./mlhub.module.css";

const FileRow = ({ item, model_name }) => {
  const [draw, setDraw] = useState(false);
  const [open, setOpen] = useState(false);
  const [deploymodel, setDeploymodel] = useState(false);
  const [showstage, setShowstage] = useState(false);
  const [api, setApi] = useState({ api_endpoint: "", api_key: "" });
  const [deploy_data, setDeploy_data] = useState({
    model_name: model_name,
    selected_model: item.model,
    instance: "",
    version: item?.verison,
  });
  const [checkSelected, setCheckSelected] = useState(false);

  const toast = useRef(null);

  const [stagData, setStagData] = useState({});

  let token = JSON.parse(localStorage.getItem("mlanglesToken"));
  let Header = { headers: { Authorization: `Bearer ${token}` } };

  const [selectedML, setSelectedML, fullData, checkData] =
    useContext(modelHubData);

  // console.log("selectedML, setSelectedML, fullData------->", selectedML, item, fullData);

  useEffect(() => {
    if (item?.api_endpoint) {
      let a1 = {};
      a1["api_endpoint"] = item?.api_endpoint;
      a1["api_key"] = item?.api_key;
      setApi(a1);
      setShowstage(true);
    }
  }, [item]);

  console.log("item---------->", item);
  useEffect(() => {
    // console.log("useEffect  selectedML");
    let k1 = Object.keys(selectedML);
    if (k1.length > 0) {
      let arr1 = [];
      let isThere = [];
      if (selectedML?.[item?.project_name]) {
        for (let i of selectedML?.[item?.project_name]) {
          arr1.push({ model: i.model, version: i.verison });
          isThere.push(i.model);
        }
      }
      if (isThere?.includes(item?.model)) {
        setCheckSelected(true);
      } else {
        setCheckSelected(false);
      }
    } else {
      setCheckSelected(false);
    }
  }, [selectedML]);

  async function removeDeploy(e) {
    try {
      e.preventDefault();
      let stagData = JSON.parse(JSON.stringify(deploy_data));
      stagData.instance = "t2.xlarge";
      // console.log('stagData--', stagData);
      setOpen(true);
      const removeData = await axios.delete(
        `${backend_url}/deploy/move_to_staging`,
        { headers: { Authorization: `Bearer ${token}` }, data: stagData }
      );
      setOpen(false);
      // console.log("removeData---->", removeData);
      if (removeData.data.success) {
        window.location.reload();
      } else {
        showError(removeData?.data?.message);
      }
    } catch (e) {
      // console.log(e);
      showError(e?.message);
    }
  }

  async function getAPIKEY() {
    try {
      setOpen(true);
      let d1 = {
        model_name: model_name,
        selected_model: item.model,
        version: item?.verison,
      };
      const allData = await axios.post(
        `${backend_url}/deploy/list_models_rmodels`,
        d1,
        Header
      );
      setOpen(false);
      // console.log("allData---", allData);
      if (allData.data.success) {
        let a1 = {};
        a1["api_endpoint"] = allData.data.data.api_endpoint;
        a1["api_key"] = allData.data.data.api_key;
        setApi(a1);
      } else {
        showError(allData?.data?.message);
      }
    } catch (e) {
      // console.log(e);
      showError(e?.message);
    }
  }

  async function post_deploy(e) {
    try {
      e.preventDefault();
      setDeploymodel(false);
      let d1 = JSON.parse(JSON.stringify(deploy_data));
      setStagData(d1);
      setOpen(true);
      const allData = await axios.post(
        `${backend_url}/deploy/deploy_the_model`,
        deploy_data,
        Header
      );
      setOpen(false);
      // console.log("alldata---->", allData);
      if (allData.data.success) {
        setDeploymodel(false);
        setDeploy_data({ ...deploy_data, ["instance"]: "" });
        // removeDeploy();
        getAPIKEY();
        setShowstage(true);
      } else {
        showError(allData?.data?.message);
      }
      // setDeploy_data({ ...deploy_data, ["instance"]: "" });
    } catch (e) {
      // console.log(e);
      showError(e?.message);
    }
  }

  function checkBoxEach(e) {
    // e.stopPropagation();
    let checkboxparent = document.getElementsByClassName("checkboxparent")[0];
    let eachCheck = document.getElementsByClassName("eachcheckbox");
    for (let i of eachCheck) {
      if (!i.checked) {
        checkboxparent.checked = false;
      }
    }
  }

  const showError = (data) => {
    setOpen(false);
    toast.current.show({
      severity: "error",
      summary: "Error",
      detail: data,
      life: 2000,
    });
  };

  function childRowCheckbox() {
    let copyData = JSON.parse(JSON.stringify(selectedML));
    // console.log("item?.project_name----", item?.project_name);

    let arr1 = [];
    if (selectedML?.[item?.project_name]) {
      for (let i of selectedML?.[item?.project_name]) {
        arr1.push(i.model);
      }
    }

    if (arr1?.includes(item.model)) {
      // console.log("there");
      // console.log(copyData?.[item?.project_name]);
      let count = 0;
      for (let i of copyData?.[item?.project_name]) {
        if (i?.model === item?.model) {
          break;
        }
        count += 1;
      }
      console.log(count);
      // delete copyData?.[item?.project_name]?.[count];
      copyData?.[item?.project_name].splice(count, 1);
      if (copyData?.[item?.project_name].length === 0) {
        delete copyData?.[item?.project_name];
      }
      console.log("copyData----", copyData);
    } else {
      console.log(
        "copyData?.[item?.project_name]-------",
        copyData?.[item?.project_name]
      );
      console.log(
        "checkData?.[item?.project_name]-------",
        checkData?.[item?.project_name]
      );
      console.log("item?.model-----", item?.model);
      if (copyData?.[item?.project_name]) {
        let s123 = copyData?.[item?.project_name];
        console.log("s123-----", s123);
        for (let i of checkData?.[item?.project_name]) {
          console.log("eachi---", i);
          if (i?.model === item?.model) {
            s123.push(i);
            console.log("inside i---", i);
          }
        }
        console.log("s123----", s123);
      } else {
        for (let i of checkData?.[item?.project_name]) {
          console.log(i);
          if (i?.model === item?.model) {
            copyData[item?.project_name] = [i];
          }
        }
      }
    }
    setSelectedML(copyData);
  }

  return (
    <>
      <Loading loading={open} />
      <Toast ref={toast} />

      <tr>
        <td className="r1" scope="row">
          <input
            type="checkbox"
            className="eachcheckbox"
            // onChange={checkBoxEach}
            onChange={childRowCheckbox}
            checked={checkSelected}
          />
          <span>{item.model}</span>
        </td>
        <td className="rd">{item.created_by}</td>
        <td className="rd">{item.created_at}</td>
        <td className="rd">{item.verison}</td>
        <td className="rd">{item.status}</td>
        <td className="rd point" onClick={() => setDraw(true)}>
          View Details
        </td>
      </tr>

      <Drawer
        anchor="right"
        open={draw}
        onClose={() => setDraw(false)}
        className="mt60"
      >
        <div className="f1">
          <div className="api">API Details</div>
          <i onClick={() => setDraw(false)}>
            <img src={close} alt="" className="close" />
            {/* <svg
              xmlns="http://www.w3.org/2000/svg"
              width="13"
              height="11"
              viewBox="0 0 13 11"
              fill="none"
            >
              <path
                d="M12.3907 9.65695C12.547 9.80449 12.6348 10.0046 12.6348 10.2133C12.6348 10.4219 12.547 10.622 12.3907 10.7696C12.2345 10.9171 12.0225 11 11.8015 11C11.5805 11 11.3686 10.9171 11.2123 10.7696L6.81126 6.61295L2.40881 10.7683C2.25254 10.9158 2.0406 10.9987 1.8196 10.9987C1.5986 10.9987 1.38666 10.9158 1.23039 10.7683C1.07412 10.6207 0.986328 10.4206 0.986328 10.2119C0.986328 10.0033 1.07412 9.80318 1.23039 9.65564L5.63283 5.50033L1.23177 1.34371C1.07551 1.19616 0.987715 0.996053 0.987715 0.787396C0.987715 0.578739 1.07551 0.378628 1.23177 0.231086C1.38804 0.0835431 1.59999 0.000654375 1.82098 0.000654373C2.04198 0.000654371 2.25393 0.0835431 2.4102 0.231086L6.81126 4.38771L11.2137 0.230431C11.37 0.0828885 11.5819 -3.47622e-09 11.8029 0C12.0239 3.47622e-09 12.2359 0.0828885 12.3921 0.230431C12.5484 0.377974 12.6362 0.578085 12.6362 0.786742C12.6362 0.995398 12.5484 1.19551 12.3921 1.34305L7.98968 5.50033L12.3907 9.65695Z"
                fill="#6A6E79"
                fill-opacity="0.53"
              />
            </svg> */}
          </i>
        </div>
        <div className="f">
          <div className="f2">
            <div className="f2white">Algorithm Name</div>
            <div className="f2gray">{item.model}</div>
          </div>
          <div className="f2">
            <div className="f2white">Version</div>
            <div className="f2gray">{item.verison}</div>
          </div>
          <div className="f2">
            <div className="f2white">Created by</div>
            <div className="f2gray">{item.created_by}</div>
          </div>
          <div className="f2">
            <div className="f2white">Created at</div>
            <div className="f2gray">{item.created_at}</div>
          </div>
          <div className={styles.tabel}>
            <div className={styles.metricss}>Metrics :</div>
            {/* <table>
              <thead className={styles.headcolor}>
                <tr className={styles.trr}>
                  <th className={styles.thh}>Key</th>
                  <th className={styles.thh}>Value</th>
                </tr>
              </thead>
              <tbody className={styles.bodycolor}>
                <tr className={styles.tablemetrics}>
                  <td className={styles.tdd}>ihbjytfugihjopk</td>
                  <td>iuhoj</td>
                </tr>
              </tbody>
            </table> */}
            <table className="sample" style={{ width: "max-content" }}>
              <thead className="fixing darkblue">
                <tr>
                  <th className="posrel"> Key</th>
                  <th className="posrel">STATUS</th>
                </tr>
              </thead>

              <tbody>
                {item.metric.map((metric, index) => (
                  <tr key={index}>
                    <td className={styles.datacolor}>{metric.key}</td>
                    <td className={styles.datacolor}>{metric.value}</td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
        {/* <div className="descript">Description</div>
        <div className="desbox">
          In publishing and graphic design, Lorem ipsum is a placeholder text
          commonly used to the form demonstrate the visual form of a document or
          a typeface without relying on meaningful ful content. Lorem ipsum may
          be used as a placeholder before final copy is available. It is also
          used to temporarily replace text in a process called greeking, which
          allows designers to consider the form of a webpage or publication,
          without the meaning of the text influencing the design,which allows
          designers to consider the form of a webpage or publication, without
          the meaning of the text influencing the design Lorem ipsum may be used
          as a placeholder before final copy is available.
        </div> */}

        {showstage ? (
          <></>
        ) : (
          <div>
            <button className="deploybtn" onClick={() => setDeploymodel(true)}>
              Deploy
            </button>
          </div>
        )}

        {showstage && (
          <>
            <div className="f3main">
              <div className="f3">
                <div className="f3white">API</div>
                <div className="f3box">{api?.api_endpoint}</div>
              </div>
              <div className="f3">
                <div className="f3white">API Key</div>
                <div className="f3box">{api?.api_key}</div>
              </div>
            </div>
            <div className="f3_new">Total Request : 98</div>
            <div>
              <button className="movebtn" onClick={removeDeploy}>
                Move to staging
              </button>
            </div>
          </>
        )}
      </Drawer>

      <Modal
        open={deploymodel}
        onClose={() => setDeploymodel(false)}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        className="center"
      >
        <div className="box">
          <div className="hdr">Compute Power</div>
          <form onSubmit={post_deploy}>
            <div>
              <FormControl fullWidth>
                <InputLabel id="demo-simple-select-label">
                  Select Compute Power
                </InputLabel>
                <Select
                  className={"inpt"}
                  name="instance"
                  value={deploy_data?.instance}
                  required
                  onChange={(e) =>
                    setDeploy_data({
                      ...deploy_data,
                      [e.target.name]: e.target.value,
                    })
                  }
                >
                  {["t2.xlarge"]?.map((value, id) => {
                    return (
                      <MenuItem value={value} key={id} className="opt">
                        {value}
                      </MenuItem>
                    );
                  })}
                </Select>
              </FormControl>

              <div className="prblkbtn">
                <button className="blkbtn">Deploy</button>
                <button
                  onClick={() => setDeploymodel(false)}
                  className="blkbtn"
                >
                  Cancel
                </button>
              </div>
            </div>
          </form>
        </div>
      </Modal>
    </>
  );
};

export default FileRow;
