import React, { useEffect, useRef, useState } from 'react'
import { Toast } from "primereact/toast";
import Mlsidebar from '../../Components/Mlsidebar'
import mllogo from "../../logo/mlangles360logo.svg"
import mlimage from "../../logo/mlangles360logo.svg";
import { Link, useNavigate, useParams } from 'react-router-dom'
import profilePic from "../../logo/profile-pic.png"
import { TabView, TabPanel } from 'primereact/tabview';
import PipeslineBox from '../../Components/PipeslineBox'
import file from "../../logo/file.png"
import deleteIcon from "../../logo/deleteIcon.png"
import plus from "../../logo/plus.png"
import { Dropdown } from "primereact";
import { FormControl, InputLabel, MenuItem, Select } from '@mui/material'
import axios from 'axios'
import Loading from '../../Components/Loading'
import Superuser from '../../Components/Superuser';
import { backend_url, backend_url1 } from '../../Config'
import Logout from '../Logout';
import { Dialog } from 'primereact/dialog';
import { changecheckBoxParent, checkBoxEach } from '../AllFunctions';

const BatchServe = () => {

  let path = "serving";
  const navigate = useNavigate();
  const [userDetails, setUserDetails] = useState({});
  const [logout, setLogout] = useState(false);

  const [open, setOpen] = useState(false);
  const [tableData, setTableData] = useState([]);
  const toast = useRef(null);

  let token = JSON.parse(localStorage.getItem("mlanglesToken"));
  let Header = { headers: { "Authorization": `Bearer ${token}` } };

  useEffect(() => {
    let token = JSON.parse(localStorage.getItem("mlanglesToken"));
    let userdetails = JSON.parse(localStorage.getItem("userDetails"));
    setUserDetails(userdetails);

    if (userdetails) {
      if (!token || userDetails?.permissions?.is_serve === 0) {
        navigate("/");
      }
    }
    fetchdata();
  }, [])

  // function errFunction() {
  //   window.scrollTo(0, 0);
  //   setTimeout(() => {
  //     setErr("");
  //   }, 1000);
  // }

  async function fetchdata() {
    try {
      setOpen(true);
      const res = await axios.get(`${backend_url}/configuration/batch_prediction_table`, Header);
      setOpen(false);
      if (res.data.success) {
        setTableData(res?.data?.input);
      } else {
        funError(res.data.message);

      }
    }
    catch (e) {
      funError(e);

    }
  }

  function gotoBatchresult(data) {
    // console.log("data854565869854---", data);
    navigate("/batch_result", { state: data });
  }

  function selectedCheckbox() {
    let arr = [];
    let eachCheck = document.getElementsByClassName("eachcheckbox");
    for (let i of eachCheck) {
      if (i.checked) {
        console.log("i----", i.name);
        arr.push(i.name);
      }
    }
    return arr;
  }

  async function deleteOperation() {
    try {
      let selected = selectedCheckbox();

      setOpen(true);
      const res = await axios.delete(`${backend_url}/configuration/delete_dag`, {
        data: {
          "dag_id": selected
        }, headers: { "Authorization": `Bearer ${token}` }
      });
      setOpen(false);
      if (res.data.success) {
        fetchdata();
      }

    }
    catch (e) {
      console.log("error---", e);
    }
  }


  const funError = (data) => {
    toast.current.show({
      severity: "error",
      summary: "Error",
      detail: data,
      life: 2000,
    });
  };


  return (
    <div>
      <header>
        <div className='d-flex align-items-center justify-content-between' >
          <Toast ref={toast} />
          <div className="d-flex align-items-center">
            <div className='logohover'>
              {/* <div className='mllogo'>
                <img src={mllogo} alt="mlangles logo" />
              </div> */}
              <div className="newlogo">
                <img src={mlimage} className='newlogos' alt="" />
              </div>
            </div>
            <h4 className='capitailze'>{userDetails?.user_full_name} workspace / </h4><Link to="/serving"><span> Serving</span></Link><span>/ Batch Serving</span>
          </div>
          <Superuser logoutClicked={() => { setLogout(true) }} />

        </div>
      </header>
      <Dialog className="logout-dialogbox" visible={logout} style={{ width: '40vw' }} onHide={() => setLogout(false)}>
        <Logout no={() => setLogout(false)} />
      </Dialog>

      <Loading loading={open} />

      <Mlsidebar data={path} />
      <div className="middlepart serving">
        <h2 className='titleFixed'>BATCH PREDICTIONS</h2>
        {/* {
          err ?
            <div className='d-flex align-items-center justify-content-center'>
              <h4 className='error-message'>{err}</h4>
            </div>
            :
            <></>
        } */}

        <div className="inside-serving">
          <div className='d-flex justify-content-end align-items-center padRig38 martop20'>
            <div className='d-flex' >
              <Link to="/batch_process">
                <div className="commonbtn"  >
                  <img src={plus} alt="" /> <span>New Job</span>
                </div>
              </Link>
              <div className="commonbtn" onClick={() => deleteOperation()} >
                <img src={deleteIcon} alt="" /> <span>Delete</span>
              </div>
            </div>
          </div>

          <div className="usertable">
            <table>
              <thead>
                <tr>
                  <th> <input autoComplete="off" type="checkbox" className='servingcheckbox checkboxparent' onChange={changecheckBoxParent} /> JOB NAME</th>
                  <th>PROJECT NAME </th>
                  <th>CREATED AT</th>
                  <th>CREATED BY</th>
                  <th>STATUS</th>
                  <th>NEXT RUN ON</th>
                </tr>
              </thead>
              <tbody>
                {
                  tableData?.map((data, idx) => {
                    return (
                      <tr key={idx}>
                        <td>
                          <input autoComplete="off" type="checkbox" className='servingcheckbox eachcheckbox' name={data.job_name} onChange={checkBoxEach} />
                          {/* <span onClick={() => gotoBatchresult(data)} style={{ color: "#0e8ebe", cursor: "pointer" }}> {data.job_name} </span> */}
                          <span style={{ color: "#0e8ebe" }}> {data.job_name} </span>
                        </td>
                        {/* <td onClick={gotoBatchresult(data)} style={{ color: "#0e8ebe" }}>  {data.project_name}</td> */}
                        <td > {data.project_name}   </td>
                        <td>{data.created_at}</td>
                        <td>{data.created_by}</td>
                        <td>{data.status}</td>
                        <td>{data.next_run}</td>
                      </tr>
                    )
                  })
                }
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div >
  )
}

export default BatchServe;