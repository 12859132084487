import React, { useEffect, useRef, useState } from "react";
import { Toast } from "primereact/toast";
import { useNavigate, useParams } from "react-router";
import axios from "axios";
import Loading from "../../Components/Loading";

// import createdat from "../../logo/createdat.png";
// import createdat from "../../logo/createdat.png";
import { TabPanel, TabView } from "primereact/tabview";
import ExperimentSidebar from "./ExperimentSidebar";
import deleteIcon from "../../logo/deleteIcon.png";
import filterIcon from "../../logo/clear_filter.png";
import filterActive from "../../logo/filterActive.png";
import plus from "../../logo/plus.png";
import { backend_url, backend_url1 } from "../../Config";

import mllogo from "../../logo/mlangles360logo.svg";
import mlimage from "../../logo/mlangles360logo.svg";
import { Link } from "react-router-dom";
import Superuser from "../../Components/Superuser";
import Logout from "../Logout";
import { Dialog } from "primereact/dialog";
import Mlsidebar from "../../Components/Mlsidebar";
import filter from "../../logo/filter.png";
import run_conf from "../../logo/run_conf.png";
import NewExperiment from "./NewExperiment";

const ProjectTable = () => {
  let { project_name, project_id, path, experiment, run } = useParams();
  const [open, setOpen] = useState(false);
  const toast = useRef(null);
  const [showrun, setShowrun] = useState(false);

  const [experimentTable, setExperimentTable] = useState([]);
  const navigate = useNavigate();

  const [createdBy, setCreatedBy] = useState(false);
  const [searchCreated, setSearchCreated] = useState("");
  const [initialUser, setInitialUser] = useState([]);
  const [initialData, setInitialData] = useState([]);
  const [user, setUser] = useState([]);
  const [status, setStatus] = useState(false);
  const [openSidebar, setOpenSidebar] = useState(false);
  const [sidebarData, setSidebarData] = useState({});
  const [userDetails, setUserDetails] = useState({});
  const [logout, setLogout] = useState(false);
  const [metricsTh, setMetricsTh] = useState([]);
  const [paramTh, setParamTh] = useState([]);
  const [filterAll, setFilterAll] = useState({ createdBy: true, status: true });
  const [deleteStatus, setDeleteStatus] = useState(true);
  const [fullPage, setFullPage] = useState(false);

  let token = JSON.parse(localStorage.getItem("mlanglesToken"));
  let Header = { headers: { Authorization: `Bearer ${token}` } };
  // const [err, setErr] = useState("");

  useEffect(() => {
    let token = JSON.parse(localStorage.getItem("mlanglesToken"));
    let userdetails = JSON.parse(localStorage.getItem("userDetails"));
    setUserDetails(userdetails);
    if (userdetails) {
      if (!token || userDetails?.permissions?.experiments === 0) {
        navigate("/");
      }
      fetchData();
      // setOpen(true);
    }

    // const myInterval = setInterval(continous, 3000);
    async function continous() {
      fetchData1();
      // fetchData();
    }
    return () => {
      console.log("cleaning data---- done");
      // clearInterval(myInterval);
    };
  }, []);

  const funError = (data) => {
    setOpen(false);
    toast.current.show({
      severity: "error",
      summary: "Error",
      detail: data,
      life: 2000,
    });
  };

  function supportingValue(data) {
    let data1 = data;
    let user1 = new Set();

    let uniqueMetrics = new Set();
    let uniqueParam = new Set();
    for (let i = 0; i < data1.length; i++) {
      user1.add(data1[i].created_by);
      let parameters = data1[i]?.data?.data;
      if (parameters?.params) {
        for (let i of parameters?.params) {
          uniqueParam.add(i.key);
        }
      }
      if (parameters?.params) {
        for (let i of parameters?.metrics) {
          uniqueMetrics.add(i.key);
        }
      }
    }

    let onlyMetric = Array.from(uniqueMetrics);
    let onlyParam = Array.from(uniqueParam);
    // console.log("onlyMetric11111----------", onlyMetric, onlyMetric.length);

    let already = 0;
    for (let i = 0; i < onlyMetric.length; i++) {
      if (onlyMetric[i] === "Score") {
        onlyMetric.splice(i, 1);
        already = 1;
        break;
      }
    }

    if (already) {
      onlyMetric.push("Score");
    }

    setMetricsTh(onlyMetric);
    setParamTh(onlyParam);
    setUser(Array.from(user1));
    setInitialUser(Array.from(user1));
  }

  async function fetchData() {
    try {
      console.log("fetchData------fetchData");
      if (run === "true") {
        setShowrun(true);
      }
      setOpen(true);

      const res = await axios.get(
        `${backend_url}/tracking/experiment_tracking/${project_id}`,
        Header
      );
      if (res.data.success) {
        setOpen(false);

        setExperimentTable(res.data.data);
        // console.log(res.data.data);
        setInitialData(res.data.data);
        supportingValue(res.data.data);

        let fullData = res.data.data;

        for (let i of fullData) {
          // console.log("i---", i.data.info.status);
          if (
            ["finished", "success"].includes(i.data.info.status.toLowerCase())
          ) {
            console.log("i---", i.data.info.run_name);
            let rName = i.data.info.run_name;
            // const grafana_inputs = await axios.post(
            //   `${backend_url}/mlflow/grafana_inputs`,
            //   { project_id: project_id, run_name: rName },
            //   Header
            // );
            // console.log("grafana_inputs---", grafana_inputs);

            break;
          }
        }
      } else {
        setOpen(false);
        setExperimentTable([]);
      }
    } catch (e) {
      // console.log("There is an Error--", e);
      funError(e.message);
    }
  }

  async function fetchData1() {
    try {
      // setOpen(true);
      if (run === "true") {
        setShowrun(true);
      }
      const res = await axios.get(
        `${backend_url}/tracking/experiment_tracking/${project_id}`,
        Header
      );
      setOpen(false);

      if (res.data.success) {
        // setExperimentTable(res.data.data);
        // setInitialData(res.data.data);
        let d1 = res.data.data;

        if (d1.length > experimentTable.length) {
          setExperimentTable(d1);
          setInitialData(d1);
          // alert("new data added successfully");
          supportingValue(res.data.data);
        }
        // let data1 = res.data.data;
        // let user1 = new Set();
        // for (let i = 0; i < data1.length; i++) {
        //     user1.add(data1[i].created_by);
        // }
        // setUser(Array.from(user1));
        // setInitialUser(Array.from(user1));
      }
      // else {
      //     setOpen(false);
      //     setExperimentTable([]);
      // }
    } catch (e) {
      funError(e.message);
    }
  }

  async function runButton() {
    navigate(`/createRun/Projects/${project_name}/${project_id}`);
  }

  function searchCreating(e) {
    let data = e.target.value.toLowerCase();
    setSearchCreated(data);
    let newData = [];
    for (let i = 0; i < initialUser.length; i++) {
      if (initialUser[i].toLowerCase().includes(data)) {
        newData.push(initialUser[i]);
      }
    }
    setUser(newData);
  }

  function filterCreatedBy() {
    toggle();
    if (createdBy) {
      setCreatedBy(!createdBy);
    }
    let selected = [];
    let flag = false;
    var eachcheckcreatedby =
      document.getElementsByClassName("eachcheckcreatedby");
    for (let i = 0; i < eachcheckcreatedby.length; i++) {
      if (eachcheckcreatedby[i].checked) {
        selected.push(eachcheckcreatedby[i].name);
        flag = true;
      }
    }
    if (flag) {
      setFilterAll({ ...filterAll, ["createdBy"]: false });
    } else {
      setFilterAll({ ...filterAll, ["createdBy"]: true });
    }
    let newData = [];
    for (let i = 0; i < experimentTable.length; i++) {
      if (selected.includes(experimentTable[i].created_by.toLowerCase())) {
        newData.push(experimentTable[i]);
      }
    }

    if (selected.length === 0) {
      setExperimentTable(initialData);
    } else {
      setExperimentTable(newData);
    }
    setSearchCreated("");
    setUser(initialUser);
  }

  function allCommonFilter() {
    toggle();
    let eachcheckstatus = document.getElementsByClassName("eachcheckstatus");
    let eachcheckcreatedby =
      document.getElementsByClassName("eachcheckcreatedby");
    // let eachprojectname = document.getElementsByClassName("projectname");

    let selCreatedBy = [];
    for (let i = 0; i < eachcheckcreatedby.length; i++) {
      if (eachcheckcreatedby[i].checked) {
        selCreatedBy.push(eachcheckcreatedby[i].name);
      }
    }

    let selStatus = [];
    for (let i = 0; i < eachcheckstatus.length; i++) {
      if (eachcheckstatus[i].checked) {
        selStatus.push(eachcheckstatus[i].name);
      }
    }

    console.log("selected---", selCreatedBy, selStatus);

    let newData = [];

    let initialData123 = JSON.parse(JSON.stringify(initialData));
    console.log("initialData123------->", initialData123);
    let changeFilter = { ...filterAll };

    for (let i = 0; i < initialData123.length; i++) {
      // console.log(initialData123[i]);

      if (
        (selCreatedBy.length !== 0
          ? selCreatedBy.includes(initialData123[i].created_by?.toLowerCase())
          : true) &&
        (selStatus.length !== 0
          ? selStatus.includes(
              initialData123[i].data.info.status?.toLowerCase()
            )
          : true)
      ) {
        newData.push(initialData123[i]);
      }
    }
    console.log("newData------", newData);
    if (selCreatedBy.length !== 0) {
      changeFilter.createdBy = false;
    } else {
      changeFilter.createdBy = true;
    }
    if (selStatus.length !== 0) {
      changeFilter.status = false;
    } else {
      changeFilter.status = true;
    }

    setFilterAll(changeFilter);
    setExperimentTable(newData);
  }

  function filterStatus() {
    toggle();
    if (status) {
      setStatus(!status);
    }
    let selected = [];
    var eachcheckstatus = document.getElementsByClassName("eachcheckstatus");
    let flag = false;
    for (let i = 0; i < eachcheckstatus.length; i++) {
      if (eachcheckstatus[i].checked) {
        if (eachcheckstatus[i]?.name === "success") {
          selected.push("success", "finished");
        } else if (eachcheckstatus[i]?.name === "failed") {
          selected.push("failed", "failure");
        } else {
          selected.push("running", "unfinished");
        }
        flag = true;
      }
    }
    if (flag) {
      setFilterAll({ ...filterAll, ["status"]: false });
    } else {
      setFilterAll({ ...filterAll, ["status"]: true });
    }

    console.log("selected---------", selected);

    let newData = [];
    for (let i = 0; i < experimentTable.length; i++) {
      // console.log("initialData[i]?.status-------", initialData[i]);
      if (
        selected.includes(experimentTable[i]?.data.info.status?.toLowerCase())
      ) {
        newData.push(experimentTable[i]);
      } else {
        if (selected.includes("running") && !experimentTable[i]?.result) {
          newData.push(experimentTable[i]);
        }
      }
    }

    console.log("newData------------", newData);

    if (selected.length === 0) {
      setExperimentTable(initialData);
    } else {
      setExperimentTable(newData);
    }
  }

  function runIdClicked(data) {
    // setOpenSidebar(true);
    setSidebarData(data);
    setFullPage(true);
  }

  function closing() {
    setOpenSidebar(false);
  }

  function changecheckBoxParent(e) {
    let checkboxparent = document.getElementsByClassName("checkboxparent")[0];
    let eachCheck = document.getElementsByClassName("eachcheckbox");

    if (checkboxparent.checked) {
      for (let i = 0; i < eachCheck.length; i++) {
        eachCheck[i].checked = true;
      }
      setDeleteStatus(false);
    } else {
      for (let i = 0; i < eachCheck.length; i++) {
        eachCheck[i].checked = false;
      }
      setDeleteStatus(true);
    }
  }

  function checkBoxEach(e) {
    //console.log(e)
    let checkboxparent = document.getElementsByClassName("checkboxparent")[0];
    let eachCheck = document.getElementsByClassName("eachcheckbox");
    let flag = false;
    for (let i of eachCheck) {
      if (!i.checked) {
        checkboxparent.checked = false;
      } else {
        flag = true;
      }
    }
    if (flag) {
      setDeleteStatus(false);
    } else {
      setDeleteStatus(true);
    }
  }

  async function deleteExperiment() {
    try {
      // console.log("deleting");
      let deleteArray = [];
      let checkboxparent = document.getElementsByClassName("checkboxparent")[0];
      let eachCheck = document.getElementsByClassName("eachcheckbox");

      for (let i of eachCheck) {
        if (i.checked) {
          // console.log(i.value);
          let d1 = i?.value?.split("-");
          deleteArray.push({ run_name: d1[0], run_id: d1[1] });
          i.checked = false;
        }
      }

      if (deleteArray.length > 0) {
        setOpen(true);
        const deleting = await axios.delete(
          `${backend_url}/tracking/deleteExperimentRun`,
          {
            data: { run_ids: deleteArray },
            headers: { Authorization: `Bearer ${token}` },
          }
        );
        if (deleting.data.success) {
          fetchData();

          //   setFilterAll({...filterAll,['']})
          clearing();
        }
        setDeleteStatus(true);
        checkboxparent.checked = false;
      }
    } catch (err) {
      console.log("There is a error:--", err);
      funError(err.message);
    }
  }

  function uncheckAll() {
    const checkboxAll = document.querySelectorAll("[type=checkbox]");
    for (let i of checkboxAll) {
      if (i.checked) {
        i.checked = false;
      }
    }
  }

  function toggle() {
    const toggleDropdown = document.getElementsByClassName("dropdown-menu");
    // console.log("toggleDropdown----", toggleDropdown);
    for (let i of toggleDropdown) {
      // console.log(i);
      i.classList.remove("show");
    }
    // toggleDropdown.classList.remove("show");
  }

  function clearing() {
    setSearchCreated("");
    uncheckAll();
    setFilterAll({ createdBy: true, status: true });
    // filterCreatedBy();
  }

  function empltyList() {
    setFullPage(false);
    fetchData();
  }

  return (
    <div>
      <header>
        <div className="d-flex align-items-center justify-content-between">
          <div className="d-flex align-items-center">
            <div className="logohover">
              {/* <div className='mllogo'>
                                <img src={mllogo} alt="mlangles logo" />
                            </div> */}
              <div className="newlogo">
                <img src={mlimage} className="newlogos" alt="" />
              </div>
            </div>
            <Link to="/Home">
              <h4 className="capitailze">
                {userDetails?.user_full_name} workspace /
              </h4>
            </Link>
            <Link to="/Projects">
              <span> Projects /</span>
            </Link>
            <span className="capitailze">
              {project_name} Experiment Tracking
            </span>
          </div>
          <Superuser
            logoutClicked={() => {
              setLogout(true);
            }}
          />
        </div>
      </header>

      <Dialog
        className="logout-dialogbox"
        visible={logout}
        style={{ width: "40vw" }}
        onHide={() => setLogout(false)}
      >
        <Logout no={() => setLogout(false)} />
      </Dialog>

      <Loading loading={open} />
      <Toast ref={toast} />
      <Mlsidebar data={path} />

      <div className="middlepart">
        <div className="card">
          <div className="each-pipehead">
            <div className="d-flex align-items-center justify-content-between mar-right25 pad12 he56">
              <div className="d-flex align-items-center">
                <Link
                  to={`/projectpipeline/${path}/${project_name}/${project_id}`}
                >
                  <h6 className="head mypro cursor">PIPELINES</h6>
                </Link>
                <h6 className="head green assignpro cursor">
                  EXPERIMENT TRACKING
                </h6>
              </div>
              <div>
                <Link to="/serving">
                  <button className="commonbtn servBtn">Go to Serving</button>
                </Link>
              </div>
            </div>
          </div>
          {/* <div className="d-flex align-items-center justify-content-center">
            <h4 className="error-message">{err}</h4>
          </div> */}
          {openSidebar ? (
            <ExperimentSidebar
              data={sidebarData}
              project_id={project_id}
              close={closing}
            />
          ) : (
            <></>
          )}
          {fullPage ? (
            <div className="allpipelines martop48">
              <NewExperiment
                sidebarData={sidebarData}
                project_id={project_id}
                hide={empltyList}
              />
            </div>
          ) : (
            <div className="allpipelines martop60px">
              <div className="d-flex mb-3 justify-content-end padd-24 martop12">
                <button className="commonbtn" onClick={() => runButton()}>
                  <img src={plus} alt="plus" className="plus" /> New Run
                </button>

                <Link to={`/defaultParamter/${project_name}/${project_id}`}>
                  <button className="commonbtn">
                    <img src={run_conf} alt="run_conf" className="plus" /> Run
                    Configuration
                  </button>
                </Link>

                <div
                  className="commonbtn"
                  style={{
                    opacity:
                      !filterAll.createdBy || !filterAll.status ? 1 : 0.5,
                  }}
                  onClick={() => {
                    setExperimentTable(initialData);
                    clearing();
                  }}
                >
                  <img src={filterIcon} alt="" /> <span>Clear Filter</span>
                </div>

                <div
                  className="commonbtn"
                  style={{ opacity: deleteStatus ? 0.5 : 1 }}
                  onClick={() => deleteExperiment()}
                >
                  <img src={deleteIcon} className="deleteIcon" alt="" /> Delete
                </div>
              </div>
              <div className="overflows inputrightspace">
                <table
                  className={
                    experimentTable.length > 0 ? "sample " : "sample w100"
                  }
                  style={{ width: "max-content" }}
                >
                  <thead className="fixing darkblue">
                    {/* <tr className='smp'>
                                        <th colSpan={6}></th>
                                        {
                                            metricsTh.length > 0 ?
                                                <th colSpan={metricsTh.length}>METRICS</th> : <></>
                                        }
                                        {
                                            paramTh.length > 0 ?
                                                <th colSpan={paramTh.length}>PARAMETERS</th> : <></>
                                        }
                                    </tr> */}
                    <tr>
                      <th>
                        <input
                          type="checkbox"
                          className="checkboxparent"
                          onChange={changecheckBoxParent}
                        />
                        RUN ID
                      </th>
                      <th>RUN NAME </th>
                      <th className="posrel width140px">
                        STATUS
                        {filterAll?.status ? (
                          <img
                            src={filter}
                            className="fa-solid fa-filter dropdown-toggle img-responsive filter"
                            data-bs-toggle="dropdown"
                            alt="filter"
                          />
                        ) : (
                          <img
                            src={filterActive}
                            className="fa-solid fa-filter dropdown-toggle img-responsive filter"
                            data-bs-toggle="dropdown"
                            alt="filter"
                          />
                        )}
                        <div
                          onClick={(e) => e.stopPropagation()}
                          id="toggleDropdown"
                          className="posabs search-content dropdown-menu options"
                        >
                          <div className="d-flex allcheck">
                            <div className="eachboxcheck">
                              <input
                                type="checkbox"
                                id="success"
                                name="finished"
                                className="eachcheckstatus"
                              />
                              <label htmlFor="success">Success </label>
                            </div>
                            <div className="eachboxcheck">
                              <input
                                type="checkbox"
                                id="failed"
                                name="failed"
                                className="eachcheckstatus"
                              />
                              <label htmlFor="failed">Failed </label>
                            </div>
                            <div className="eachboxcheck">
                              <input
                                type="checkbox"
                                id="running"
                                name="running"
                                className="eachcheckstatus"
                              />
                              <label htmlFor="running">Running </label>
                            </div>
                          </div>
                          <div className="text-end">
                            <button
                              onClick={() => allCommonFilter()}
                              className="apply"
                              id="applyStatus"
                            >
                              Apply
                            </button>
                          </div>
                        </div>
                      </th>
                      <th
                        className={
                          experimentTable.length === 0
                            ? "posrel width160px"
                            : "posrel "
                        }
                      >
                        CREATED BY
                        {filterAll?.createdBy ? (
                          <img
                            src={filter}
                            className="fa-solid fa-filter dropdown-toggle img-responsive filter"
                            data-bs-toggle="dropdown"
                            alt="filter"
                          />
                        ) : (
                          <img
                            src={filterActive}
                            className="fa-solid fa-filter dropdown-toggle img-responsive filter"
                            data-bs-toggle="dropdown"
                            alt="filter"
                          />
                        )}
                        <div
                          onClick={(e) => e.stopPropagation()}
                          id="toggleDropdown"
                          className="posabs search-content dropdown-menu options"
                        >
                          <input
                            autoComplete="off"
                            type="text"
                            placeholder="Search"
                            className="textinput"
                            value={searchCreated}
                            onChange={(e) => searchCreating(e)}
                          />
                          <div className="d-flex allcheck">
                            {user.map((ele, index) => {
                              return (
                                <div className="eachboxcheck" key={index}>
                                  <input
                                    type="checkbox"
                                    id={ele}
                                    name={ele.toLowerCase()}
                                    className="eachcheck eachcheckcreatedby"
                                  />
                                  <label htmlFor={ele}>{ele} </label>
                                </div>
                              );
                            })}
                          </div>
                          <div className="text-end">
                            <button
                              onClick={() => allCommonFilter()}
                              className="apply"
                              id="applycreatedby"
                            >
                              Apply
                            </button>
                          </div>
                        </div>
                      </th>

                      <th>START TIME </th>
                      <th>END TIME </th>

                      {/* {
                                            metricsTh?.map((ele, idx) => {
                                                return (
                                                    <th key={idx}>{ele.toUpperCase()} </th>
                                                )
                                            })
                                        } */}

                      {/* {
                                            paramTh?.map((ele, idx) => {
                                                return (
                                                    <th key={idx}>{ele.toUpperCase()} </th>
                                                )
                                            })
                                        } */}
                    </tr>
                  </thead>
                  <tbody>
                    {experimentTable.map((user, idx) => {
                      let starttime = new Date(
                        user.data.info.start_time
                      ).toLocaleString(undefined, { timeZone: "Asia/Kolkata" });
                      let endtime = new Date(
                        user.data.info.end_time
                      ).toLocaleString(undefined, { timeZone: "Asia/Kolkata" });

                      console.log(
                        "starttime-----",
                        starttime === "Invalid Date",
                        starttime,
                        "----",
                        user.data.info.start_time
                      );

                      return (
                        <tr key={idx}>
                          {/* {["finished", "success"].includes( */}
                          {!["running"].includes(
                            user?.data?.info?.status?.toLowerCase()
                          ) ? (
                            <td className="runidtd">
                              <input
                                type="checkbox"
                                className="eachcheckbox"
                                value={`${user.data.info.run_name}-${user.data.info.run_id}`}
                                onChange={checkBoxEach}
                              />
                              <span
                                className="cursorhover"
                                onClick={() => runIdClicked(user)}
                              >
                                {user.data.info.run_id}
                              </span>
                            </td>
                          ) : (
                            <td className="runidtd">
                              <input
                                type="checkbox"
                                className="eachcheckbox"
                                value={`${user.data.info.run_name}-${user.data.info.run_id}`}
                                onChange={checkBoxEach}
                              />
                              {user.data.info.run_id}
                            </td>
                          )}

                          <td>{user.data.info.run_name}</td>

                          {["finished", "success"].includes(
                            user?.data?.info?.status?.toLowerCase()
                          ) ? (
                            <td className="green">Success</td>
                          ) : (
                            <></>
                          )}
                          {["failed", "failure"].includes(
                            user?.data?.info?.status?.toLowerCase()
                          ) ? (
                            <td className="red">Failed</td>
                          ) : (
                            <></>
                          )}
                          {["error"].includes(
                            user?.data?.info?.status?.toLowerCase()
                          ) ? (
                            <td className="red">Error</td>
                          ) : (
                            <></>
                          )}
                          {["running", "", "unfinished"].includes(
                            user?.data?.info?.status?.toLowerCase() ||
                              !user?.data?.info?.status
                          ) ? (
                            <td className="blue">Running</td>
                          ) : (
                            <></>
                          )}

                          <td>{user?.created_by}</td>
                          <td>
                            {starttime === "Invalid Date" ? "-" : starttime}
                          </td>
                          <td>{endtime === "Invalid Date" ? "-" : endtime}</td>
                          {/*  <td>{endtime}</td> */}

                          {/* {
                                                        metricsTh.map((ele1, index) => {
                                                            let flag = 0;
                                                            return (
                                                                <React.Fragment key={index}>
                                                                    {
                                                                        user?.data.data?.metrics?.map((ele, index1) => {
                                                                            if (ele1 === ele.key) {
                                                                                flag = 1;
                                                                                return (
                                                                                    <td key={index1}> {ele.value} </td>
                                                                                )
                                                                            }
                                                                        })
                                                                    }
                                                                    {
                                                                        !flag
                                                                            ?
                                                                            <><td> - </td></>
                                                                            : <></>
                                                                    }
                                                                </React.Fragment>
                                                            )
                                                        })
                                                    }

                                                    {
                                                        paramTh.map((ele1, index) => {
                                                            let flag = 0;
                                                            return (
                                                                <React.Fragment key={index}>
                                                                    {
                                                                        user?.data.data?.params?.map((ele, index) => {
                                                                            if (ele1 === ele.key) {
                                                                                flag = 1;
                                                                                return (
                                                                                    <td key={index}> {ele.value} </td>
                                                                                )
                                                                            }
                                                                        })
                                                                    }
                                                                    {
                                                                        !flag
                                                                            ?
                                                                            <><td> - </td></>
                                                                            : <></>
                                                                    }
                                                                </React.Fragment>
                                                            )
                                                        })
                                                    } */}
                        </tr>
                      );
                    })}
                  </tbody>
                </table>
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default ProjectTable;
