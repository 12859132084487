import React, { useEffect, useRef, useState } from "react";
import { Toast } from "primereact/toast";
import Mlsidebar from "../Components/Mlsidebar";
import { Link, useAsyncError, useNavigate, useParams } from "react-router-dom";
import IndustryProjectBox from "../Components/industryProjectsBox";
import mlimage from "../logo/mlangles360logo.svg";
import { Dialog } from "primereact/dialog";
import axios from "axios";
import Superuser from "../Components/Superuser";
import { backend_url, backend_url1 } from "../Config";
import Logout from "./Logout";
import { useLocation } from "react-router-dom";
import Loading from "../Components/Loading";
import mllogo from "../logo/mlangles360logo.svg";
import profilePic from "../logo/profile-pic.png";
import ViewDetailSideBar from "./ViewDetailSideBar";
import searchIcon from "../logo/searchIcon.png";
import plus from "../logo/plus.png";
import AddIcon from "@mui/icons-material/Add";
import CloseIcon from "@mui/icons-material/Close";
import { Sidebar } from "primereact/sidebar";
import filter from "../logo/filter.png";
import chatBot from "../logo/chatBot.png";
import filtericon from "../logo/filter.png";

const IndustryLevelProjects = () => {
  const [open, setOpen] = useState(true);
  const [projects, setProjects] = useState([]);
  // const [show, setShow] = useState(false);
  const navigate = useNavigate();
  const [userDetails, setUserDetails] = useState({});
  const [logout, setLogout] = useState(false);
  const toast = useRef(null);
  const location = useLocation();
  // const params = useParams();
  // const { selectedIndustry } = params;

  const [selectedIndustryProjects, setSelectedIndustryProjects] = useState([]);

  let token = JSON.parse(localStorage.getItem("mlanglesToken"));
  let Header = { headers: { Authorization: `Bearer ${token}` } };

  let path = window?.location?.href?.split("/")[3];

  const industryName = decodeURIComponent(
    window.location.pathname.split("/").pop().replace(/%20/g, " ")
  );

  useEffect(() => {
    setOpen(true);
    if (location.state && location.state.data) {
      setSelectedIndustryProjects(location.state.data);
    }
    setOpen(false);
  }, [location.state]);

  console.log("location.state---------->", location.state);

  useEffect(() => {
    let token = JSON.parse(localStorage.getItem("mlanglesToken"));
    let userdetails = JSON.parse(localStorage.getItem("userDetails"));
    setUserDetails(userdetails);

    if (userdetails) {
      if (!token || userDetails?.permissions?.projects_admin === 0) {
        navigate("/");
      }
      fetchData();
    }
  }, []);

  async function fetchData() {
    try {
      if (selectedIndustryProjects.length > 0) {
        setProjects(selectedIndustryProjects);
        setOpen(false);
        // setShow(true);
      }
    } catch (e) {
      funError(e.message);
    }
  }

  console.log("allprojects----------->", projects);

  const funError = (data) => {
    toast.current.show({
      severity: "error",
      summary: "Error",
      detail: data,
      life: 2000,
    });
  };

  return (
    <div className="allProjectspage">
      <Loading loading={open} />

      <Toast ref={toast} />
      <header>
        <div className="d-flex align-items-center justify-content-between">
          <div className="d-flex align-items-center">
            <div className="logohover">
              <div className="newlogo">
                <img src={mlimage} className="newlogos" alt="" />
              </div>
            </div>
            <Link to="/Home">
              <h4 className="capitailze">
                {userDetails?.user_full_name} workspace /
              </h4>
            </Link>
            <span>
              {industryName
                ? `${industryName.charAt(0).toUpperCase()}${industryName.slice(
                    1
                  )} Project Usecases`
                : "Industry Projects Usecases"}
            </span>
          </div>
          <Superuser
            logoutClicked={() => {
              setLogout(true);
            }}
          />
        </div>
      </header>

      <Dialog
        className="logout-dialogbox"
        visible={logout}
        style={{ width: "40vw" }}
        onHide={() => setLogout(false)}
      >
        <Logout no={() => setLogout(false)} />
      </Dialog>

      <Mlsidebar data={path} />

      {/* <Dialog
        visible={visible}
        style={{ width: "30vw" }}
        className="popupProject"
        onHide={() => {
          setVisible(false);
          setName("");
          setDes("");
          setLeftDes(0);
        }}
      ></Dialog> */}

      <section className="middlepart allprojects dashboard">
        <div className="each-pipehead">
          <div className="d-flex justify-content-between mar-right25 pad12">
            <div className="d-flex align-items-center">
              <h6 className="IndustryHeader">
                {industryName
                  ? `${industryName
                      .charAt(0)
                      .toUpperCase()}${industryName.slice(1)} Project Usecases`
                  : "Industry Project Usecases"}
              </h6>
              {/* <Link to="/assignedProjects">
                <h6 className="head assignpro">ASSIGNED PROJECTS</h6>
              </Link> */}
            </div>
          </div>
        </div>
        <div className="padTop40">
          <div className="allprojts">
            <div className="d-flex flex-wrap w100 pad24">
              {selectedIndustryProjects?.map((data, idx) => {
                return (
                  <IndustryProjectBox key={idx} value={data} index={idx} />
                );
              })}
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default IndustryLevelProjects;
