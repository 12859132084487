import React, { useEffect, useState } from 'react';

const S3input = ({ show, mainData, validate }) => {

    useEffect(() => {
        setS3({
            "AWS_ACCESS_KEY_ID": "",
            "AWS_SECRET_ACCESS_KEY": "",
            "Bucket_Name": "",
            "Object_Path": "",
        });
    }, [show]);

    const [s3, setS3] = useState({
        "AWS_ACCESS_KEY_ID": "",
        "AWS_SECRET_ACCESS_KEY": "",
        "Bucket_Name": "",
        "Object_Path": "",
    });


    function fieldSubmit(e) {
        e.preventDefault();
        if (!s3.Object_Path) {
            delete s3.Object_Path
        }
        console.log("s3---", s3);
        mainData(s3);
    }

    function datavalues3Change(e) {
        let name = e.target.name;
        let value = e.target.value;
        setS3({ ...s3, [name]: value });
    }

    function nextBtn(e) {
        e.preventDefault();
        if (Object.keys(s3).length !== 0) {
            mainData(s3, true);
        }
    }

    console.log("validate---", validate);

    return (
        <form onSubmit={fieldSubmit}>
            <div className="serpro-bottom">
                <h4>Provide Your S3 Credentials</h4>
                <div className='serveprobottombox'>
                    <input autoComplete="off" type="text" required placeholder='AWS Access Key ID' name="AWS_ACCESS_KEY_ID" value={s3.AWS_ACCESS_KEY_ID} onChange={datavalues3Change} />
                    <input autoComplete="off" type="password" required placeholder='AWS Secret Access Key ID' name='AWS_SECRET_ACCESS_KEY' value={s3.AWS_SECRET_ACCESS_KEY} onChange={datavalues3Change} />
                    <input autoComplete="off" type="text" required placeholder='Bucket Name' name='Bucket_Name' value={s3.Bucket_Name} onChange={datavalues3Change} />
                    {
                        show ? <input autoComplete="off" type="text" required placeholder='Object Path' name='Object_Path' value={s3.Object_Path} onChange={datavalues3Change} /> : <></>
                    }
                </div>

                {
                    validate?.url || validate?.s3 ? <button name='s3' onClick={nextBtn} className='nextbtn'>Next</button> : <button type='submit' className='nextbtn'>Validate</button>
                }

            </div>
        </form>
    )
}

export default S3input