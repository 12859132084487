// local
// export const backend_url = "http://127.0.0.1:8000";     
// export const frontend_url = "http://127.0.0.1:8000";     

// demo - production
export const backend_url = "https://mlops.mlangles.ai";
export const frontend_url = "https://mlops.mlangles.ai";

// public dev for demo - production
// export const backend_url = "http://54.84.62.198";
// export const frontend_url = "http://54.84.62.198";

// private IP for dev
// export const backend_url = "http://172.31.73.232";
// export const frontend_url = "http://172.31.73.232";

// private IP for demo - production
// export const backend_url = "http://172.31.39.107";
// export const frontend_url = "http://172.31.39.107";

// public IP demo - production
// export const backend_url = "http://52.206.20.10";
// export const frontend_url = "http://52.206.20.10";

// Test - Private IP
// export const backend_url = "http://172.31.67.146";
// export const frontend_url = "http://172.31.67.146";

// Test - Public IP
// export const backend_url = "http://44.200.65.176";
// export const frontend_url = "http://44.200.65.176";


// demo - azure vm
// export const backend_url = "http://172.203.240.15";
// export const frontend_url = "http://172.203.240.15";
