import React, { useEffect,useRef, useState } from "react";
import { Toast } from "primereact/toast";

import axios from "axios";
import Loading from "../../Components/Loading";
import { FormControl, InputLabel, MenuItem, Select } from "@mui/material";
import { useNavigate, useParams } from "react-router";
import { Link } from "react-router-dom";
import profilePic from "../../logo/profile-pic.png";
import informationIcon from "../../logo/informationIcon.png";
import downArrow from "../../logo/downArrow.png";
import mllogo from "../../logo/mlangles360logo.svg";
import mlimage from "../../logo/mlangles360logo.svg";
import iIcon from "../../logo/i-Icon.png";
import Mlsidebar from "../../Components/Mlsidebar";
import Superuser from "../../Components/Superuser";
import { backend_url, backend_url1 } from "../../Config";
import Logout from "../Logout";
import { Dialog } from "primereact/dialog";
import ErrorMessage from "../Chatbot/ErrorMessage";
import InputCheckbox from "./InputCheckbox";
import Cookies from "universal-cookie";

const DefaultParameter = () => {
  let { project_name, project_id } = useParams();
  const [inputValue, setInputValue] = useState({});
  const [selected, setSelected] = useState([]);
  const [runName, setRunName] = useState("");
  const [fulldata, setFulldata] = useState({});
  const [open, setOpen] = useState(false);
  const navigate = useNavigate();
  const [path, setPath] = useState("projects");
  const toast = useRef(null);

  const [totalData, setTotalData] = useState({});
  const [problemtype, setProblemtype] = useState("");
  const [showalogo, setShowalgo] = useState(false);
  const [runs, setRuns] = useState([]);
  const [currentTab, setCurrentTab] = useState("experimentTracking");
  const [userDetails, setUserDetails] = useState({});
  const [logout, setLogout] = useState(false);
  const [fieldErr, setFieldErr] = useState({
    runName: "",
    problemType: "",
    dataVersion: "",
    targetVariable: "",
    instance: "",
  });
  const [score, setScore] = useState("");
  const [err, setErr] = useState("");
  const [classiCheck, setClassiCheck] = useState([]);
  const [regCheck, setRegCheck] = useState([]);
  const [fromFile, setFromfile] = useState({});
  const [showInput, setShowInput] = useState(false);
  const [showDrop, setShowDrop] = useState(false);
  const [edit, setEdit] = useState(false);

  let token = JSON.parse(localStorage.getItem("mlanglesToken"));
  let Header = { headers: { Authorization: `Bearer ${token}` } };

  useEffect(() => {
    let token = JSON.parse(localStorage.getItem("mlanglesToken"));
    let userdetails = JSON.parse(localStorage.getItem("userDetails"));
    setUserDetails(userdetails);
    if (userdetails) {
      if (!token || userDetails?.permissions?.experiments === 0) {
        navigate("/");
      }
      fetchData();
    }
    // cookie();
  }, []);

  const funError = (data) => {
    toast.current.show({
      severity: "error",
      summary: "Error",
      detail: data,
      life: 2000,
    });
  };

  // function cookie() {
  //     const cookies = new Cookies();
  //     cookies.set('myCat', 'Pacman', { path: '/', maxAge: 100 });
  //     console.log(cookies.get('myCat'));
  // }

  async function fetchData() {
    try {
      setOpen(true);
      const runfetch = await axios.get(
        `${backend_url}/tracking/default_trining_values`,
        Header
      );
      setOpen(false);
      if (runfetch.data.success) {
        setTotalData(runfetch.data.data);
        let res1 = runfetch.data.data;
        setFromfile(res1);

        console.log("res1----", res1);
        if (res1?.classification) {
          setClassiCheck(Object.keys(res1?.classification));
        }
        if (res1?.regression) {
          setRegCheck(Object.keys(res1?.regression));
        }
        if (res1?.confidence_score) {
          setScore(res1.confidence_score);
        }
      } else {
        funError("Error occured while getting tracking data");
      }
    } catch (e) {
      setOpen(false);
      funError("Error occured while getting tracking data");
    }
  }

  function errorHide() {
    setTimeout(() => {
      setFieldErr({
        runName: "",
        problemType: "",
        dataVersion: "",
        targetVariable: "",
        instance: "",
      });
    }, 5000);
  }

  async function createRunbtn(e) {
    try {
      e.preventDefault();
      console.log("fulldata---", fulldata);

      for (let i in fulldata) {
        if (selected.includes(i)) {
          continue;
        } else {
          delete fulldata[i];
        }
      }

      for (let i = 0; i < selected.length; i++) {
        if (selected[i] in inputValue) {
        } else {
          inputValue[selected[i]] = {};
        }
      }

      let initalErr = { problemType: "" };
      let flag = 0;

      if (!problemtype) {
        initalErr = { ...initalErr, problemType: "Select Problem Type" };
        errorHide();
        flag = 1;
      }

      setFieldErr(initalErr);
      let runFlag = 0;
      for (let i of runs) {
        if (i === runName) {
          runFlag = 1;
          funError("Run Name need to be unique for a project");
          break;
        }
      }

      if (flag || runFlag) {
        window.scrollTo(0, 0);
      }

      if (!flag && !runFlag) {
        let resultData = {};
        resultData[problemtype] = fulldata;
        resultData["confidence_score"] = score;
        let da1 = "";
        if (problemtype === "classification") {
          da1 = "regression";
        } else if (problemtype === "regression") {
          da1 = "classification";
        }

        console.log("fromFile---", fromFile);

        try {
          if (da1 in fromFile) {
            console.log("there");
            resultData[da1] = fromFile[da1];
          } else {
            console.log("not there");
            resultData[da1] = {};
          }
        } catch (e) {
          console.log("e---", e);
          resultData[da1] = {};
        }

        setOpen(true);
        const updateDefault = await axios.post(
          `${backend_url}/tracking/default_trining_values`,
          resultData,
          Header
        );
        setOpen(false);
        if (updateDefault.data.success) {
          console.log("successfully posted");
          navigate(
            `/experimentTracking/Projects/${project_name}/${project_id}`
          );
        } else {
          console.log("error occured while posting data");
          funError(updateDefault.data.message);
        }
      }
    } catch (e) {
      console.log("e-------", e);
      setOpen(false);
      funError("Error occured while creating a run with sever side");
    }
  }

  async function problemsselect(value) {
    try {
      setProblemtype(value);
      setOpen(true);
      const typeSelect = await axios.post(
        `${backend_url}/tracking/hyper_perameter_tuning_inputs_and_labels`,
        {
          type: value,
        },
        Header
      );
      setOpen(false);
      if (typeSelect.data.success) {
        setShowalgo(true);
        setFulldata(typeSelect.data.data);

        // operation(typeSelect.data.data, value);

        setTimeout(() => {
          operation(typeSelect.data.data, value);
        }, 500);
      } else {
        funError(
          "Error occured while taking hyper_perameter_tuning_inputs_and_labels"
        );
      }
    } catch (e) {
      setOpen(false);
      funError(
        "Error occured while hyper_perameter_tuning_inputs_and_labels with sever side"
      );
    }
  }

  function inputAllGetting(e, main, sub) {
    let valueData = e.target.value;

    let d1 = fulldata;

    if (["true"].includes(e.target.value)) {
      setInputValue({
        ...inputValue,
        [main]: { ...inputValue[main], [sub]: true },
      });
    } else if (["false"].includes(e.target.value)) {
      setInputValue({
        ...inputValue,
        [main]: { ...inputValue[main], [sub]: false },
      });
    } else if (Number(valueData) || valueData === "0") {
      setInputValue({
        ...inputValue,
        [main]: { ...inputValue[main], [sub]: Number(valueData) },
      });
    } else {
      setInputValue({
        ...inputValue,
        [main]: { ...inputValue[main], [sub]: e.target.value },
      });
    }

    d1[main][sub]["value"] = valueData;
    setFulldata(d1);
  }

  function changingCheckbox(eachAlgo) {
    // console.log("changingCheckbox");
    const copyOfFullData = { ...fulldata };
    for (let i in copyOfFullData) {
      if (i === eachAlgo) {
        if (copyOfFullData[i].isTrue) {
          copyOfFullData[i].isTrue = false;
          // console.log("copyOfFullData---", copyOfFullData[i]);
          let arrCopyOfFullData = Object.keys(copyOfFullData[i]);
          for (let x of arrCopyOfFullData) {
            if (copyOfFullData[i][x].value) {
              copyOfFullData[i][x].value = "null";
            }
            // console.log(copyOfFullData[i][x].value);
          }
        } else {
          copyOfFullData[i].isTrue = true;
        }
      }
    }
    setFulldata(copyOfFullData);
    let selectedCheck = [];
    for (let i in copyOfFullData) {
      if (copyOfFullData[i].isTrue) {
        selectedCheck.push(i);
      }
    }
    // console.log("selectedCheck-------", selectedCheck);
    setSelected(selectedCheck);
  }

  function operation(initial, value) {
    let selectedCheck = [];

    if (initial) {
      let full1 = initial;
      let newf1 = Object.keys(fromFile[value.toLowerCase()]);
      let formD1 = fromFile[value.toLowerCase()];
      let s1 = [];
      for (let i of newf1) {
        for (let x in full1) {
          if (i === x) {
            full1[x] = formD1[x];
            full1[x]["isTrue"] = true;
            setShowInput(true);
            s1.push(x);
            setEdit(true);
          }
        }
      }
      setSelected(s1);
      setFulldata(full1);
    }

    if (!initial) {
      let result = fulldata;
      for (let i in fulldata) {
        if (!selectedCheck.includes(i)) {
          let k1 = Object.keys(fulldata[i]);
          for (let x of k1) {
            result[i][x].value = "null";
          }
        }
      }
      setFulldata(result);
    }
  }

  console.log("fulldata----", fulldata);

  function multiply(multiplier, ...theArgs) {
    return theArgs.map((element) => multiplier * element);
  }
  
  const arr = multiply(2, 15, 25, 42);
  console.log(arr);

  return (
    <div>
      <header>
        <div className="d-flex align-items-center justify-content-between">
          <div className="d-flex align-items-center">
            <div className="logohover">
              {/* <div className='mllogo'>
                                <img src={mllogo} alt="mlangles logo" />
                            </div> */}
              <div className="newlogo">
                <img src={mlimage} className="newlogos" alt="" />
              </div>
            </div>
            <Link to="/Home">
              <h4 className="capitailze">
                {userDetails?.user_full_name} workspace /{" "}
              </h4>{" "}
            </Link>{" "}
            <Link to="/Projects">
              <span> Projects/</span>
            </Link>
            <Link
              to={`/projectpipeline/Projects/${project_name}/${project_id}`}
            >
              {" "}
              <span className="capitailze">{project_name} Predication/</span>
            </Link>
            <span className="capitailze">Experiment Tracking</span>
          </div>
          <Superuser
            logoutClicked={() => {
              setLogout(true);
            }}
          />
        </div>
      </header>

      <Dialog
        className="logout-dialogbox"
        visible={logout}
        style={{ width: "40vw" }}
        onHide={() => setLogout(false)}
      >
        <Logout no={() => setLogout(false)} />
      </Dialog>

      <Loading loading={open} />
      <Toast ref={toast} />

      {/* {err ? <ErrorMessage errMessage={err} /> : <></>} */}
      <Mlsidebar data={path} />
      <div className="middlepart">
        <div className="each-pipehead d-flex">
          <h4
            onClick={(e) => {
              setCurrentTab("pipeline");
              navigate(
                `/projectpipeline/Projects/${project_name}/${project_id}`
              );
            }}
          >
            PIPELINES
          </h4>
          <h4
            onClick={(e) => setCurrentTab("experimentTracking")}
            className="green"
          >
            EXPERIMENT TRACKING
          </h4>
        </div>
        <form onSubmit={createRunbtn}>
          <div className="run">
            <div>
              <div className="run-checkbox run-check">
                <div
                  className="each-check defaulteach"
                  onClick={(e) => {
                    console.log("stop");
                    e.stopPropagation();
                  }}
                >
                  <label htmlFor="">Problem Type</label>
                  <div className="posrel customSelect">
                    <div className="posrel">
                      <h4 onClick={() => setShowDrop(true)}>
                        {problemtype ? problemtype : "Select Your Problem Type"}{" "}
                      </h4>
                      <img
                        src={downArrow}
                        alt="downArrow"
                        className="arrowabs"
                      />
                    </div>
                    {showDrop ? (
                      <ul>
                        <li
                          onClick={(e) => {
                            e.stopPropagation();
                            setProblemtype("classification");
                            setShowDrop(false);
                            problemsselect("classification");
                          }}
                        >
                          Classification
                        </li>
                        <li
                          onClick={(e) => {
                            e.stopPropagation();
                            setProblemtype("regression");
                            setShowDrop(false);
                            problemsselect("regression");
                          }}
                        >
                          Regression
                        </li>
                      </ul>
                    ) : (
                      <></>
                    )}
                  </div>
                </div>
              </div>
            </div>
            {showalogo ? (
              <>
                <div className="algo">
                  <h5 className="green">Select the Algorithm</h5>
                  <div className="algo-input">
                    {Object.keys(fulldata)?.map((ele, idx) => {
                      return (
                        <React.Fragment key={idx}>
                          <div>
                            <InputCheckbox
                              ele={ele}
                              idx={idx}
                              dis={edit}
                              fulldata={fulldata}
                              changingCheckbox={changingCheckbox}
                            />
                          </div>
                        </React.Fragment>
                      );
                    })}
                  </div>
                </div>

                {selected.length > 0 ? (
                  <>
                    <div className="">
                      <h5 className="green">Input Hyperparameters</h5>
                      <div className="hyper-table">
                        <div className="d-flex justify-content-between table-heading-hyper">
                          <div className="d-flex width20 justify-content-between">
                            <div className="sno width20">
                              {" "}
                              <h2 className="hyper-th">#</h2>{" "}
                            </div>
                            <div className="head-algo width80">
                              {" "}
                              <h2 className="hyper-th">Algorithm </h2>{" "}
                            </div>
                          </div>
                          <div className="head-hyper justifyCenter width80">
                            {" "}
                            <h2 className="hyper-th">Hyperparameter </h2>{" "}
                          </div>
                        </div>

                        <div className="d-flex justify-content-between align-items-center column-dir">
                          {Object.keys(fulldata).map((ele, ind) => {
                            // console.log("ele-----", ele);
                            return (
                              <React.Fragment key={ind}>
                                {selected.map((cur, idx) => {
                                  if (cur === ele) {
                                    return (
                                      <div
                                        key={idx}
                                        className="d-flex w100 mar12 align-items-center"
                                      >
                                        <div className="d-flex width20 justify-content-between">
                                          <div className="sno width20">
                                            {" "}
                                            <h2 className="hyper-td">
                                              {idx + 1}{" "}
                                            </h2>{" "}
                                          </div>
                                          <div className="head-algo width80">
                                            {" "}
                                            <h2 className="hyper-td">
                                              {ele}{" "}
                                            </h2>{" "}
                                          </div>
                                        </div>
                                        <div className="head-hyper width80">
                                          {Object.keys(fulldata[ele]).map(
                                            (elements, index) => {
                                              let d1 = fulldata[ele][elements];
                                              let type1 = "";

                                              if (d1?.options?.length === 0) {
                                                if (d1?.limits[1] === "inf") {
                                                  type1 = "Infinity";
                                                } else if (d1?.limits[1]) {
                                                  type1 = d1.limits[1];
                                                }
                                                return (
                                                  <div
                                                    className="equalwidth"
                                                    key={index}
                                                  >
                                                    <div className="hoverInfo">
                                                      <label
                                                        htmlFor="elements"
                                                        className="infoLabel"
                                                      >
                                                        {elements}
                                                        <img
                                                          src={informationIcon}
                                                          className="infoIcon"
                                                          alt=""
                                                        />
                                                      </label>
                                                      <span className="showInfo">
                                                        Enter {d1.type} value
                                                        between {d1.limits[0]}{" "}
                                                        to {type1}
                                                      </span>
                                                    </div>

                                                    <input
                                                      autoComplete="off"
                                                      type={
                                                        [
                                                          "int",
                                                          "float",
                                                        ].includes(d1.type)
                                                          ? "number"
                                                          : "text"
                                                      }
                                                      value={
                                                        d1.value === "null"
                                                          ? ""
                                                          : d1.value
                                                      }
                                                      onChange={(e) =>
                                                        inputAllGetting(
                                                          e,
                                                          ele,
                                                          elements
                                                        )
                                                      }
                                                      min={
                                                        d1.limits[0]
                                                          ? d1.limits[0]
                                                          : 0
                                                      }
                                                      max={
                                                        d1.limits[1] === "inf"
                                                          ? "1.797693134862315E+308"
                                                          : d1.limits[1]
                                                      }
                                                      step={
                                                        [
                                                          "int",
                                                          "float",
                                                        ].includes(d1.type)
                                                          ? d1.type === "int"
                                                            ? "1"
                                                            : "any"
                                                          : ""
                                                      }
                                                      disabled={edit}
                                                    />
                                                  </div>
                                                );
                                              } else {
                                                if (elements === "isTrue") {
                                                } else {
                                                  return (
                                                    <div
                                                      className="equalwidth d-flex posrel"
                                                      key={index}
                                                    >
                                                      <label htmlFor="elements">
                                                        {elements}{" "}
                                                      </label>
                                                      <Select
                                                        labelId="demo-simple-select-label"
                                                        id="demo-simple-select"
                                                        className="demo-simple-select"
                                                        value={d1?.value}
                                                        label="select value"
                                                        onChange={(e) =>
                                                          inputAllGetting(
                                                            e,
                                                            ele,
                                                            elements
                                                          )
                                                        }
                                                        disabled={edit}
                                                      >
                                                        {d1?.options?.map(
                                                          (data, idx) => {
                                                            return (
                                                              <MenuItem
                                                                key={idx}
                                                                value={data}
                                                              >
                                                                {data}
                                                              </MenuItem>
                                                            );
                                                          }
                                                        )}
                                                      </Select>
                                                    </div>
                                                  );
                                                }
                                              }
                                            }
                                          )}
                                        </div>
                                      </div>
                                    );
                                  }
                                })}
                              </React.Fragment>
                            );
                          })}
                        </div>
                      </div>
                    </div>

                    {/* confidence score */}

                    <div className="megintop32">
                      <h4 className="confHead">Confidence Score</h4>
                      <div className="d-flex gap18 score">
                        <h6>Enter your score</h6>
                        <input
                          required
                          type="number"
                          min="0"
                          max="100"
                          disabled={edit}
                          onChange={(e) => setScore(e.target.value)}
                          value={score}
                        />
                        <p>
                          {" "}
                          <img src={iIcon} alt="" /> If your score is above{" "}
                          <span>{score}% </span> shows success and below as
                          failure{" "}
                        </p>
                      </div>
                    </div>
                  </>
                ) : (
                  <></>
                )}
                <div className="saving">
                  <button className="commonbtn" type="submit">
                    {" "}
                    Save
                  </button>
                  <span
                    className="commonbtn editing"
                    onClick={() => setEdit(false)}
                  >
                    {" "}
                    Edit
                  </span>
                </div>
              </>
            ) : (
              <></>
            )}
          </div>
        </form>
      </div>
    </div>
  );
};

export default DefaultParameter;
