import { FormControl, InputLabel, MenuItem, Select } from "@mui/material";
import { useEffect, useState } from "react";

const ImageLayers = ({ imageLayers, imagelayerChanging, imagelayerValueChanging, index }) => {

    const [layer, setLayer] = useState("");
    const [inputLayer, setInputLayer] = useState([]);
    const [layersKey, setLayersKey] = useState([]);
    const [trigger, setTrigger] = useState(false);

    useEffect(() => {
        setLayersKey(Object.keys(imageLayers));
    }, [])

    function layerChange(e) {
        const { value } = e.target;
        setLayer(value);
        setTrigger(true);

        setInputLayer(Object.keys(imageLayers[value]));
        imagelayerChanging({ index: index, layerType: value })
    }

    function valueChanging(data) {
        setTrigger(false)
        imagelayerValueChanging(data);
        // changingInputLayersValue(data);
    }

    console.log("trigger--------", trigger);

    return (
        <div className="layerFullData run-checkbox">
            <div className="each-check">
                <label htmlFor="">Layer</label>
                <FormControl fullWidth>
                    <InputLabel id="demo-simple-select-label">
                        Select Layer
                    </InputLabel>
                    <Select
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        className="demo-simple-select"
                        // value={layer}
                        label="Data Version"
                        onChange={layerChange}
                    >
                        {
                            layersKey?.map((data, idx) => {
                                return (
                                    <MenuItem key={idx} value={data}>
                                        {data}
                                    </MenuItem>
                                );
                            })}
                    </Select>
                </FormControl>
            </div>
            {
                inputLayer?.map((item, idx) => {
                    if (imageLayers[layer][item]?.options?.length === 0) {
                        return (
                            <div key={idx + layer} className="each-check">
                                <label htmlFor="">{item}</label>
                                <input
                                    className="brightColor"
                                    autoComplete="off"
                                    type="number"
                                    defaultValue={trigger && ""}
                                    name={item}
                                    placeholder="Enter Output features"
                                    onChange={(e) => { valueChanging({ index: index, layerType: layer, key: item, value: e.target.value }) }}
                                />
                            </div>
                        )
                    } else {
                        return (
                            <div key={idx + layer} className="each-check">
                                <label htmlFor="">{item}</label>
                                <FormControl fullWidth>
                                    <Select
                                        labelId="demo-simple-select-label"
                                        id="demo-simple-select"
                                        defaultValue={trigger && ""}
                                        className="demo-simple-select"
                                        onChange={(e) => { valueChanging({ index: index, layerType: layer, key: item, value: e.target.value }) }}
                                    >
                                        {imageLayers[layer][item]?.options?.map((data, idx) => {
                                            return (
                                                <MenuItem key={idx} value={data}>
                                                    {data}
                                                </MenuItem>
                                            );
                                        })}
                                    </Select>
                                </FormControl>
                            </div>
                        );
                    }
                })
            }
        </div>
    )
}

export default ImageLayers;
