import React, { useEffect, useRef, useState } from 'react';
import Loading from '../Components/Loading';
import { Link, useNavigate, useParams } from 'react-router-dom';
import mllogo from "../logo/mlangles360logo.svg"
import profilePic from "../logo/profile-pic.png"
import notificationIcon from "../logo/notification-icon.png"
import mlimage from "../logo/mlangles360logo.svg"
import Mlsidebar from '../Components/Mlsidebar';
import { Toast } from "primereact/toast";
import pencil from "../logo/pencil.png";
import deleteIcon from "../logo/deleteIcon.png";
import plus from "../logo/plus.png";

import lock from "../logo/lock.png";
import close from "../logo/close.png";
import tick from "../logo/tick.png";
import { FormControl, MenuItem, Modal, Select } from '@mui/material';
import "./usermanage.css"
import axios from 'axios';
import Superuser from '../Components/Superuser';
import { backend_url, backend_url1 } from '../Config';
import Logout from '../Pages/Logout';
import { Dialog } from 'primereact/dialog';

const UserCreate = () => {
    const [open, setOpen] = useState(false);
    const [editOpen, setEditOpen] = useState(false);
    const [newUser, setNewUser] = useState(false);
    const [assign, setAssign] = useState("");
    const [assigndata, setAssigndata] = useState([]);
    const [alldata, setAlldata] = useState([]);

    const [btndisable, setBtndisable] = useState(false);
    const [userAccount, setUserAccount] = useState("");
    const navigate = useNavigate();
    const [logout, setLogout] = useState(false);
    let token = JSON.parse(localStorage.getItem("mlanglesToken"));
    let Header = { headers: { "Authorization": `Bearer ${token}` } };
    let userdetails = JSON.parse(localStorage.getItem("userDetails"));
    const [userDetails, setUserDetails] = useState({});
    const toast = useRef(null);
    const [user, setUser] = useState({
        "user_first_name": "",
        "user_last_name": "",
        "user_mobile": "",
        "department": "",
        "business_unit": "",
        "user_organization": `${userdetails.organization}`,
        "email": "",
        "password": "",
        "re_password": "",
        "is_superuser": 0,
        "assign_role": "",
        "created_by": `${userdetails.user_full_name}`
    });

    let path = "userManage";


    useEffect(() => {
        let token = JSON.parse(localStorage.getItem("mlanglesToken"));
        let userdetails = JSON.parse(localStorage.getItem("userDetails"));
        setUserDetails(userdetails);

        setUser({
            "user_first_name": "",
            "user_last_name": "",
            "user_mobile": "",
            "department": "",
            "business_unit": "",
            "user_organization": `${userdetails.organization}`,
            "email": "",
            "password": "",
            "re_password": "",
            "is_superuser": 0,
            "assign_role": "",
            "created_by": `${userdetails.user_full_name}`
        })

        if (userdetails) {
            if (!token || userdetails?.permissions?.user_management_roles_admin === 0) {
                navigate("/");
            }
            fetchData();
        }
    }, [])


    async function fetchData() {
        setOpen(true);

        const res = await axios.get(`${backend_url}/settings/create_role`, Header);
        // console.log(res.data);
        const ans = await axios.get(`${backend_url}/settings/all_users`, Header);
        // console.log(ans);

        if (res.data.success) {
            setAlldata(ans.data.data);
            setOpen(false);
            setAssigndata(res.data.data);
        }
    }

    function createUserBtn(e) {

        try {
            e.preventDefault();
            setOpen(true);

            if (user.user_mobile.length !== 10) {
                setOpen(false);
                funError("Enter Correct mobile number");
             
            }
            else if (user.email.includes("gmail") ||
                user.email.includes("yahoo") ||
                user.email.includes("outlook") ||
                user.email.includes("protonmail") ||
                user.email.includes("zoho") ||
                user.email.includes("icloud")) {
                setOpen(false);
                funError("Enter Correct Email ID");
                
            }

            else {
                fetch(`${backend_url}/auth/users/`, {
                    method: "POST",
                    body: JSON.stringify(user),
                    headers: {
                        "Content-type": "application/json; charset=UTF-8",
                    },
                })
                    .then((response) => response.json())
                    .then((data) => {
                        setOpen(true);
                        let ans = Object.keys(data).length;
                        let ans1 = Object.values(data);
                        // console.log("ans11----", ans1);
                        if (ans === 1) {
                            funError(ans1[0][0]);
                            setOpen(false);
                           
                        } else {
                            async function fetching() {
                                const res = await axios.post(`${backend_url}/settings/assign_instance_ip_to_user`, { "user_account_id": data.user_account_id }, Header);
                                if (res.data.success) {
                                    // console.log("success");
                                }
                                setUser({
                                    "user_first_name": "",
                                    "user_last_name": "",
                                    "user_mobile": "",
                                    "department": "",
                                    "business_unit": "",
                                    "user_organization": `${userDetails.organization}`,
                                    "email": "",
                                    "password": "",
                                    "re_password": "",
                                    "is_superuser": 0,
                                    "assign_role": "",
                                    "created_by": `${userDetails.user_full_name}`
                                });
                                setOpen(false)
                                setNewUser(false);
                                navigate("/usermanage");
                            }
                            fetching();
                        }
                    });
            }
        }
        catch (e) {
            // console.log(e);
            setOpen(false);
            funError(e.message);
        }
    }
    function newUserChanging(e) {
        let name = e.target.name;
        let value = e.target.value;
        if (name === "user_mobile") {
            if (value.length <= 10) {
                setUser({ ...user, [name]: value });
            }
        } else {
            setUser({ ...user, [name]: value });
        }
    }

    function pass() {
    }

    const funError = (data) => {
        toast.current.show({
          severity: "error",
          summary: "Error",
          detail: data,
          life: 2000,
        });
      };

    return (
        <div>
            <Loading loading={open} />

            <header>
                <div className='d-flex align-items-center justify-content-between' >
                    <div className="d-flex align-items-center">
                        <div className='logohover'>
                           
                            <div className="newlogo">
                                <img src={mlimage} className='newlogos' alt="" />
                            </div>
                        </div>
                        <h4 className='capitailze'>{userDetails?.user_full_name} workspace / </h4><span> User & Role Management/ </span> <span>User Management</span>
                    </div>
                    <Superuser logoutClicked={() => { setLogout(true) }} />

                </div>
            </header>

            <Dialog className="logout-dialogbox" visible={logout} style={{ width: '40vw' }} onHide={() => setLogout(false)}>
                <Logout no={() => setLogout(false)} />
            </Dialog>

            <Mlsidebar data={path} />
            <div className="middlepart">

                <div>
                    <div className='d-flex backgrey fixedsubttab'>
                        <h4 className='page-title green'>USER MANAGEMENT</h4>
                        <Link to="/rolemanage/false"><h4 className='page-title'>ROLE MANAGEMENT</h4></Link>
                    </div>

                    <form onSubmit={createUserBtn}>
                        <div className='inside-newuser'>
                            {/* {
                                err ?
                                    <div className='d-flex align-items-center justify-content-center'>
                                        <h4 className='error-message'>{err}</h4>
                                    </div> : <></>
                            } */}
                            <div className="width50">
                                <div className="d-flex gap30">
                                    <div className="each-for-box">
                                        <label htmlFor="" className='labelforgot'>First Name</label>
                                        <input autoComplete="off" type="text" required className='inputcommontextforgot' name="user_first_name" onChange={newUserChanging} id="" />
                                    </div>
                                    <div className="each-for-box">
                                        <label htmlFor="" className='labelforgot'>Last Name</label>
                                        <input autoComplete="off" type="text" required className='inputcommontextforgot' name="user_last_name" onChange={newUserChanging} id="" />
                                    </div>
                                </div>
                                <div className="d-flex gap30">
                                    <div className="each-for-box">
                                        <label htmlFor="" className='labelforgot'>Email ID</label>
                                        <input autoComplete="off" type="email" required className='inputcommontextforgot' name="email" onChange={newUserChanging} id="" />
                                    </div>
                                    <div className="each-for-box">
                                        <label htmlFor="" className='labelforgot'>Phone Number</label>
                                        <input type="number" required className='inputcommontextforgot' value={user.user_mobile} name="user_mobile" onChange={user?.user_mobile?.length <= 10 ? newUserChanging : pass} id="" />
                                    </div>
                                </div>

                                <div className="d-flex gap30">
                                    <div className="each-for-box">
                                        <label htmlFor="" className='labelforgot'>Department</label>
                                        <input autoComplete="off" type="text" required className='inputcommontextforgot' name="department" onChange={newUserChanging} id="" />
                                    </div>
                                    <div className="each-for-box">
                                        <label htmlFor="" className='labelforgot'>Business Unit</label>
                                        <input autoComplete="off" type="text" required className='inputcommontextforgot' name="business_unit" onChange={newUserChanging} id="" />
                                    </div>
                                </div>

                                <div className="d-flex gap30">
                                    <div className="each-for-box">
                                        <label htmlFor="" className='labelforgot'>Password</label>
                                        <input autoComplete="off" type="text" required className='inputcommontextforgot' name="password" onChange={newUserChanging} id="" />
                                    </div>
                                    <div className="each-for-box">
                                        <label htmlFor="" className='labelforgot'>Confirm Password</label>
                                        <input autoComplete="off" type="text" required className='inputcommontextforgot' name="re_password" onChange={newUserChanging} id="" />
                                    </div>
                                </div>

                                <div className="d-flex gap30 align-items-center">
                                    <div className="each-for-box">
                                        <label htmlFor="" className='labelforgot'>Assign User Role</label>
                                        <FormControl fullWidth>
                                            <Select
                                                labelId="demo-simple-select-label"
                                                id="demo-simple-select"
                                                value={user?.assign_role}
                                                label="Select your Project"
                                                onChange={newUserChanging}
                                                className='dropdwon-user'
                                                name="assign_role"
                                                required
                                            >
                                                {
                                                    assigndata.map((data, idx) => {
                                                        return (
                                                            <MenuItem key={idx} name="assign_role" value={data}>{data}</MenuItem>
                                                        )
                                                    })
                                                }
                                            </Select>
                                        </FormControl>
                                    </div>
                                    <div className="each-boxing">
                                        <Link to="/rolemanage/true">
                                            <div className="eachbutton commonbackbtn buttonnewrole" onClick={() => setEditOpen(true)}>
                                                <img src={plus} alt="" /> <span>New User Role</span>
                                            </div>
                                        </Link>
                                    </div>
                                </div>

                                {/* <div className='details'>
            <div>
                <h2>1.Projects</h2>
                <ul>
                    <li>Edit/ delete/ modify all projects</li>
                    <li>Assigned members to my projects</li>
                    <li>Edit/ delete/ modify Assigned projects</li>
                </ul>
            </div>
            <div>
                <h2>2.Experiments</h2>
                <ul>
                    <li>View Runs</li>
                    <li>Edit/delete/modify runs</li>
                </ul>
            </div>
        </div> */}

                                {/* <button type='submit' className='commonbtn commonbackbtn createuserbtn ' onClick={() => { createUserBtn() }} >Create User</button> */}
                                <button type='submit' className='commonbtn commonbackbtn createuserbtn' >Create User</button>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    )
}

export default UserCreate